import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
import config from '../config';

interface PageProps {
  children: ReactNode;
  title?: string;

  [key: string]: unknown;
}

export default function Page(props: PageProps) {
  const { children, title = '', ...other } = props;

  return (
    <Box { ...other }>
      <Helmet>
        <title>{ title ? `${ title } | ` : '' }{ config.site.name }</title>
      </Helmet>
      { children }
    </Box>
  );
}
