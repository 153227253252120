import { ComponentsPropsList, stepLabelClasses } from '@mui/material';
import colors from '../colors';

export default function StepLabel() {
  return {
    MuiStepLabel: {
      styleOverrides: {
        root: ({ ownerState } : { ownerState: ComponentsPropsList['MuiStep'] }) => {
          const styles = {
            justifyContent: 'center',
            borderBottom: `1px solid #C4C4C4`,
            paddingBottom: '1.5rem',

            [`.${ stepLabelClasses.labelContainer }`]: {
              width: 'auto',
            },

            [`.${ stepLabelClasses.label }`]: {
              fontSize: '0.875rem',

              [`&.${ stepLabelClasses.active }`]: {
                color: colors.brown,
                fontWeight: 600,
              },
            },
          };

          if (ownerState.active) {
            styles['borderBottom'] = `3px solid ${ colors.brown }`;
          }

          return styles;
        },
      },
    },
  };
}
