import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { store } from '../../store';
import {
  getSubscriptionById,
  fetchSubscriptions,
  SubscriptionZone,
  createSubscription } from '../../features/subscriptions/subscriptionsSlice';
import { isAuthed } from '../../features/users/usersSlice';

import {
  Alert,
  Box,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography } from '@mui/material';

import BulletCheck from '../../components/icons/BulletCheck';
import Page from '../../components/Page';
import Price from '../../components/Price';
import StepCheckout from './StepCheckout';
import StepMap from './StepMap';
import Register from '../../components/Register';
import { useTranslation } from 'react-i18next';

import BadgePremium from '../../assets/images/badge-premium.svg';
import { getErrorMessage } from '../../utils/errorHandler';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: `calc(${ theme.sizes.navbar.height } + 1rem)`,
}));

const SubscriptionWrapper = styled(Paper)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '.5rem',

  [theme.breakpoints.up('sm')]: {
    minHeight: '60vh',
    padding: '4rem 2rem',
  },
}));

const ChangeSubscriptionWrapper = styled(Box)(() => ({
  padding: '1rem',
  width: '100%',
  textAlign: 'center',
}));

export default function Subscribe() {
  const { id } = useParams();
  const { t } = useTranslation();

  if (!id) {
    return null;
  }

  const subscription = useSelector(getSubscriptionById(parseInt(id)));
  const authed = useSelector(isAuthed);

  const [steps, setSteps] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState<string>();
  const [nuCase, setNuCase] = useState('');
  const [error, setError] = useState('');

  const handleNextStep = (newData?: SubscriptionZone[]) => {
    if (!subscription) {
      return;
    }

    if (!activeStep) {
      setActiveStep(steps[0]);
      return;
    }

    switch (activeStep) {
      case 'map':
        if (!newData || !newData.length) {
          throw new Error(t('No se seleccionaron zonas'));
        }

        store.dispatch(createSubscription({
          zones: newData,
          subscription,
        }))
          .unwrap()
          .then(data => {
            setNuCase(data.cdCase);
            setActiveStep('checkout');
          })
          .catch(err => {
            console.error(err);
            setError(getErrorMessage(err));
          });
        break;
      case 'checkout':
        break;
    }
  };

  const getStepContent = (step: string) => {
    switch (step) {
      case 'register': return <Register />;
      case 'map': return <StepMap max={ subscription?.zones ?? 0 } onFinish={ handleNextStep } />;
      case 'checkout': return <StepCheckout nuCase={ nuCase } />;
    }
  };

  useEffect(() => {
    store.dispatch(fetchSubscriptions());
  }, []);

  useEffect(() => {
    if (authed) {
      setSteps([
        'map',
        'checkout',
      ]);
      setActiveStep('map');
      return;
    }

    setSteps([
      'register',
      'map',
      'checkout',
    ]);
    setActiveStep('register');
  }, [authed]);

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <RootStyle>
      <Container>
        <Grid container spacing={ 4 }>
          <Grid item xs={ 12 } sm={ 4 }>
            {
              subscription
                ? <SubscriptionWrapper>
                    <img src={ BadgePremium } style={{ maxWidth: '65px' }} /><br />
                    <Typography variant="h4">{ subscription.name }</Typography>
                    <List sx={{ marginBottom: '2rem' }}>
                      {
                        subscription.description.map((d, idx) => (
                          <ListItem key={`detail_${ idx }`}>
                            <ListItemIcon sx={{ minWidth: '42px' }}>
                              <BulletCheck />
                            </ListItemIcon>
                            <ListItemText primary={ d } />
                          </ListItem>
                        ))
                      }
                    </List>
                    <Price value={ subscription.price } currency={ subscription.currency } period="mes" />
                  </SubscriptionWrapper>
                : null
            }
            <ChangeSubscriptionWrapper>
              <Link href="/opportunities#choose-subscription">
                { t('Cambiar Membresía') }
              </Link>
            </ChangeSubscriptionWrapper>
          </Grid>
          <Grid item xs={ 12 } sm={ 8 }>
            <Stepper activeStep={ activeStep ? steps.indexOf(activeStep) : -1 }>
              {
                steps.includes('register')
                  ? <Step expanded>
                      <StepLabel>{ t('Registro') }</StepLabel>
                    </Step>
                  : null
              }
              {
                steps.includes('map')
                  ? <Step expanded>
                      <StepLabel>{ t('Oportunidades') }</StepLabel>
                    </Step>
                  : null
              }
              {
                steps.includes('checkout')
                  ? <Step expanded>
                      <StepLabel>{ t('Checkout') }</StepLabel>
                    </Step>
                  : null
              }
            </Stepper>
            <Box>
              { activeStep ? getStepContent(activeStep) : null }
            </Box>
            {
              error
                ? <Alert severity="error" sx={{ fontSize: '1rem', marginTop: '2rem' }}>{ error }</Alert>
                : null
            }
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
