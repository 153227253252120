import { Icon } from '@iconify/react';
import { Cancel, CheckCircle } from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NumericField, {
  HTMLNumericElement,
} from '../../components/NumericField';
import { Lot } from '../../features/lots/lotsSlice';
import { getCurrencies, getUnits } from '../../features/options/optionsSlice';
import { getMapIsLoaded } from '../../features/ui/UISlice';

const PaperStyled = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  borderRadius: '10px',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    padding: '2rem',
    justifyContent: 'space-between',
  },
}));

const FormControlStyled = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

interface NumericFieldStyledProps {
  dir?: 'left' | 'right';
}

const NumericFieldStyled = styled(NumericField, {
  shouldForwardProp: (prop) => prop !== 'dir',
})<NumericFieldStyledProps>(({ dir = 'right' }) => ({
  fieldset: {
    borderTopLeftRadius: dir == 'left' ? 0 : undefined,
    borderBottomLeftRadius: dir == 'left' ? 0 : undefined,
    borderTopRightRadius: dir == 'right' ? 0 : undefined,
    borderBottomRightRadius: dir == 'right' ? 0 : undefined,

    borderLeft: dir == 'left' ? 'none' : undefined,
    borderRight: dir == 'right' ? 'none' : undefined,
  },
  marginTop: '4px',
}));

interface LotSummaryProps {
  lot: Lot;

  handleEdit?: (data: Partial<Lot>) => void;

  [key: string]: unknown;
}

export default function LotSummary(props: LotSummaryProps) {
  const { lot, handleEdit, ...rest } = props;

  const { t, i18n } = useTranslation();

  const showEditors = !!handleEdit;

  const addressRef = useRef<HTMLInputElement>(null);

  const mapIsLoaded = useSelector(getMapIsLoaded);
  const currencies = useSelector(getCurrencies);
  const units = useSelector(getUnits);

  const [data, setData] = useState<Partial<Lot>>(lot);

  const [parsedPrice, setParsedPrice] = useState('');
  const [addressValue, setAddressValue] = useState(lot.address);
  const [address, setAddress] = useState({
    address: '',
    placeId: '',
    lat: 0,
    lng: 0,
  });

  const [editing, setEditing] = useState<Partial<Record<keyof Lot, boolean>>>({
    title: false,
    address: false,
    description: false,
    area: false,
    currency: false,
  });

  const getEditorCaller = (field: keyof Lot) => {
    return (
      <>
        {showEditors && !editing[field] ? (
          <IconButton
            color="primary"
            onClick={() => setEditing({ ...editing, [field]: true })}
          >
            <Icon icon="ant-design:edit-outlined" />
          </IconButton>
        ) : null}
      </>
    );
  };

  const getEditorActions = (field: keyof Lot) => {
    const closeEditor = () => {
      if (handleEdit) {
        handleEdit(address.address ? { ...data, ...address } : data);
      }

      setEditing({ ...editing, [field]: false });
    };

    const cancelEdition = () => {
      setData({
        ...data,
        [field]: lot[field],
      });

      if (field == 'address') {
        setAddressValue(lot.address);
      }

      setEditing({ ...editing, [field]: false });
    };

    return (
      <>
        <IconButton color="success" onClick={closeEditor}>
          <CheckCircle />
        </IconButton>
        <IconButton color="warning" onClick={cancelEdition}>
          <Cancel />
        </IconButton>
      </>
    );
  };

  const handleInputChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLNumericElement
    >,
  ) => {
    if (event.target.name === 'address') {
      setAddress({
        address: '',
        placeId: '',
        lat: 0,
        lng: 0,
      });
      setAddressValue(`${event.target.value}`);
      return;
    }

    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleCurrencyChange = (event: SelectChangeEvent<number>) => {
    setData({
      ...data,
      currency: {
        id: +event.target.value,
        name: currencies.find((c) => c.id == +event.target.value)!.name,
      },
    });
  };

  const handleUnitChange = (event: SelectChangeEvent<number>) => {
    setData({
      ...data,
      unit: {
        id: +event.target.value,
        name: units.find((c) => c.id == +event.target.value)!.name,
      },
    });
  };

  useEffect(() => {
    if (!mapIsLoaded || !addressRef.current) {
      return;
    }

    const newAutocomplete = new window.google.maps.places.Autocomplete(
      addressRef.current,
    );

    newAutocomplete.setFields(['formatted_address', 'place_id', 'geometry']);

    // add a listener to handle when the place is selected
    newAutocomplete.addListener('place_changed', () => {
      if (!newAutocomplete) {
        return;
      }

      const place = newAutocomplete.getPlace();

      if (
        !place.place_id ||
        !place.formatted_address ||
        !place.geometry?.location
      ) {
        return;
      }

      setAddress({
        address: place.formatted_address,
        placeId: place.place_id,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setAddressValue(place.formatted_address);
    });
  }, [mapIsLoaded, addressRef.current]);

  useEffect(() => {
    setData(lot);
  }, [lot]);

  useEffect(() => {
    if (!data.price) {
      setParsedPrice('');
      return;
    }

    const parsed = new Intl.NumberFormat(i18n.language, {
      minimumFractionDigits: 0,
      useGrouping: true,
    }).format(data.price);

    setParsedPrice(parsed);
  }, [data.price, i18n.language]);

  return (
    <Box {...rest}>
      <PaperStyled>
        <Box flexGrow={1}>
          {editing.title ? (
            <FormControlStyled>
              <TextField
                variant="standard"
                fullWidth
                InputProps={{
                  sx: {
                    fontSize: '2rem',
                    fontWeight: 600,
                  },
                }}
                name="title"
                value={data.title}
                onChange={handleInputChange}
              />
              {getEditorActions('title')}
            </FormControlStyled>
          ) : (
            <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>
              {data.title}
              {showEditors && getEditorCaller('title')}
            </Typography>
          )}
          {editing.address ? (
            <FormControlStyled>
              <TextField
                variant="standard"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <Icon
                      icon="et:map-pin"
                      fontSize="1.125rem"
                      style={{ marginLeft: '.25rem' }}
                    />
                  ),
                }}
                inputRef={addressRef}
                value={addressValue}
                name="address"
                onChange={handleInputChange}
              />
              {getEditorActions('address')}
            </FormControlStyled>
          ) : (
            <Typography color="text.secondary">
              <Icon
                icon="ep:location"
                style={{
                  width: '1rem',
                  marginRight: '.25rem',
                  verticalAlign: 'middle',
                }}
              />
              {data.address}
              {getEditorCaller('address')}
            </Typography>
          )}
          {editing.area ? (
            <FormControlStyled>
              <NumericFieldStyled
                margin="dense"
                placeholder={t('Tamaño')}
                precision={0}
                name="area"
                value={data.area ? data.area : 0}
                onChange={handleInputChange}
              />
              {units.length > 0 ? (
                <Select<number>
                  displayEmpty
                  value={data.unit ? data.unit.id : 1}
                  name="unit"
                  onChange={handleUnitChange}
                  sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                >
                  {units.map((u) => (
                    <MenuItem key={u.id} value={u.id}>
                      {u.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : null}
              {getEditorActions('area')}
            </FormControlStyled>
          ) : (
            <Typography color="text.secondary">
              <Icon
                icon="bi:arrows-angle-expand"
                style={{
                  width: '.875rem',
                  marginRight: '.475rem',
                  verticalAlign: 'middle',
                }}
              />
              {data.area} {data.unit?.name}
              {getEditorCaller('area')}
            </Typography>
          )}
        </Box>
        <Box
          sx={{ display: 'flex', alignItems: 'baseline', whiteSpace: 'nowrap' }}
        >
          {editing.price ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
              }}
            >
              <FormControlStyled>
                {currencies.length > 0 ? (
                  <Select
                    displayEmpty
                    value={data.currency ? data.currency.id : 1}
                    sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    name="currency"
                    onChange={handleCurrencyChange}
                  >
                    {currencies.map((c) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.name === 'Dolar' ? 'USD' : c.name}
                      </MenuItem>
                    ))}
                  </Select>
                ) : null}
                <NumericFieldStyled
                  precision={0}
                  margin="dense"
                  placeholder={t('Valor')}
                  name="price"
                  value={data.price ? data.price : 0}
                  onChange={handleInputChange}
                  dir="left"
                />
              </FormControlStyled>
              <Box>{getEditorActions('price')}</Box>
            </Box>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 400,
                  letterSpacing: '0.05rem',
                  mr: '.5rem',
                }}
              >
                {data.currency?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: '1.625rem',
                  fontWeight: 700,
                  letterSpacing: '0.05rem',
                }}
              >
                {parsedPrice}
                {getEditorCaller('price')}
              </Typography>
            </>
          )}
        </Box>
      </PaperStyled>
      <Typography
        sx={{
          marginTop: '2rem',
          marginBottom: '1rem',
          fontSize: '1.625rem',
          fontWeight: 600,
        }}
      >
        {t('Descripción')}
        {showEditors && getEditorCaller('description')}
      </Typography>
      {showEditors && (
        <small style={{ fontSize: '.85rem' }}>
          ({t('1000 caracteres max')})
        </small>
      )}
      {editing.description ? (
        <Box
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
        >
          <TextField
            placeholder={
              t('Descripción') + ' (' + t('1000 caracteres max') + ')'
            }
            margin="dense"
            fullWidth
            multiline
            sx={{
              '.MuiInputBase-multiline': {
                height: '8rem',
                padding: 0,
              },
            }}
            name="description"
            value={data.description}
            onChange={handleInputChange}
          />
          <Box>{getEditorActions('description')}</Box>
        </Box>
      ) : (
        <Typography component="p" sx={{ whiteSpace: 'pre-wrap' }}>
          {data.description}
        </Typography>
      )}
    </Box>
  );
}
