import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGeolocated } from 'react-geolocated';

import { store } from '../../store';
import { fetchLots, FetchLotsOptions, getLots, getLotsError } from '../../features/lots/lotsSlice';

import { Button, Container, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';

import LotSlider from '../../components/LotSlider';
import Section from '../../components/Section';
import CircleButton from '../../components/CircleButton';
import LotsError from '../../components/LotsError';
import { getMapIsLoaded, getUserPlaceId, setPlaceId, setPosition } from '../../features/ui/UISlice';
import { getLandTypes } from '../../features/options/optionsSlice';
import { useTranslation } from 'react-i18next';

interface NewestProps {
  sx?: SxProps<Theme>;
}

export default function Newest(props: NewestProps) {
  const { sx } = props;

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const { t } = useTranslation();

  const lots = useSelector(getLots);
  const error = useSelector(getLotsError);
  const types = useSelector(getLandTypes);
  const userPlace = useSelector(getUserPlaceId);
  const isMapLoaded = useSelector(getMapIsLoaded);

  const [selectedType, setSelectedType] = useState(-1);

  const { coords, getPosition } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
    suppressLocationOnMount: true,
  });

  useEffect(() => {
    store.dispatch(fetchLots({
      count: 10,
      overwrite: true,
    }));

    getPosition();
  }, []);

  useEffect(() => {
    if (!isMapLoaded || !coords) {
      return;
    }

    store.dispatch(setPosition({ ...coords }));

    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({
      location: {
        lat: coords.latitude,
        lng: coords.longitude,
      },
    })
      .then(response => {
        if (!response || !response.results || response.results.length === 0) {
          return;
        }

        let selectedPlace = response.results.find(r => r.types.includes('locality'));
        if (!selectedPlace) {
          selectedPlace = response.results[0];
        }

        const placeId = selectedPlace.place_id;

        store.dispatch(setPlaceId(placeId));

        store.dispatch(fetchLots({ count: 10, zone: placeId, overwrite: true }));
      });
  }, [isMapLoaded, coords]);

  if (error) {
    return <LotsError />;
  }

  const handleFilterClick = (id: number) => {
    const options: FetchLotsOptions = {
      count: 10,
      overwrite: true,
    };

    if (userPlace) {
      options['zone'] = userPlace;
    }

    if (id > 0) {
      options['landType'] = id;
    }

    store.dispatch(fetchLots(options));

    setSelectedType(id);
  };

  const handleViewAllClick = () => {
    navigate('/lots');
  };

  return (
    <Section
      title={ t('Últimas incorporaciones') }
      sx={ sx }
      actions={
        <>
          <Button
            variant={ selectedType === -1 ? 'contained' : 'outlined' }
            onClick={ () => handleFilterClick(-1) }
          >{ t('Todos') }</Button>
          {
            types.map(type => (
              <Button
                key={`type_${ type.id }`}
                variant={ selectedType === type.id ? 'contained' : 'outlined' }
                sx={{ ml: '1rem' }}
                onClick={ () => handleFilterClick(type.id) }
              >{ type.name }</Button>
            ))
          }
        </>
      }
    >
      <LotSlider lots={ lots } cols={ 3 } />
      {
        desktop
          ? <Container sx={{ textAlign: 'center', mt: '1rem' }}>
              <CircleButton onClick={ handleViewAllClick }>{ t('Ver todos') }</CircleButton>
            </Container>
          : null
      }
    </Section>
  );
}
