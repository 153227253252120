import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Typography } from '@mui/material';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';

import { store } from '../../store';
import { fetchLotById, Lot, updateLot } from '../../features/lots/lotsSlice';

import Page from '../../components/Page';

import LotPhotos from './Photos';
import LotSummary from './Summary';
import LotMap from './Map';
import { useSelector } from 'react-redux';
import { getLandTypes, getPublicationStatus, getSuitabilities } from '../../features/options/optionsSlice';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '../../utils/errorHandler';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: `calc(${ theme.sizes.navbar.height } + 1rem)`,
}));

type LotErrors = Partial<Record<keyof Lot, boolean>>;

export const DelayedFallback = ({ children, delay = 300 }: { children: ReactNode, delay?: number }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delay);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return <>{show && children}</>;
};

export default function Edit() {
  const { id } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const publicationStatus = useSelector(getPublicationStatus);
  const suitabilities = useSelector(getSuitabilities);
  const landTypes = useSelector(getLandTypes);

  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [lotData, setLotData] = useState<Lot>({
    id: -1,
    title: '',
    description: '',
    address: '',
    type: 1,
    suitabilities: [],
    thumbnail: '',
    photos: [],
    price: 0,
    currency: {
      id: 1,
      name: 'USD',
    },
    area: 0,
    unit: {
      id: 1,
      name: 'm² (metro cuadrado)',
    },
    opportunity: false,
    lat: 0,
    lng: 0,
    placeId: '',
    status: 1,
    url: '',
  });

  const [errors, setErrors] = useState<LotErrors>({});
  const [error, setError] = useState('');

  useEffect(() => {
    if (!id) {
      return;
    }

    store.dispatch(fetchLotById(parseInt(id)))
      .unwrap()
      .then(response => {
        setLotData(response);
      })
      .catch(err => {
        console.error(err);
        setError(getErrorMessage(err));
      })
      .finally(() => setLoading(false));
  }, [id]);

  const handleEdit = (data: Partial<Lot>) => {
    setLotData({
      ...lotData,
      ...data,
    } as Lot);

    setError('');
  };

  const handleSave = () => {
    setLoading(true);

    setError('');

    const newErrors: LotErrors = {};
    let count = 0;

    if (!lotData.price || lotData.price == 0) {
      newErrors.price = true;
      count++;
    }

    if (!lotData.area || lotData.area == 0) {
      newErrors.area = true;
      count++;
    }

    if (!lotData.address || !lotData.address.trim()) {
      newErrors.address = true;
      count++;
    }

    if (!lotData.description || !lotData.description.trim()) {
      newErrors.description = true;
      count++;
    }

    if (!lotData.title || !lotData.title.trim()) {
      newErrors.title = true;
      count++;
    }

    if (lotData.suitabilities.length == 0) {
      newErrors.suitabilities = true;
      count++;
    }

    if (lotData.suitabilities.includes(7) && !lotData.customSuitabilities) {
      newErrors.customSuitabilities = true;
      count++;
    }

    if (count > 0) {
      setErrors(newErrors);
      return;
    }

    store.dispatch(updateLot(lotData))
      .unwrap()
      .then(response => {
        // En caso de error desconocido recargamos la página
        if (response.cdState != 'ENDEDCASE') {
          window.location.reload();
          return;
        }

        setDialogOpen(true);
      })
      .catch(err => {
        console.error(err);
        setError(getErrorMessage(err));
      })
      .finally(() => setLoading(false));
  };

  const handleStatusChange = (event: SelectChangeEvent<number>) => {
    if (!lotData) {
      return;
    }

    setLotData({
      ...lotData,
      status: +event.target.value,
    });
  };

  const handleSuitabilitiesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const changedSuitability = parseInt(event.target.value);

    let newSuitabilities = [...lotData.suitabilities];
    if (lotData.suitabilities.includes(changedSuitability)) {
      newSuitabilities = lotData.suitabilities.filter(a => a !== changedSuitability);
    } else {
      newSuitabilities.push(changedSuitability);
    }

    setLotData({
      ...lotData,
      suitabilities: newSuitabilities,
    });

    if (errors.suitabilities) {
      setErrors({
        ...errors,
        suitabilities: false,
      });
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.name === 'type' ? +event.target.value : event.target.value;
    setLotData({
      ...lotData,
      [event.target.name]: newValue,
    });

    // Una vez que verificamos que la propiedad existe en el objeto, suprimimos el error ts(7053)
    if (event.target.name in errors && errors[event.target.name as keyof LotErrors]) {
      setErrors({
        ...errors,
        [event.target.name]: false,
      });
    }
  };

  const handleSuccess = () => {
    navigate('/profile/lots');
  };

  return (
    <RootStyle>
      <Container>
        {
          lotData.photos.length > 0
            ? <LotPhotos lot={ lotData } handleEdit={ handleEdit } />
            : null
        }
        <Grid container spacing={ 2 } mt="2rem">
          <Grid item xs={ 12 } sm={ 8 }>
            <LotSummary lot={ lotData } sx={{ marginBottom: '2rem' }} handleEdit={ handleEdit } />
            <LotMap lot={ lotData } />
          </Grid>
          <Grid item xs={ 12 } sm={ 4 }>
            <FormLabel sx={{ fontSize: '1.25rem' }}>{ t('Estado de la Publicación') }</FormLabel>
            {
              publicationStatus.length > 0 && lotData.status
                ? <Select<number>
                    displayEmpty
                    fullWidth
                    value={ lotData.status }
                    onChange={ handleStatusChange }
                    sx={{ marginBottom: '1rem' }}
                    disabled={ loading }
                  >
                    {
                      publicationStatus.map(status => (
                        <MenuItem
                          key={ `status_${ status.id }` }
                          value={ status.id }
                        >
                          { status.name }
                        </MenuItem>
                      ))
                    }
                  </Select>
                : null
            }
            <FormControl error={ errors.type } required sx={{ marginBottom: '1rem' }}>
              <FormLabel sx={{ fontSize: '1.25rem' }}>{ t('Tipo del Terreno') }</FormLabel>
              <FormGroup row>
                <RadioGroup
                  name="type"
                  onChange={ handleInputChange }
                  row
                  value={ lotData.type }
                >
                  {
                    landTypes.map(type => (
                      <FormControlLabel
                        key={`type_${ type.id }`}
                        label={ type.name }
                        value={ type.id }
                        control={ <Radio /> }
                        disabled={ loading }
                      />
                    ))
                  }
                </RadioGroup>
              </FormGroup>
            </FormControl>
            <FormControl error={ errors.suitabilities } required>
              <FormLabel sx={{ fontSize: '1.25rem' }}>{ t('Aptitudes del Terreno') }</FormLabel>
              {
                errors.suitabilities
                  ? <Typography color="red">{ t('Tiene que seleccionar al menos una aptitud') }</Typography>
                  : null
              }
              <FormGroup row>
                {
                  suitabilities.map(suitability => (
                    <FormControlLabel
                      key={`suitability_${ suitability.id }`}
                      label={ suitability.name }
                      value={ suitability.id }
                      control={ <Checkbox onChange={ handleSuitabilitiesChange } /> }
                      checked={ lotData.suitabilities.includes(suitability.id) }
                      disabled={ loading }
                    />
                  ))
                }
                <FormControlLabel
                  key={`suitability_7`}
                  label={ t('Otros') }
                  value={ 7 }
                  control={ <Checkbox onChange={ handleSuitabilitiesChange } /> }
                  checked={ lotData.suitabilities.includes(7) }
                  disabled={ loading }
                />
              </FormGroup>
            </FormControl>
            {
              lotData.suitabilities.includes(7)
                ? <TextField
                    placeholder={ t('Otras apitudes') }
                    margin="dense"
                    fullWidth
                    name="customSuitabilities"
                    value={ lotData.customSuitabilities ? lotData.customSuitabilities : '' }
                    onChange={ handleInputChange }
                    error={ errors.customSuitabilities }
                    disabled={ loading }
                  />
                : null
            }
            <Button
              variant="contained"
              onClick={ handleSave }
              sx={{ marginTop: '1rem' }}
              disabled={ loading }
            >
              { t('Guardar cambios') }
            </Button>
          </Grid>
        </Grid>

        {
          error
            ? <Alert severity="error" sx={{ fontSize: '1rem', marginTop: '2rem' }}>
                { error }
              </Alert>
            : null
        }
      </Container>
      <Dialog open={ dialogOpen } PaperProps={{ sx: { borderRadius: '20px' } }}>
        <DialogContent sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem 4rem 1rem 4rem' }}>
          <Icon
            icon="ant-design:check-circle-outlined"
            style={{ fontSize: '10rem', color: '#BEA356' }}
          />
          <Typography variant="overline" sx={{ marginTop: '2rem' }}>
            { t('¡Los cambios se guardaron correctamente!') }
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', padding: '1rem 4rem 2rem 4rem' }}>
          <Button variant="contained" size="large" onClick={ handleSuccess }>{ t('Continuar') }</Button>
        </DialogActions>
      </Dialog>
    </RootStyle>
  );
}
