import { Alert, Container, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AlertStyled = styled(Alert)(() => ({
  justifyContent: 'center',
  textAlign: 'center',
  margin: '2rem 0',
}));

export default function LotsError() {
  const { t } = useTranslation();

  return (
    <Container>
      <AlertStyled variant="outlined" severity="error" icon={ false }>
        { t('Se produjo un error durante la carga de los lotes') }.
        { t('Por favor, recargue la página para intentar nuevamente') }
        <br />
        { t('Si el problema persiste comuniquese con') } soporte@lotes.com
      </AlertStyled>
    </Container>
  );
}
