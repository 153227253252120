import { Box, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import config from '../../config';
import { getRefreshToken, getToken } from '../../features/users/usersSlice';

const Content = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '2.5rem',
}));

const IframeStyled = styled('iframe')(() => ({
  width: '100%',
  border: 'none',
  height: '680px',
}));

interface StepCheckoutProps {
  nuCase: string;
}

export default function StepCheckout(props: StepCheckoutProps) {
  const { nuCase } = props;

  const token = useSelector(getToken);
  const refreshToken = useSelector(getRefreshToken);

  return (
    <Content>
      <Box sx={{ width: '100%', mb: '2.25rem', mt: '2.25rem' }}>
        <IframeStyled
          src={`${ config.api.url }/SLResourceShorcut?_idCreateSession=true&_idExecuteInMainFrame=` +
          `false&_idResource=SLExpedienteConsulta&nuExpedienteHidden=${ nuCase }&access_token=${ token }&` +
          `refresh_token=${ refreshToken }`}
        />
      </Box>
    </Content>
  );
}
