import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RootState, store } from '../../store';
import {
  fetchLots,
  getCurrentPage,
  getLots,
  getLotsError,
  getSurroundingLots,
  getTotalPages } from '../../features/lots/lotsSlice';
import { getLotsSelectedView,
  LotsViewType, openSidebar, selectLotsView } from '../../features/ui/UISlice';
import { FetchLotsOptions } from '../../features/lots/lotsSlice';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

import { Box, Button, Container, Paper, styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { GridView, MapOutlined } from '@mui/icons-material';

import Page from '../../components/Page';
import PageHeader from '../../components/Header';
import Sidebar from '../../components/Sidebar/Filters';
import Grid from './Grid';
import Map from './Map';
import LotsError from '../../components/LotsError';
import Surrounding from './Surrounding';
import GridLoader from './GridLoader';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: `calc(${ theme.sizes.navbar.height } + 1rem)`,
}));

export default function Lots() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const error = useSelector(getLotsError);
  const loading = useSelector((state: RootState) => state.lots.status === 'booting' || state.lots.status === 'loading');
  const lots = useSelector(getLots);
  const surrounding = useSelector(getSurroundingLots);
  const selectedView = useSelector(getLotsSelectedView);
  const currentPage = useSelector(getCurrentPage);
  const totalPages = useSelector(getTotalPages);

  const [currentOptions, setCurrentOptions] = useState<FetchLotsOptions>();

  // Scroll infinito
  const endRef = useRef<HTMLDivElement>(null);
  const endObserver = useIntersectionObserver(endRef);

  const handleChangeView = (event: React.MouseEvent<HTMLElement>, newView: LotsViewType) => {
    store.dispatch(selectLotsView(newView));
  };

  useEffect(() => {
    // Si no hay parámetros, despachamos inmediatamente la petición
    if (!Array.from(searchParams).length) {
      store.dispatch(fetchLots());
      return;
    }

    const options: FetchLotsOptions = {
      overwrite: true,
    };

    if (searchParams.has('page')) {
      const page = searchParams.get('page');
      if (page && parseInt(page) > 0) {
        options['page'] = parseInt(page);
      }
    }

    if (searchParams.has('zone')) {
      const zone = searchParams.get('zone');
      if (zone) {
        options['zone'] = zone;
      }
    }

    if (searchParams.has('money')) {
      const money = searchParams.get('money')?.split(',');
      if (money) {
        options['priceMin'] = parseInt(money[0]);
        options['priceMax'] = parseInt(money[1]);
      }
    }

    if (searchParams.has('userType')) {
      const userType = searchParams.get('userType');
      if (userType) {
        options['userType'] = parseInt(userType);
      }
    }

    if (searchParams.has('landType')) {
      const landType = searchParams.get('landType');
      if (landType) {
        options['landType'] = parseInt(landType);
      }
    }

    if (searchParams.has('suitabilities')) {
      const suitabilities = searchParams.get('suitabilities');
      if (suitabilities) {
        options['suitabilities'] = suitabilities.split(',').map(s => parseInt(s)).filter(e => e > 0);
      }
    }

    store.dispatch(fetchLots(options))
      .unwrap()
      .then(() => {
        setCurrentOptions(options);

        // Si se filtra por zona también consultamos los lotes cercanos
        if (options['zone']) {
          store.dispatch(fetchLots({ ...options, surroundingAreas: true }));
        }
      });
  }, [searchParams]);

  // Monitoreamos si el div entró en el viewport y si es así recuperamos más productos
  useEffect(() => {
    if (loading) {
      return;
    }

    if (endObserver?.isIntersecting === true && currentPage < totalPages) {
      store.dispatch(fetchLots({
        ...currentOptions,
        page: currentPage + 1,
        overwrite: false,
      }));
    }
  }, [endObserver?.isIntersecting]);

  return (
    <RootStyle title={ t('Lotes') }>
      <>
        <Sidebar />
        <PageHeader
          title={ searchParams.get('zone') ? t('Lotes en venta') : t('Todos los Lotes en venta') }
          actions={
            <>
              <ToggleButtonGroup
                size="small"
                sx={{ mr: 1 }}
                value={ selectedView }
                onChange={ handleChangeView }
                exclusive={ true }
              >
                <ToggleButton value="grid" key="grid">
                  <GridView />
                </ToggleButton>
                <ToggleButton value="map" key="map">
                  <MapOutlined />
                </ToggleButton>
              </ToggleButtonGroup>
              <Button
                variant="outlined"
                sx={{
                  paddingLeft: '2rem !important',
                  paddingRight: '2rem !important',
                  lineHeight: '1.25rem !important',
                }}
                size="large"
                startIcon={ <Icon icon="akar-icons:settings-horizontal" /> }
                onClick={ () => store.dispatch(openSidebar('filters')) }
              >
                { t('Filtros') }
              </Button>
            </>
          }
          sx={{ mb: '2rem' }}
        />
        <Container>
          {
            error
              ? <LotsError />
              : loading && !lots.length
                ? <GridLoader />
                : lots && lots.length
                  ? selectedView === 'grid'
                    ? <Grid lots={ lots } />
                    : <Map lots={ lots } />
                  : <Paper sx={{ padding: 4, display: 'flex', justifyContent: 'center' }}>
                      <Typography variant='h6'>{ t('No se encontraron lotes...') }</Typography>
                    </Paper>
          }
          <div ref={ endRef } />
        </Container>
        {
          surrounding && surrounding.length > 0
            ? <Box>
                <Surrounding lots={ surrounding } sx={{ marginTop: '2rem' }} />
              </Box>
            : null
        }
      </>
    </RootStyle>
  );
}
