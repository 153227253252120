import packageJson from '../package.json';

if (!process.env.REACT_APP_WS_URL || !process.env.REACT_APP_WS_USER || !process.env.REACT_APP_WS_PASS) {
  throw new Error('Falta configurar los parámetros de acceso a Deyel');
}

if (!process.env.REACT_APP_S3_HOST || !process.env.REACT_APP_S3_PATH) {
  throw new Error('Falta configurar los parámetros de S3');
}

const config = {
  site: {
    name: 'Plotland',
    no_image: '/static/images/no_image.svg',
  },
  s3: {
    url: process.env.REACT_APP_S3_HOST + process.env.REACT_APP_S3_PATH,
    path: process.env.REACT_APP_S3_PATH,
  },
  google: {
    maps: {
      key: 'AIzaSyCssJwCTEGdOxbnOtyJOJZ0UamAqfavYQc',
    },
  },
  version: packageJson.version,
  api: {
    url: process.env.REACT_APP_WS_URL,
    user: process.env.REACT_APP_WS_USER,
    pass: process.env.REACT_APP_WS_PASS,
    mocks: false,
  },
};

export default config;
