import { inputBaseClasses, inputClasses, Theme } from '@mui/material';

export default function InputBase(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          [`&.${ inputClasses.root }`]: {
            borderBottom: `1px solid ${ theme.palette.primary.light }`,
          },
          [`.${ inputBaseClasses.input }`]: {
            padding: '.75rem',
          },
          [`.${ inputClasses.inputTypeSearch }`]: {
            padding: '.5rem 0 calc(.5rem + 1px)',
            fontSize: '1.125rem',
            '::placeholder': {
              color: `${ theme.palette.text.secondary }`,
            },
          },
        },
      },
    },
  };
}
