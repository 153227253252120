import { PaletteOptions } from '@mui/material';

const palette: PaletteOptions = {
  primary: {
    light: '#bea356',
    main: '#6a4739',
  },
  secondary: {
    main: '#bea356',
  },
  text: {
    primary: '#333333',
    secondary: '#646464',
    disabled: '#e0e0e0',
  },
  background: {
    default: 'white',
    paper: 'white',
  },
  grey: {
    200: '#e4e4e4',
    500: '#a1a1a1',
  },
};

export default palette;
