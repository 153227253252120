import { Navigate, useRoutes } from 'react-router-dom';

import FullLayout from './layouts/FullLayout';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import About from './pages/About';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import Home from './pages/Home';
import Login from './pages/Login';
import Lot from './pages/Lot';
import Edit from './pages/Lot/Edit';
import Lots from './pages/Lots';
import MyData from './pages/Profile/Data';
import MyLots from './pages/Profile/Lots';
import MyMessages from './pages/Profile/Messages';
import MyOpportunities from './pages/Profile/Opportunities';
import MySubscriptions from './pages/Profile/Subscriptions';
import NotFound from './pages/NotFound';
import Opportunities from './pages/Opportunities';
import Profile from './pages/Profile';
import Publish from './pages/Publish';
import Subscribe from './pages/Suscribe';
import Terms from './pages/Terms';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <FullLayout />,
      children: [
        { path: '/about', element: <About /> },
        { path: '/contact', element: <Contact /> },
        { path: '/faq', element: <FAQ /> },
        { path: '/login', element: <Login /> },
        { path: '/lots/:title-:id', element: <Lot /> },
        { path: '/lots/:title-:id/edit', element: <Edit /> },
        { path: '/lots', element: <Lots /> },
        { path: '/opportunities', element: <Opportunities /> },
        {
          path: '/profile',
          element: <Profile />,
          children: [
            { path: '/profile/', element: <MyData /> },
            { path: '/profile/lots', element: <MyLots /> },
            { path: '/profile/messages', element: <MyMessages /> },
            { path: '/profile/opportunities', element: <MyOpportunities /> },
            { path: '/profile/subscriptions', element: <MySubscriptions /> },
          ],
        },
        { path: '/publish', element: <Publish /> },
        { path: '/subscribe/:id', element: <Subscribe /> },
        { path: '/terms', element: <Terms /> },
        { path: '/', element: <Home /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
