import { accordionSummaryClasses, Theme } from '@mui/material';

export default function AccordionSummary(theme: Theme) {
  return {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          fontFamily: 'Open Sans, sans-serif',
          fontWeight: 600,
          size: '1.125rem',
          color: theme.palette.text.secondary,

          [`.${ accordionSummaryClasses.content }`]: {
            alignItems: 'center',
          },

          [`&.${ accordionSummaryClasses.expanded }`]: {
            borderBottom: `solid 1px ${ theme.palette.text.disabled }`,

            [`.${ accordionSummaryClasses.content }`]: {
              marginBottom: '10px',
            },
          },
        },
      },
    },
  };
}
