import { Lot } from './lotsSlice';
import api, { GenericListResponse } from '../../services/api';
import config from '../../config';
import { store } from '../../store';

export interface LotData {
  id: number;

  ubicacion: string;

  moneda: number;
  precio: string;

  magnitud: number;
  superficie: number;

  ploLatitude: string;
  plotLongitude: string;

  imagenesIter: {
    'imagenesIter/fotos': string | null;
    'imagenesIter/imageURL': string;
    _nuSequence: number;
  }[];
  cantidadDeFotos: number;

  aptiTerreno: {
    'aptiTerreno/landSuitability': string
  }[];
  othersSuitability: string | null;

  descripcion: string;
  plotTitle: string | null;

  locationZone: string;

  tipoDeTerreno: string;

  oportunidad: boolean;

  estadoDePublicacion: number;
  dtLastUpdate: string;
  fechaDePublicacion: string;

  tipoDePublicante: number;
  publiIndividual: string | null;
  publiInmo: string | null;
  publisher: string | null;
  publisherTel: string | null;
}

export interface FetchLotsOptionsAPI {
  count?: number;
  page?: number;
  priceMin?: number;
  priceMax?: number;
  areaMin?: number;
  areaMax?: number;
  locationZone?: string;
  userType?: number;
  landType?: number;
  suitabilities?: number[];
  surroundingAreas?: boolean;
}

async function fetchLots(options: FetchLotsOptionsAPI = {}) {
  const search: string[] = [];

  if (options.locationZone) {
    search.push(`locationZone=eq:${ options.locationZone }`);
  }

  if (options.areaMin) {
    search.push(`superficie=gte:${ options.areaMin }`);
  }

  if (options.areaMax) {
    search.push(`superficie=lte:${ options.areaMax }`);
  }

  if (options.priceMin) {
    search.push(`precio=gte:${ options.priceMin }`);
  }

  if (options.priceMax) {
    search.push(`precio=lte:${ options.priceMax }`);
  }

  if (options.userType) {
    search.push(`tipoDePublicante=eq:${ options.userType }`);
  }

  if (options.landType) {
    search.push(`tipoDeTerreno=eq:${ options.landType }`);
  }

  if (options.suitabilities && options.suitabilities.length > 0) {
    search.push(`aptiTerreno/landSuitability=in:${ options.suitabilities.join('%7C') }`);
  }

  const response = await api<{ lands: GenericListResponse<LotData> }>('PUT', 'rules/plotGetLands-1/execute', {
    searchParams: {
      paging: {
        'page-number': options.page ? options.page : 1,
        'per-page': options.count ? options.count : 10
      },
      search: search.length > 0 ? search.join(',') : undefined,
      sort: 'dtStore'
    },
    surroundingAreas: options.surroundingAreas ? options.surroundingAreas : false
  });

  return {
    data: response.data.lands.instances?.map(instance => parseLot(instance)) ?? [],
    pagination: {
      current: response.data.lands.paging['page-number'],
      total: response.data.lands.paging.pages
    }
  };
}

async function fetchMyLots() {
  const response = await api<{ lands: GenericListResponse<LotData> }>('PUT', 'rules/plotGetMyLands-1/execute');

  return {
    data: response.data.lands.instances?.map(instance => parseLot(instance)) ?? []
  };
}

async function fetchMyOpportunities(options: FetchLotsOptionsAPI = {}) {
  const search: string[] = [];

  if (options.locationZone) {
    search.push(`locationZone=eq:${ options.locationZone }`);
  }

  if (options.areaMin) {
    search.push(`superficie=gte:${ options.areaMin }`);
  }

  if (options.areaMax) {
    search.push(`superficie=lte:${ options.areaMax }`);
  }

  if (options.priceMin) {
    search.push(`precio=gte:${ options.priceMin }`);
  }

  if (options.priceMax) {
    search.push(`precio=lte:${ options.priceMax }`);
  }

  if (options.userType) {
    search.push(`tipoDePublicante=eq:${ options.userType }`);
  }

  if (options.landType) {
    search.push(`tipoDeTerreno=eq:${ options.landType }`);
  }

  if (options.suitabilities && options.suitabilities.length > 0) {
    search.push(`aptiTerreno/landSuitability=in:${ options.suitabilities.join('%7C') }`);
  }

  const response = await api<{
    opportunities: GenericListResponse<LotData>
  }>('PUT', 'rules/plotGetOpportunities-1/execute', {
    searchParams: {
      paging: {
        'page-number': options.page ? options.page : 1,
        'per-page': options.count ? options.count : 20
      },
      search: search.length > 0 ? search.join(',') : undefined,
      sort: 'dtStore'
    }
  });

  return {
    data: response.data.opportunities.instances?.map(instance => parseLot(instance)) ?? []
  };
}

async function fetchLotById(id: number) {
  const response = await api<LotData>('GET', `forms/PROP1649165897/instances/${ id }`);

  return {
    data: parseLot(response.data)
  };
}

async function fetchLotIsSharable(id:number) {
  const response = await api<{resultRequest:string, shareOk:boolean}>('PUT', `rules/plotShareLots-1/execute`, { idLot: id });

  return {
    shareable: response.data.shareOk
  };
}


interface DeyelLotData {
  cdProcess: string;
  cdVersion: number;
  idApplication: string;
  fieldsActivity: {
    actionToDo: number;
    modifiedLot?: string;
    fechaDePublicacion: string;
    tipoDePublicante: number; // 1 Inmobiliaria, 2 Particular
    publiIndividual?: string;
    publiInmo?: string;
    estadoDePublicacion: number;
    descripcion: string;
    superficie: number;
    precio: string;
    magnitud: number; // 1 M2, 2 Ha
    moneda?: number; // 1 USD, undefined ARS
    tipoDeTerreno: string; // 1 Urbano, 2 Rural
    ubicacion: string;
    locationZone: string; // placeId
    plotLatitude: string;
    plotLongitude: string;
    plotTitle: string; // Máx length: 100
    lsAptitudes:{
      _nuSequence: number;
      'lsAptitudes/landSuitability': string;
    }[];
    otherSuitability?: string;
    cargaDeImagenes: {
      _nuSequence: number;
      'cargaDeImagenes/foto': string;
    }[],
    cantidadDeFotos: number;
  }
}

interface CreateLotResponse {
  cdCase: string;
  cdState: string;
  cdNextActivity: number;
}

export type CreateLot = Omit<Lot, 'url' | 'status'>;

async function createLot(lot: CreateLot) {
  const state = store.getState();
  const user = state.users;

  const payload: DeyelLotData = {
    cdProcess: '2327228069ef4b3c9b79c86cc9518144',
    cdVersion: 1,
    idApplication: 'PLOTLAND',
    fieldsActivity: {
      actionToDo: 1,
      fechaDePublicacion: Date.now().toString(),
      tipoDePublicante: user.type === 'realstate' ? 1 : 2,
      publiIndividual: user.type === 'individual' ? `${ user.id }` : undefined,
      publiInmo: user.type === 'realstate' ? `${ user.current?.realEstater }` : undefined,
      estadoDePublicacion: 1,
      descripcion: lot.description ? lot.description : lot.address,
      superficie: lot.area,
      precio: `${ lot.price }`,
      magnitud: lot.unit.id,
      moneda: lot.currency.id,
      tipoDeTerreno: `${ lot.type }`,
      ubicacion: lot.address,
      locationZone: lot.placeId,
      plotLatitude: `${ lot.lat }`,
      plotLongitude: `${ lot.lng }`,
      plotTitle: lot.title,
      lsAptitudes: lot.suitabilities.map((s, idx) => ({
        _nuSequence: idx,
        'lsAptitudes/landSuitability': `${ s }`
      })),
      otherSuitability: lot.suitabilities.findIndex(s => s === 7) > -1
        ? lot.customSuitabilities ?? undefined
        : undefined,
      cargaDeImagenes: lot.photos.map((p, idx) => (
        {
          _nuSequence: idx,
          'cargaDeImagenes/foto': p.replace(config.s3.url, '')
        }
      )),
      cantidadDeFotos: lot.photos.length
    }
  };

  const response = await api<CreateLotResponse>('POST', `cases`, payload);

  return {
    data: response.data
  };
}

async function updateLot(lot: Lot) {
  const state = store.getState();
  const user = state.users;

  const payload: DeyelLotData = {
    cdProcess: '2327228069ef4b3c9b79c86cc9518144',
    cdVersion: 1,
    idApplication: 'PLOTLAND',
    fieldsActivity: {
      actionToDo: 2,
      modifiedLot: `${ lot.id }`,
      fechaDePublicacion: Date.now().toString(),
      tipoDePublicante: user.type === 'realstate' ? 1 : 2,
      publiIndividual: user.type === 'individual' ? `${ user.id }` : undefined,
      publiInmo: user.type === 'realstate' ? `${ user.current?.realEstater }` : undefined,
      estadoDePublicacion: lot.status,
      descripcion: lot.description ? lot.description : lot.address,
      superficie: lot.area,
      precio: `${ lot.price }`,
      magnitud: lot.unit.id,
      moneda: lot.currency.id,
      tipoDeTerreno: `${ lot.type }`,
      ubicacion: lot.address,
      locationZone: lot.placeId,
      plotLatitude: `${ lot.lat }`,
      plotLongitude: `${ lot.lng }`,
      plotTitle: lot.title,
      lsAptitudes: lot.suitabilities.map((s, idx) => ({
        _nuSequence: idx,
        'lsAptitudes/landSuitability': `${ s }`
      })),
      otherSuitability: lot.suitabilities.findIndex(s => s === 7) > -1
        ? lot.customSuitabilities ?? undefined
        : undefined,
      cargaDeImagenes: lot.photos.map((p, idx) => (
        {
          _nuSequence: idx,
          'cargaDeImagenes/foto': p.replace(config.s3.url, '')
        }
      )),
      cantidadDeFotos: lot.photos.length
    }
  };

  const response = await api<CreateLotResponse>('POST', `cases`, payload);

  return {
    data: response.data
  };
}

async function visitLot(id: number) {
  await api('PUT', `rules/plotVisitsCounter-1/execute`, {
    idPlot: id
  });
}

export function parseLot(instance: LotData): Lot {
  const state = store.getState();
  const currencies = state.options.data.currencies;
  const units = state.options.data.units;

  let currency = currencies.find(c => c.id == instance.moneda)?.name;
  if (currency === 'Dolar') {
    currency = 'USD';
  }

  const unit = units.find(u => u.id == instance.magnitud)?.name;

  return {
    id: instance.id,

    title: instance.plotTitle ? instance.plotTitle : instance.ubicacion,
    description: 'descripcion' in instance ? instance.descripcion : undefined,
    address: instance.ubicacion,
    url: (instance.plotTitle ? instance.plotTitle : instance.ubicacion)
      .toLowerCase()
      .replace(/[^a-zA-Z\d\u00C0-\u00FF]/g, '_')
      .replaceAll('__', '_') + '-' + instance.id,

    type: parseInt(instance.tipoDeTerreno),
    placeId: instance.locationZone,
    suitabilities: instance.aptiTerreno
      .filter(a => parseInt(a['aptiTerreno/landSuitability']) !== 7) // Filtrar otras aptitudes
      .map(a => parseInt(a['aptiTerreno/landSuitability'])),
    customSuitabilities: instance.aptiTerreno.findIndex(a => parseInt(a['aptiTerreno/landSuitability']) === 7) > -1
      ? instance.othersSuitability ?? undefined
      : undefined,

    thumbnail: config.s3.url + instance.imagenesIter[0]['imagenesIter/imageURL'],
    photos: instance.imagenesIter.map(image => config.s3.url + image['imagenesIter/imageURL']),

    price: parseFloat(instance.precio),
    currency: {
      id: instance.moneda,
      name: currency ? currency : 'USD'
    },

    area: instance.superficie,
    unit: {
      id: instance.magnitud,
      name: unit ? unit : 'm²'
    },

    opportunity: 'oportunidad' in instance ? instance.oportunidad : false,

    lat: parseFloat(instance.ploLatitude),
    lng: parseFloat(instance.plotLongitude),

    status: instance.estadoDePublicacion,

    publisher: {
      name: instance.publisher ?? '?',
      tel: instance.publisherTel ?? '?'
    }
  };
}

const lotsAPI = {
  fetchLotById,
  fetchLots,
  fetchLotIsSharable,
  fetchMyLots,
  fetchMyOpportunities,
  createLot,
  updateLot,
  visitLot
};

export default lotsAPI;
