import { Theme } from '@mui/material';
import { merge } from 'lodash';

import Accordion from './Accordion';
import AccordionDetails from './AccordionDetails';
import AccordionSummary from './AccordionSummary';
import AppBar from './AppBar';
import Button from './Button';
import InputBase from './InputBase';
import OutlinedInput from './OutlinedInput';
import Slider from './Slider';
import Step from './Step';
import StepIcon from './StepIcon';
import Stepper from './Stepper';
import StepLabel from './StepLabel';
import Table from './Table';
import TableCell from './TableCell';
import TableRow from './TableRow';
import ToggleButton from './ToggleButton';
import ToggleButtonGroup from './ToggleButtonGroup';

/* eslint-disable new-cap */
export default function ComponentsOverrides(theme: Theme) {
  return merge(
    Accordion(),
    AccordionDetails(),
    AccordionSummary(theme),
    AppBar(),
    Button(),
    InputBase(theme),
    OutlinedInput(),
    Slider(theme),
    Step(),
    StepIcon(),
    Stepper(),
    StepLabel(),
    Table(),
    TableCell(theme),
    TableRow(),
    ToggleButton(),
    ToggleButtonGroup(),
  );
}
