import { Card, styled, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchMyLots, getMyLots, Lot } from '../../features/lots/lotsSlice';
import { store } from '../../store';
import LotItem from '../../components/LotItem';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CardStyled = styled(Card)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  borderRadius: '10px',
  overflow: 'hidden',
  minHeight: '240px',
  marginBottom: '1rem',
  border: `4px dotted ${ theme.palette.grey[200] }`,
  fontSize: '1.5rem',
  color: theme.palette.grey[400],
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.palette.grey[50],
  },

  [theme.breakpoints.up('sm')]: {
    minHeight: '160px',
  },
}));

export default function MyLots() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const lots = useSelector(getMyLots);

  const handlePublish = () => {
    navigate('/publish');
  };

  const handleEdit = (lot: Lot) => {
    navigate(`/lots/${ lot.url }/edit`);
  };

  useEffect(() => {
    store.dispatch(fetchMyLots());
  }, []);

  return (
    <>
      <Typography variant="h6">{ t('Mis Lotes') }</Typography>
      <CardStyled elevation={ 0 } onClick={ handlePublish }>
        { t('Publicar lote') }
      </CardStyled>
      {
        lots.map(l => (
          <LotItem
            key={ l.id }
            lot={ l }
            sx={{ marginBottom: '1rem' }}
            disabled
            edit
            onEdit={ handleEdit }
          />))
      }
    </>
  );
}
