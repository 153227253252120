import { Icon } from '@iconify/react';
import { Box, Button, Grid, styled, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import JoinLeft from '../../assets/images/join-left.svg';
import JoinRight from '../../assets/images/join-right.svg';

const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: '1rem',

  [theme.breakpoints.up('sm')]: {
    paddingLeft: '2rem',
  },
}));

const PublishButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: '1.125rem',
  marginBottom: '1.5625rem',

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    height: '36px',
    padding: '.5rem 1.125rem .5rem 1.25rem',
  },
}));

const JoinQuestion = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  textAlign: 'center',
  textDecoration: 'underline',

  [theme.breakpoints.up('sm')]: {
    fontSize: '1.125rem',
    textAlign: 'left',
    textDecoration: 'none',
    marginRight: '1.5rem',
  },
}));

interface JoinProps {
  sx?: SxProps<Theme>;
}

export default function Join(props: JoinProps) {
  const { sx } = props;

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid container sx={ sx }>
      {
        desktop
          ? <Grid item sm={ 5 }>
              <img src={ JoinLeft } style={{ maxWidth: '100%' }} />
            </Grid>
          : null
      }
      <Grid item sm={ 4 }>
        <ContentWrapper>
          <Typography variant="overline">{ t('Formá parte') }</Typography>
          <Typography variant="h3" sx={{ mb: '1.5625rem' }}>
            { t('¡Cuento con un lote que puede ser una gran oportunidad!') }
          </Typography>
          <Typography variant="body2" sx={{ mb: '2.1875rem' }}>
            { t('Publicá tu lote y accedé a un mercado ansioso por conseguir oportunidades.') }
          </Typography>
          <PublishButton
            variant="contained"
            startIcon={ <Icon icon="et:map" height="1.125rem" /> }
            onClick={ () => navigate('/publish') }
          >
            { t('PUBLICAR UN LOTE') }
          </PublishButton>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <JoinQuestion>
              <Trans i18nKey="por.que.sumarse" />
            </JoinQuestion>
            {
              desktop
                ? <Icon icon="cil:arrow-right" width="34px" />
                : null
            }
          </Box>
        </ContentWrapper>
      </Grid>
      {
        desktop
          ? <Grid item sm={ 3 } sx={{ textAlign: 'right' }}>
              <img src={ JoinRight } style={{ maxWidth: '100%' }} />
            </Grid>
          : null
      }
    </Grid>
  );
}
