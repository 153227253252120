import { SvgIcon } from '@mui/material';

interface ChevronLeftProps {
  sx?: Record<string, unknown>;
  color?: string;
}

/* eslint-disable max-len */
export default function ChevronLeftIcon(props: ChevronLeftProps) {
  const { sx, color = '#333333' } = props;

  return (
    <SvgIcon height="11" width="20" viewBox="0 0 11 20" sx={{ verticalAlign: 'middle', fill: '#ffffff', ...sx }}>
      <path d="M10 1L1 10L10 19" stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
}
