import { Container, Grid, Paper, styled } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Page from '../../components/Page';
import Menu from './Menu';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: `calc(${ theme.sizes.navbar.height } + 1rem)`,
}));

const PaperStyled = styled(Paper)(({ theme }) => ({
  borderRadius: '20px',
  boxShadow: theme.shadows[3],

  [theme.breakpoints.up('sm')]: {
    height: '100%',
    minHeight: '75vh',
  },
}));

export default function Profile() {
  return (
    <RootStyle title="Mi Perfil">
      <Container>
        <Grid container spacing={ 2 }>
          <Grid item md={ 3 } sx={{ display: { xs: 'none', sm: 'block' } }}>
            <PaperStyled sx={{ position: 'sticky' }}>
              <Menu sticky />
            </PaperStyled>
          </Grid>
          <Grid item xs={ 12 } md={ 9 }>
            <PaperStyled sx={{ padding: '1rem' }}>
              <Outlet />
            </PaperStyled>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
