import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { getPriceRangeMax, getPriceRangeMin } from '../features/options/optionsSlice';

import { Box, Button, Input, styled, Typography, useTheme } from '@mui/material';
import MoneyRangeSlider from './MoneyRangeSlider';
import { Icon } from '@iconify/react';
import { getMapIsLoaded } from '../features/ui/UISlice';
import { useTranslation } from 'react-i18next';

const BoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    marginLeft: '4rem',
    marginRight: '4rem',
  },
}));

const MoneyRangeWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '1rem',

  [theme.breakpoints.up('sm')]: {
    flexGrow: 1,
    marginBottom: 'inherit',
    marginRight: '2rem',
    width: '280px',
  },
}));

const QueryWrapper = styled(Box)(({ theme }) => ({
  marginBottom: '1rem',
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    flexGrow: 2,
    marginBottom: 0,
    marginLeft: '2rem',
    marginRight: '1rem',
    width: 'auto',
  },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  button: {
    width: '100%',
  },

  [theme.breakpoints.up('sm')]: {
    flexShrink: 1,
    marginLeft: '1rem',
  },
}));

interface SearchProps {
  onQuery: (params: Record<string, string>) => void;
}

export default function Search(props: SearchProps) {
  const { onQuery } = props;

  const { t } = useTranslation();

  const priceMin = useSelector(getPriceRangeMin);
  const priceMax = useSelector(getPriceRangeMax);
  const mapIsLoaded = useSelector(getMapIsLoaded);

  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>();

  const [query, setQuery] = useState('');
  const [zone, setZone] = useState<string>();
  const [money, setMoney] = useState<string>(`${ priceMin },${ priceMax }`);

  useEffect(() => {
    if (!mapIsLoaded || !inputRef.current) {
      return;
    }

    const newAutocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      { types: ['(cities)'] },
    );

    newAutocomplete.setFields(['formatted_address', 'place_id']); // specify what properties we will get from API

    // add a listener to handle when the place is selected
    newAutocomplete.addListener('place_changed', () => {
      if (!newAutocomplete) {
        return;
      }

      const place = newAutocomplete.getPlace();

      setQuery(place.formatted_address ? place.formatted_address : '');
      setZone(place.place_id);
    });
  }, [mapIsLoaded, inputRef.current]);

  const handleSearchClick = () => {
    if (!onQuery) {
      return;
    }

    const newQuery: Record<string, string> = {};
    if (zone) {
      newQuery['zone'] = zone;
    }

    if (money) {
      newQuery['money'] = money;
    }

    onQuery(newQuery);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleRangeChange = (value: number[]) => {
    setMoney(value.join(','));
  };

  return (
    <BoxStyled>
      <MoneyRangeWrapper>
        <Typography sx={{ color: theme.palette.primary.main, fontSize: '1.25rem' }}>
          { t('Rango de precio') }
        </Typography>
        <MoneyRangeSlider min={ priceMin } max={ priceMax } onChange={ handleRangeChange } />
      </MoneyRangeWrapper>
      <QueryWrapper>
        <Input
          type="search"
          inputRef={ inputRef }
          sx={{ width: '100%' }}
          value={ query }
          onChange={ handleInputChange }
          startAdornment={
            <Icon
              icon="bytesize:search"
              height="2rem"
              style={{
                color: '#6A4739',
                marginRight: '1rem',
              }} />
          }
          placeholder={ t('Ingresá ubicación, por ej: Buenos Aires') }
        />
      </QueryWrapper>
      <ButtonWrapper>
        <Button variant="contained" sx={{ pl: '2rem', pr: '2rem' }} onClick={ handleSearchClick }>
          { t('¡Buscar!') }
        </Button>
      </ButtonWrapper>
    </BoxStyled>
  );
}
