import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, IconButton, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import config from '../../config';
import { getRefreshToken, getToken } from '../../features/users/usersSlice';

const Content = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8rem',
}));

const IframeStyled = styled('iframe')(() => ({
  width: '100%',
  border: 'none',
  height: '680px',
}));

const baseIframeUrl = `${ config.api.url }/SLResourceShorcut` +
  '?_idCreateSession=true' +
  '&_idExecuteInMainFrame=false' +
  '&_idResource=SLProcessDispatcher' +
  '&cdAsunto=9da94503ebd64f82a5dbb80168184fc1' +
  '&cdProcess=9da94503ebd64f82a5dbb80168184fc1@|@1' +
  '&button=new' +
  '&FORM_INITIAL_VALUES_HIDDEN=';

interface StepUpgradeProps {
  packageId: number;
}

export default function StepUpgrade(props: StepUpgradeProps) {
  const { packageId } = props;

  const { t } = useTranslation();

  const token = useSelector(getToken);
  const refreshToken = useSelector(getRefreshToken);

  const [iframeUrl, setIframeUrl] = useState<string>();

  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);

  useEffect(() => {
    setIframeUrl(`${ baseIframeUrl
    }productToBeCanceled=${ packageId
    }&access_token=${ token
    }&refresh_token=${ refreshToken }`);
  }, []);

  return (
    <>
      <Content>
        <Typography variant="h4" color="primary">{ t('Máximo de Publicaciones Alcanzado' )}</Typography>
        <br/>
        {
          t(`Estimado Usuario, se ha alcanzado el número máximo de Publicaciones permitidas por su actual Pack.
          Para cambiarse a un Pack que permita un mayor número de Publicaciones, haga click en el siguiente botón:`)
        }
        <br/><br/>
        <Button variant="contained" onClick={ () => setSubscriptionDialogOpen(true) }>
          Cambiar Pack
        </Button>
      </Content>
      <Dialog open={ subscriptionDialogOpen } maxWidth="md" fullWidth>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <IconButton onClick={ () => setSubscriptionDialogOpen(false) }>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          {
            iframeUrl
              ? <IframeStyled src={ iframeUrl } />
              : null
          }
        </DialogContent>
      </Dialog>
    </>
  );
}
