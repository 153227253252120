import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState, store } from '../../store';
import { fetchLots, Lot } from '../../features/lots/lotsSlice';

import LotSlider from '../../components/LotSlider';
import Section from '../../components/Section';
import { SxProps, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SimilarLotsProps {
  current: Lot;
  sx?: SxProps<Theme>;
}

export default function SimilarLots(props: SimilarLotsProps) {
  const { current, sx } = props;

  const { t } = useTranslation();

  const lots = useSelector((state: RootState) => state.lots.list ? state.lots.list : []);

  useEffect(() => {
    store.dispatch(fetchLots({
      zone: current.placeId,
      landType: current.type,
      suitabilities: current.suitabilities,
      areaMin: current.area * .9,
      areaMax: current.area * 1.1,
      priceMin: current.price * .9,
      priceMax: current.price * 1.1,
      exclude: current.id,
    }));
  }, []);

  if (!lots || lots.length === 0) {
    return null;
  }

  return (
    <Section
      overline={ t('Te puede interesar') }
      title={ t('Publicaciones Similares') }
      sx={ sx }
    >
      <LotSlider lots={ lots } cols={ 3 } />
    </Section>
  );
}
