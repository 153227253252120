import { GenericList } from '../features/options/optionsSlice';

export function getLabel(list: GenericList, id: number): string {
  if (list.length == 0) {
    return 'S/D';
  }

  const item = list.find(c => c.id == id);
  if (!item) {
    return 'S/D';
  }

  return item.name;
}
