import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { store } from '../store';
import { getSubscriptions, fetchSubscriptions } from '../features/subscriptions/subscriptionsSlice';

import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  Typography } from '@mui/material';

import Page from '../components/Page';
import colors from '../theme/colors';
import BulletCheck from '../components/icons/BulletCheck';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import Opportunities1 from '../assets/images/opportunities-1.svg';
import Opportunities2 from '../assets/images/opportunities-2.svg';
import Opportunities3 from '../assets/images/opportunities-3.svg';
import BadgePremium from '../assets/images/badge-premium.svg';
import HeaderBackground from '../assets/header.jpg';
import PremiumLandscape from '../assets/images/premium-landscape.png';
import Price from '../components/Price';

const Header = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  paddingTop: `calc(${ theme.sizes.navbar.height } + 1rem)`,
  height: '445px',
  clipPath: 'ellipse(200% 100% at top)',
  backgroundImage: `url(${ HeaderBackground })`,
  backgroundSize: 'cover',

  [theme.breakpoints.up('sm')]: {
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    clipPath: 'ellipse(150% 100% at top)',
    height: '568px',
  },
}));

const Overline = styled(Typography)(({ theme }) => ({
  color: colors.brown,
  fontSize: '1.125rem',

  [theme.breakpoints.up('sm')]: {
    fontSize: '1.625rem',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: colors.brown,
  fontSize: '2.5rem',

  [theme.breakpoints.up('sm')]: {
    fontSize: '3.375rem',
    paddingBottom: '3rem', // Ajuste offset desde el centro
  },
}));

const InfoWrapper = styled(Container)(({ theme }) => ({
  transform: 'translateY(-10%)',

  [theme.breakpoints.up('sm')]: {
    transform: 'translateY(-40%)',
  },
}));

const ImagesWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  marginTop: '2rem',
  marginBottom: '2rem',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',

    justifyContent: 'space-around',
    alignItems: 'start',
  },
}));

const Step = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '1.25rem',

  img: {
    marginBottom: '1rem',
    marginTop: '2rem',
  },

  [theme.breakpoints.up('sm')]: {
    fontSize: '1.375rem',

    img: {
      marginTop: 0,
    },
  },
}));

const ChooseSubscription = styled(Box)(() => ({
  width: '100%',
  backgroundImage: `url(${ PremiumLandscape })`,
  backgroundSize: 'cover',
  paddingBottom: '2rem',
}));

const SubscriptionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

const Subscription = styled(Paper)(({ theme }) => ({
  padding: '2rem 1.5rem',
  textAlign: 'center',
  boxShadow: '0px 0px 20px 15px rgba(54, 73, 102, 0.05)',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 2,
  marginBottom: '1rem',

  img: {
    marginBottom: '1rem',
  },

  [theme.breakpoints.up('sm')]: {
    width: '33%',
    marginBottom: 0,

    '&:not(:last-child)': {
      marginRight: '2rem',
    },
  },
}));

const ButtonStyled = styled(Button)(() => ({
  width: '140px',
  marginTop: '1.75rem',
  marginBottom: '1rem',
}));

export default function Opportunities() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const subscriptions = useSelector(getSubscriptions);

  const handleSubscribeClick = (id: number) => {
    navigate(`/subscribe/${ id }`);
  };

  useEffect(() => {
    store.dispatch(fetchSubscriptions());
  }, []);

  return (
    <Page>
      <Header>
        <Overline>{ t('Las mejores oportunidades de inversión') }</Overline>
        <Title>
          { t('Encontralas') }
          <strong>
            <span style={{ borderBottom: '6px solid #EACE80' }}>
              { t('primero') }
            </span>
            { t('en Plotland') }
          </strong>
        </Title>
      </Header>
      <InfoWrapper>
        <Paper sx={{ textAlign: 'center', padding: '1.625rem', borderRadius: '10px' }}>
          <Typography variant="h4" mb=".5rem">{ t('¿Cómo funciona?') }</Typography>
          <Typography fontSize="1.25rem">
            <Trans i18nKey="suscribite">
              Suscribite, tenemos diferentes planes para vos, ya sea que quieras realizar un único negocio<br />
              o sea tu modo de vida. Con nuestros planes accederás a un mundo de oportunidades.
            </Trans>
          </Typography>
          <ImagesWrapper>
            <Step>
              <img src={ Opportunities1 } /><br />
              { t('Elegí tu membresía') }
            </Step>
            <Step>
              <img src={ Opportunities2 } /><br />
              <Trans i18nKey="enterate">Enterate antes que nadie<br />los lotes de más bajo precio</Trans>
            </Step>
            <Step>
              <img src={ Opportunities3 } /><br />
              <Trans i18nKey="contactate">Contactate<br />con el vendedor</Trans>
            </Step>
          </ImagesWrapper>
        </Paper>
      </InfoWrapper>
      <ChooseSubscription id="choose-subscription">
        <Container>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="overline">{ t('Beneficios Exclusivos') }</Typography>
            <Typography variant="h3">{ t('Elegí tu Membresía') }</Typography>
          </Box>
          <SubscriptionWrapper mt="2rem">
            {
              subscriptions.map(s => (
                <Subscription key={`subscription_${ s.id }`}>
                  <img src={ BadgePremium } style={{ maxWidth: '65px' }} /><br />
                  <Typography variant="h4">{ s.name }</Typography>
                  <List sx={{ flexGrow: 1 }}>
                    {
                      s.description.map((d, idx) => (
                        <ListItem key={`detail_${ idx }`}>
                          <ListItemIcon sx={{ minWidth: '42px' }}>
                            <BulletCheck />
                          </ListItemIcon>
                          <ListItemText primary={ d } />
                        </ListItem>
                      ))
                    }
                  </List>
                  <Box>
                    <Box>
                      <Price currency={ s.currency } value={ s.price } period={ t('mes') } />
                    </Box>
                    <ButtonStyled variant="outlined" onClick={ () => handleSubscribeClick(s.id) }>
                      { t('Contratar') }
                    </ButtonStyled>
                  </Box>
                </Subscription>
              ))
            }
          </SubscriptionWrapper>
        </Container>
      </ChooseSubscription>
    </Page>
  );
}
