import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { closeSidebar, getDrawerStatus } from '../../features/ui/UISlice';
import { getUserName, isAuthed, logout } from '../../features/users/usersSlice';
import { store } from '../../store';

import {
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  listItemTextClasses,
  styled,
  useTheme } from '@mui/material';
import { Close, ExitToApp, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Icon } from '@iconify/react';
import Logo from '../Logo';
import { useTranslation } from 'react-i18next';

const LinkStyled = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  fontSize: '1.375rem',
  fontWeight: 600,
  width: '100%',
}));

const SubmenuItemText = styled(ListItemText)(() => ({
  [`.${ listItemTextClasses.primary }`]: {
    fontSize: '1.125rem',
  },
}));

export default function SidebarGeneral() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const open = useSelector(getDrawerStatus('general'));
  const authed = useSelector(isAuthed);
  const username = useSelector(getUserName);

  const [submenuOpen, setSubmenuOpen] = useState(false);

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    store.dispatch(logout());
    navigate('/');
  };

  const handleCloseClick = () => store.dispatch(closeSidebar());
  const handleSubmenuClick = () => setSubmenuOpen(!submenuOpen);
  const handleProfileClick = () => navigate('/profile');
  const handleMyLotsClick = () => navigate('/profile/lots');
  const handleMyOpportunitiesClick = () => navigate('/profile/opportunities');
  const handleMySubscriptionsClick = () => navigate('/profile/subscriptions');
  const handleMyMessagesClick = () => navigate('/profile/messages');

  return (
    <Drawer open={ open } anchor="right" onClose={ handleCloseClick }>
      <Box sx={{ width: '320px' }}>
        <List>
          <ListItem sx={{ borderBottom: `solid 1px ${ theme.palette.grey[200] }` }}>
            <Box sx={{ flexGrow: 1 }}>
              <Logo sx={{ height: '26px', width: 'auto' }} />
            </Box>
            <IconButton onClick={ handleCloseClick }>
              <Close />
            </IconButton>
          </ListItem>
          {
            authed
              ? <>
                  <ListItemButton
                    onClick={ handleSubmenuClick }
                    sx={{
                      borderBottom: submenuOpen ? 'none' : `solid 1px ${ theme.palette.grey[200] }`,
                    }}>
                    <ListItemIcon>
                      <Icon
                        icon="carbon:user-avatar-filled"
                        color={ theme.palette.primary.main }
                        height={ 50 }
                        width={ 50 }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        sx: {
                          fontSize: '1.375rem',
                          fontWeight: 600,
                        },
                      }}
                      primary={ username }
                    />
                    { submenuOpen ? <ExpandLess /> : <ExpandMore /> }
                  </ListItemButton>
                  <Collapse
                    in={ submenuOpen }
                    timeout="auto"
                    unmountOnExit
                    sx={{ borderBottom: `solid 1px ${ theme.palette.grey[200] }` }}
                  >
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: '4.5rem' }} onClick={ handleProfileClick }>
                        <SubmenuItemText primary={ t('Mi Perfil') } />
                      </ListItemButton>
                      <ListItemButton sx={{ pl: '4.5rem' }} onClick={ handleMyLotsClick }>
                        <SubmenuItemText primary={ t('Mis Lotes') } />
                      </ListItemButton>
                      <ListItemButton sx={{ pl: '4.5rem' }} onClick={ handleMyOpportunitiesClick }>
                        <SubmenuItemText primary={ t('Mis Oportunidades') } />
                      </ListItemButton>
                      <ListItemButton sx={{ pl: '4.5rem' }} onClick={ handleMySubscriptionsClick }>
                        <SubmenuItemText primary={ t('Mis Suscripciones') } />
                      </ListItemButton>
                      <ListItemButton sx={{ pl: '4.5rem' }} onClick={ handleMyMessagesClick }>
                        <SubmenuItemText primary={ t('Mis Mensajes') } />
                      </ListItemButton>
                      <ListItemButton sx={{ pl: '4.5rem' }} onClick={ handleLogout }>
                        <SubmenuItemText primary={ t('Cerrar Sesión') } />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </>
              : <ListItemButton
                  sx={{ borderBottom: `solid 1px ${ theme.palette.grey[200] }` }}
                  onClick={ handleLogin }
                >
                  <ListItemIcon>
                    <ExitToApp height="1.125rem" color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: {
                        fontSize: '1.375rem',
                        color: theme.palette.primary.main,
                      },
                    }}
                    primary={ t('INGRESAR') }
                  />
                </ListItemButton>
          }
          <ListItem>
            <LinkStyled to="/lots">{ t('Lotes en venta') }</LinkStyled>
          </ListItem>
          <ListItem>
            <LinkStyled to="/opportunities">{ t('Oportunidades') }</LinkStyled>
          </ListItem>
          <ListItem>
            <LinkStyled to="/about">{ t('Quiénes somos') }</LinkStyled>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}
