import { ChangeEvent, useState } from 'react';

import { Lot } from '../../features/lots/lotsSlice';
import { User } from '../../features/users/usersSlice';

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import RealStateIcon from '../../icons/RealState';
import PhoneIcon from '../../icons/Phone';
import colors from '../../theme/colors';
import Register from '../../components/Register';
import { store } from '../../store';
import { sendMessage } from '../../features/comms/commsSlice';
import { Trans, useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';

interface LotContactProps {
  lot: Lot;
  user?: User;
}

export default function LotContact(props: LotContactProps) {
  const { lot, user } = props;

  const { t } = useTranslation();

  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<'idle' | 'error' | 'success'>('idle');
  const [openRegister, setOpenRegister] = useState(false);

  const handleSend = () => {
    // Despachamos el mensaje
    store
      .dispatch(
        sendMessage({
          lotId: lot.id,
          message
        })
      )
      .unwrap()
      // eslint-disable-next-line arrow-parens
      .then((response) => {
        if (!response.messageSended) {
          setStatus('error');
          return;
        }

        setMessage('');
        setStatus('success');
      });
  };

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    // Guardamos el mensaje
    setMessage(event.target.value);

    // Reseteamos el estado
    if (status !== 'idle') {
      setStatus('idle');
    }
  };

  return (
    <Paper sx={{ borderRadius: '10px', paddingBottom: '1rem' }}>
      <Typography
        sx={{
          color: colors.brown,
          fontSize: '1.375rem',
          fontWeight: 600,
          paddingTop: '1.4375rem',
          paddingBottom: '1.25rem',
          textAlign: 'center'
        }}
      >
        {t('Comunicate con el anunciante')}
      </Typography>
      <hr style={{ border: 'none', borderTop: '1px solid #EEEEEE' }} />
      <Paper
        sx={{
          borderRadius: '5px',
          margin: '2rem',
          padding: '1.125rem .875rem .875rem .875rem',
          display: 'flex',
          mb: '1.25rem'
        }}
      >
        <RealStateIcon
          sx={{ fontSize: '60px', color: '#ffffff', mr: '.875rem' }}
        />
        <Box>
          <Typography sx={{ fontSize: '1.375rem', fontWeight: 600 }}>
            {lot.publisher?.name}
          </Typography>
          {user ? (
            <Typography sx={{ fontSize: '1.125rem' }}>
              <PhoneIcon
                sx={{ fontSize: '1.25rem', color: '#ffffff', mr: '.5rem' }}
              />
              <a
                href={`https://wa.me/${lot.publisher?.tel.replace(/\D/g, '')}`}
                target="_blank"
                rel="noreferrer"
                style={{ color: '#bea356' }}
              >
                +{lot.publisher?.tel}
              </a>
            </Typography>
          ) : (
            <>
              <Button
                fullWidth
                variant="text"
                onClick={() => setOpenRegister(true)}
              >
                <Trans i18nKey="registrate">
                  Registrate para
                  <br />
                  enviar un mensaje
                </Trans>
              </Button>
              <Dialog open={openRegister} maxWidth="md" fullWidth keepMounted>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center'
                  }}
                >
                  <IconButton onClick={() => setOpenRegister(false)}>
                    <Close />
                  </IconButton>
                </Box>
                <DialogContent>
                  <Register />
                </DialogContent>
              </Dialog>
            </>
          )}
        </Box>
      </Paper>
      {user ? (
        <Box sx={{ margin: '2rem', paddingBottom: '2.125rem' }}>
          <TextField
            type="text"
            multiline
            placeholder="Mensaje"
            sx={{ width: '100%', height: '6.5rem', mb: '1rem' }}
            value={message}
            onChange={handleInputChange}
          />
          <Button
            variant="outlined"
            sx={{ width: '100%', mb: '1rem' }}
            onClick={handleSend}
          >
            {t('Enviar')}
          </Button>
          {status === 'error' ? (
            <Alert
              severity="error"
              sx={{ fontSize: '.8rem', marginBottom: '1rem' }}
            >
              {t(
                'Se produjo un error al enviar el mensaje. Por favor, intente nuevamente'
              )}
            </Alert>
          ) : null}
          {status === 'success' ? (
            <Alert
              severity="success"
              sx={{ fontSize: '.8rem', marginBottom: '1rem' }}
            >
              {t('¡El mensaje fue enviado correctamente!')}
            </Alert>
          ) : null}
          <Typography sx={{ textAlign: 'center', textDecoration: 'underline' }}>
            {t('Ver más avisos del anunciante')}
          </Typography>
        </Box>
      ) : null}
    </Paper>
  );
}
