import { useState } from 'react';

import { Box, Button, Menu, MenuItem, styled, SxProps, Theme } from '@mui/material';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { languages } from '../services/i18n';
import { store } from '../store';
import { changeLang, fetchOptions } from '../features/options/optionsSlice';

const ButtonStyled = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.375rem',
  fontWeight: 600,
}));

const MenuItemStyled = styled(MenuItem)(() => ({
  fontWeight: 600,
  padding: '.5rem 2rem',
  letterSpacing: '0.02',
}));

interface LanguageSelectorProps {
  sx?: SxProps<Theme>
}

export default function LanguageSelector(props: LanguageSelectorProps) {
  const { sx } = props;

  const popupState = usePopupState({ variant: 'popover', popupId: 'userMenu' });
  const { i18n } = useTranslation();

  const [value, setValue] = useState<number>(languages.indexOf(i18n.language));

  const handleLanguageSelection = (language: string) => {
    const idx = languages.indexOf(language);

    if (idx !== -1) {
      setValue(idx);
    }

    i18n.changeLanguage(language);

    localStorage.setItem('lang', language);

    store.dispatch(fetchOptions());
    store.dispatch(changeLang(language));

    popupState.close();
  };

  return (
    <Box sx={ sx }>
      <ButtonStyled
        endIcon={
          popupState.isOpen
            ? <ExpandLess fontSize="large" />
            : <ExpandMore fontSize="large" />
        }
        { ...bindTrigger(popupState) }
      >
        { value in languages ? languages[value].toUpperCase() : 'N/A' }
      </ButtonStyled>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          languages.map(opt => (
            <MenuItemStyled key={ opt } onClick={ () => handleLanguageSelection(opt) }>
              { opt.toUpperCase() }
            </MenuItemStyled>
          ))
        }
      </Menu>
    </Box>
  );
}
