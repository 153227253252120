import { Box, Container, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Page from '../../components/Page';
import Search from '../../components/Search';
import FeaturedZones from './FeaturedZones';
import Join from './Join';
import Newest from './Newest';
import PremiumPromo from './PremiumPromo';

import HeaderBackground from '../../assets/header.jpg';
import Star from '../../assets/star.svg';

const Header = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  paddingTop: `calc(${ theme.sizes.navbar.height } + 1rem)`,
  height: '445px',
  clipPath: 'ellipse(200% 100% at top)',
  backgroundImage: `url(${ HeaderBackground })`,
  backgroundSize: 'cover',

  [theme.breakpoints.up('sm')]: {
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    clipPath: 'ellipse(150% 100% at top)',
    height: '568px',
  },
}));

const SearchWrapper = styled(Container)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '30px',
  position: 'relative',
  boxShadow: '0px 0px 20px 5px rgba(54, 73, 102, 0.05)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1.5rem',
  transform: 'translateY(-64%)',
  marginLeft: '.75rem',
  marginRight: '.75rem',
  width: 'auto',

  [theme.breakpoints.up('sm')]: {
    borderRadius: '65px',
    height: '130px',
    padding: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const Overline = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '1.125rem',

  [theme.breakpoints.up('sm')]: {
    fontSize: '1.625rem',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '2.5rem',

  [theme.breakpoints.up('sm')]: {
    fontSize: '3.375rem',
    paddingBottom: '3rem', // Ajuste offset desde el centro
  },
}));

export default function Home() {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onQuery = (params: Record<string, string>) => {
    const newParams = new URLSearchParams(params);

    navigate(`/lots?${ newParams.toString() }`);
  };

  return (
    <Page>
      <Header>
        <Overline>{ t('Las mejores oportunidades de inversión') }</Overline>
        <Title>
          { t('Encontrá tu próximo') }
          { desktop ? ' ' : '<br />' }
          <strong>{ t('lote en Plotland') }</strong>
        </Title>
      </Header>
      <SearchWrapper>
        <Search onQuery={ onQuery } />
      </SearchWrapper>
      <Container>
        <img src={ Star } />
      </Container>
      <Newest sx={{ mb: desktop ? '6rem' : '3.75rem' }} />
      <FeaturedZones sx={{ mb: desktop ? '10rem' : '3.75rem' }} />
      <Join />
      <PremiumPromo />
    </Page>
  );
}
