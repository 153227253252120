import { toggleButtonClasses } from '@mui/material';

export default function ToggleButton() {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '30px',
          borderColor: 'white',
          minWidth: '50px',

          '&:not(:last-of-type)': {
            borderRight: 'none',
          },

          [`&:hover`]: {
            backgroundColor: '#f2e1bc',
          },

          [`&.${toggleButtonClasses.selected}`]: {
            backgroundColor: '#EEEAE8',

            [`&:hover`]: {
              backgroundColor: '#f2e1bc',
            },
          },
        },
      },
    },
  };
}
