import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  inputBaseClasses,
  styled,
  TextField,
  Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserDeyel } from '../../features/users/usersAPI';
import { changePassword, getUser, updateUser } from '../../features/users/usersSlice';
import { store } from '../../store';
import { getErrorMessage } from '../../utils/errorHandler';

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  color: theme.palette.text.primary,

  [`.${ inputBaseClasses.disabled }`]: {
    WebkitTextFillColor: `${ theme.palette.text.secondary } !important`,
  },
}));

const ButtonLink = styled(Button)(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.primary.main,

  ':hover': {
    textDecoration: 'underline',
    color: theme.palette.primary.light,
    backgroundColor: 'transparent',
  },
}));

export default function Data() {
  const { t } = useTranslation();

  const [data, setData] = useState<Partial<UserDeyel>>({
    nombre: '',
    apellido: '',
    correoEle: '',
    telefono: '',
    nroIdPersonal: '',
  });
  const [passwordData, setPasswordData] = useState({
    current: '',
    renewed: '',
    repetition: '',
  });
  const [editing, setEditing] = useState(false);
  const [alert, setAlert] = useState<string>();
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [passwordAlert, setPasswordAlert] = useState<string>();
  const [error, setError] = useState('');

  const user = useSelector(getUser);

  useEffect(() => {
    if (!user) {
      return;
    }

    setData({
      nombre: user.nombre,
      apellido: user.apellido,
      correoEle: user.correoEle,
      telefono: user.telefono,
      nroIdPersonal: user.nroIdPersonal,
    });
  }, [user]);

  const handleCancelation = () => {
    if (!user) {
      setData({
        nombre: '',
        apellido: '',
        correoEle: '',
        telefono: '',
        nroIdPersonal: '',
      });
    } else {
      setData(user);
    }
    setEditing(false);
  };

  const handleSubmit = () => {
    setError('');

    store.dispatch(updateUser(data))
      .unwrap()
      .then(data => {
        setAlert(data.resultRequest);
        setEditing(false);
      })
      .catch(err => {
        console.error(err);
        setError(getErrorMessage(err));
      });
  };

  const handlePasswordDialogClose = () => {
    setShowPasswordDialog(false);
  };

  const handlePasswordDialogSubmit = () => {
    if (passwordData.renewed !== passwordData.repetition) {
      setPasswordAlert(t('No coinciden las contraseñas ingresadas'));
      return;
    }

    store.dispatch(changePassword({
      currentPassword: passwordData.current,
      newPassword: passwordData.renewed,
    }))
      .unwrap()
      .then(data => {
        if (data.result === 'Contraseña modificada exitosamente') {
          setAlert(data.result);
          setShowPasswordDialog(false);
          return;
        }

        setPasswordAlert(data.result);
      })
      .catch(err => {
        console.error(err);
        setPasswordAlert(getErrorMessage(err));
      });
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPasswordData({
      ...passwordData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Grid container spacing={ 2 }>
        {
          alert
            ? <Grid item xs={ 12 }>
                <Alert severity="info" onClose={ () => setAlert(undefined) } sx={{ fontSize: '1rem' }}>
                  { alert }
                </Alert>
              </Grid>
            : null
        }
        <Grid item xs={ 12 } sm={ 6 }>
          <Typography variant="h6">{ t('Datos Personales') }</Typography>
        </Grid>
        <Grid item xs={ 12 } sm={ 6 } sx={{ textAlign: 'right' }}>
          <ButtonLink
            variant="text"
            size="small"
            disableRipple
            onClick={ () => setEditing(true) }
          >
            { t('Editar') }
          </ButtonLink>
        </Grid>

        <Grid item xs={ 12 } sm={ 6 }>
          <FormLabel>{ t('Nombre') }</FormLabel>
          <TextFieldStyled
            margin="dense"
            fullWidth
            placeholder={ t('Nombre') }
            name="nombre"
            onChange={ onChange }
            disabled={ !editing }
            value={ data.nombre }
          />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <FormLabel>{ t('Apellido') }</FormLabel>
          <TextFieldStyled
            margin="dense"
            fullWidth
            placeholder={ t('Apellido') }
            name="apellido"
            onChange={ onChange }
            disabled={ !editing }
            value={ data.apellido }
          />
        </Grid>

        <Grid item xs={ 12 } sm={ 6 }>
          <FormLabel>{ t('Correo electrónico') }</FormLabel>
          <TextFieldStyled
            margin="dense"
            fullWidth
            placeholder={ t('Correo electrónico') }
            name="correoEle"
            onChange={ onChange }
            disabled={ !editing }
            value={ data.correoEle }
          />
        </Grid>

        <Grid item xs={ 6 }>
          <FormLabel>{ t('Teléfono') }</FormLabel>
          <TextFieldStyled
            margin="dense"
            fullWidth
            placeholder={ t('Teléfono') }
            name="telefono"
            onChange={ onChange }
            disabled={ !editing }
            value={ data.telefono }
          />
        </Grid>

        <Grid item xs={ 6 }>
          <FormLabel>{ t('N° documento') }</FormLabel>
          <TextFieldStyled
            margin="dense"
            fullWidth
            placeholder={ t('N° documento') }
            name="nroIdPersonal"
            onChange={ onChange }
            disabled={ !editing }
            value={ data.nroIdPersonal }
          />
        </Grid>
        {
          editing
            ? <Grid item xs={ 12 } sx={{ textAlign: 'right' }}>
                <Button
                  variant="outlined"
                  sx={{ marginRight: '1rem', paddingLeft: '2rem', paddingRight: '2rem' }}
                  onClick={ handleCancelation }
                >
                  { t('Cancelar') }
                </Button>
                <Button
                  variant="contained"
                  sx={{ paddingLeft: '2rem', paddingRight: '2rem' }}
                  onClick={ handleSubmit }
                >
                  { t('Aceptar') }
                </Button>
              </Grid>
            : null
        }
      </Grid>

      <Grid container spacing={ 2 } sx={{ marginTop: '4rem' }}>
        <Grid item xs={ 12 } sm={ 6 }>
          <Typography variant="h6">{ t('Usuario') }</Typography>
        </Grid>
        <Grid item xs={ 12 } sm={ 6 } sx={{ textAlign: 'right' }}>
          <ButtonLink
            variant="text"
            size="small"
            disableRipple
            onClick={ () => setShowPasswordDialog(true) }
          >
            { t('Cambiar Contraseña') }
          </ButtonLink>
        </Grid>
        <Grid item xs={ 12 }>
          <strong>{ t('Nombre de usuario') }</strong>: { data.correoEle }
        </Grid>
      </Grid>

      {
        error
          ? <Alert severity="error" sx={{ fontSize: '1rem', marginTop: '2rem' }}>
              { error }
            </Alert>
          : null
      }

      <Dialog
        open={ showPasswordDialog }
        maxWidth="sm"
        onClose={ handlePasswordDialogClose }
      >
        <DialogTitle>{ t('Cambiar Contraseña') }</DialogTitle>
        <DialogContent>
          <Grid container spacing={ 2 }>
            <Grid item xs={12}>
              <FormLabel>{ t('Contraseña Actual') }</FormLabel>
              <TextField
                type="password"
                fullWidth
                name="current"
                onChange={ onPasswordChange }
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{ t('Nueva Contraseña') }</FormLabel>
              <TextField
                type="password"
                fullWidth
                name="renewed"
                onChange={ onPasswordChange }
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{ t('Repetir Contraseña') }</FormLabel>
              <TextField
                type="password"
                fullWidth
                name="repetition"
                onChange={ onPasswordChange }
              />
            </Grid>
            {
              passwordAlert
                ? <Grid item xs={ 12 }>
                    <Alert severity="warning" onClose={ () => setPasswordAlert(undefined) } sx={{ fontSize: '1rem' }}>
                      { passwordAlert }
                    </Alert>
                  </Grid>
                : null
            }
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: '1rem' }}>
          <Button
            variant="outlined"
            onClick={ handlePasswordDialogClose }
            sx={{ padding: '.625rem 2rem' }}
          >{ t('Cancelar') }</Button>
          <Button
            variant="contained"
            onClick={ handlePasswordDialogSubmit }
            sx={{ padding: '.625rem 2rem' }}
            autoFocus
          >{ t('Aceptar') }</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
