import { Box, styled, SxProps, Theme, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Wrapper = styled(Box)(() => ({
  display: 'inline-flex',
  alignItems: 'baseline',
}));

const Currency = styled(Typography)(() => ({
  fontSize: '1.25rem',
  fontWeight: 400,
  letterSpacing: '0.05rem',
  marginRight: '.5rem',
}));

const Value = styled(Typography)(() => ({
  fontSize: '1.5rem',
  fontWeight: 700,
  letterSpacing: '0.05rem',
}));

const Period = styled(Typography)(() => ({
  display: 'inline-block',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '1.125rem',
  fontWeight: 600,
  letterSpacing: '0.05rem',
}));

interface PriceProps {
  color?: string;
  currency?: string;
  value: string | number;
  period?: string;
  sx?: SxProps<Theme>;
}

export default function Price(props: PriceProps) {
  const { currency = 'USD', value, period, sx } = props;

  const { i18n } = useTranslation();

  const [parsedValue, setParsedValue] = useState('');

  useEffect(() => {
    let newValue: number;
    if (typeof value === 'string') {
      newValue = parseFloat(value);
    } else {
      newValue = value;
    }

    const parsed = new Intl.NumberFormat(i18n.language, {
      minimumFractionDigits: 0,
      useGrouping: true,
    }).format(newValue);

    setParsedValue(parsed);
  }, [value]);

  return (
    <Wrapper sx={ sx }>
      <Currency>{ currency }</Currency>
      <Value>{ parsedValue }</Value>
      { period ? <Period>/{ period }</Period> : null }
    </Wrapper>
  );
}
