import { ChangeEvent, useEffect, useRef, useState } from 'react';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import NumericField, {
  HTMLNumericElement,
} from '../../components/NumericField';
import { useSelector } from 'react-redux';
import {
  getCurrencies,
  getSuitabilities,
  getLandTypes,
  getUnits,
  fetchOptions,
  selectOptions,
} from '../../features/options/optionsSlice';
import { Lot } from '../../features/lots/lotsSlice';
import { getMapIsLoaded } from '../../features/ui/UISlice';
import { Trans, useTranslation } from 'react-i18next';
import { store } from '../../store';

const Content = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '2.5rem',
}));

interface StepInformationProps {
  onFinish: (data: Partial<Lot>) => void;
}

type LotErrors = Partial<Record<keyof Lot, boolean>>;

export default function StepInformation(props: StepInformationProps) {
  const { onFinish } = props;

  const { t } = useTranslation();

  const addressRef = useRef<HTMLInputElement>(null);

  const [showInfo, setShowInfo] = useState(false);

  const [data, setData] = useState<Partial<Lot>>({
    title: '',
    type: 1,
  });
  const [errors, setErrors] = useState<LotErrors>({});
  const [optionsError, setOptionsError] = useState(false);

  const [suitabilitiesValues, setSuitabilitiesValues] = useState<number[]>([]);
  const [address, setAddress] = useState({
    address: '',
    placeId: '',
    lat: 0,
    lng: 0,
  });

  const options = useSelector(selectOptions);
  const mapIsLoaded = useSelector(getMapIsLoaded);
  const currencies = useSelector(getCurrencies);
  const units = useSelector(getUnits);
  const suitabilities = useSelector(getSuitabilities);
  const landTypes = useSelector(getLandTypes);

  const handleNextClick = () => {
    const newErrors: LotErrors = {};
    let count = 0;

    if (!data.price || data.price == 0) {
      newErrors.price = true;
      count++;
    }

    if (!data.area || data.area == 0) {
      newErrors.area = true;
      count++;
    }

    if (!address.address || !address.address.trim()) {
      newErrors.address = true;
      count++;
    }

    if (!data.description || !data.description.trim()) {
      newErrors.description = true;
      count++;
    }

    if (!data.title || !data.title.trim()) {
      newErrors.title = true;
      count++;
    }

    if (suitabilitiesValues.length == 0) {
      newErrors.suitabilities = true;
      count++;
    }

    if (suitabilitiesValues.includes(7) && !data.customSuitabilities) {
      newErrors.customSuitabilities = true;
      count++;
    }

    if (count > 0) {
      setErrors(newErrors);
      return;
    }

    onFinish({
      ...data,
      ...address,
      suitabilities: suitabilitiesValues,
    });
  };

  const handleCurrencyChange = (event: SelectChangeEvent<number>) => {
    setData({
      ...data,
      currency: {
        id: +event.target.value,
        name: currencies.find((c) => c.id == +event.target.value)!.name,
      },
    });
  };

  const handleUnitChange = (event: SelectChangeEvent<number>) => {
    setData({
      ...data,
      unit: {
        id: +event.target.value,
        name: units.find((c) => c.id == +event.target.value)!.name,
      },
    });
  };

  const handleInputChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLNumericElement
    >,
  ) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });

    // Una vez que verificamos que la propiedad existe en el objeto, suprimimos el error ts(7053)
    if (
      event.target.name in errors &&
      errors[event.target.name as keyof LotErrors]
    ) {
      setErrors({
        ...errors,
        [event.target.name]: false,
      });
    }
  };

  const handleSuitabilitiesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const changedSuitability = parseInt(event.target.value);

    let newSuitabilities = [...suitabilitiesValues];
    if (suitabilitiesValues.includes(changedSuitability)) {
      newSuitabilities = suitabilitiesValues.filter(
        (a) => a !== changedSuitability,
      );
    } else {
      newSuitabilities.push(changedSuitability);
    }

    setSuitabilitiesValues(newSuitabilities);

    if (errors.suitabilities) {
      setErrors({
        ...errors,
        suitabilities: false,
      });
    }
  };

  useEffect(() => {
    if (!mapIsLoaded || !addressRef.current) {
      return;
    }

    const newAutocomplete = new window.google.maps.places.Autocomplete(
      addressRef.current,
    );
    const newGeocoder = new google.maps.Geocoder();

    newAutocomplete.setFields([
      'address_components',
      'formatted_address',
      'place_id',
      'geometry',
    ]);

    // add a listener to handle when the place is selected
    newAutocomplete.addListener('place_changed', () => {
      if (!newAutocomplete) {
        return;
      }

      const place = newAutocomplete.getPlace();

      if (
        !place.place_id ||
        !place.formatted_address ||
        !place.geometry?.location
      ) {
        return;
      }

      newGeocoder.geocode(
        {
          location: place.geometry.location,
        },
        (results, status) => {
          if (
            !place.place_id ||
            !place.formatted_address ||
            !place.geometry?.location
          ) {
            return;
          }

          // Si no devolvió resultados utilizamos los datos de autocomplete
          if (
            status !== window.google.maps.GeocoderStatus.OK ||
            !Array.isArray(results)
          ) {
            setAddress({
              address: place.formatted_address,
              placeId: place.place_id,
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            });
            return;
          }

          const usefulResults = results.filter((r) => {
            return (
              r.types.includes('locality') ||
              r.types.includes('administrative_area_level_2')
            );
          });

          // Si ningún resultado es de los que necesitamos utilizamos los datos de autocomplete
          if (usefulResults.length == 0) {
            setAddress({
              address: place.formatted_address,
              placeId: place.place_id,
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            });
            return;
          }

          // Si pudimos determinar la localidad utilizamos el placeId de la misma
          setAddress({
            address: place.formatted_address,
            placeId: usefulResults[0].place_id,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
        },
      );
    });
  }, [mapIsLoaded, addressRef.current]);

  useEffect(() => {
    if (!errors.address) {
      return;
    }

    setErrors({
      ...errors,
      address: false,
    });
  }, [address]);

  useEffect(() => {
    // Si las opciones se cargaron o están cargando
    if (options.status === 'loaded' || options.status === 'loading') {
      return;
    }

    // Si falló la carga de opciones
    if (options.status === 'failed') {
      setOptionsError(true);
      return;
    }

    store.dispatch(fetchOptions());
  }, []);

  return (
    <Content>
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
          mb: '2.25rem',
          mt: '2.25rem',
        }}
      >
        <Typography sx={{ fontSize: '1.625rem', fontWeight: 600 }}>
          {t('Información del Lote')}
        </Typography>
        {optionsError ? (
          <Alert severity="error" sx={{ fontSize: '1rem', marginTop: '2rem' }}>
            <Trans i18nKey="error.options">
              Se produjo un error al cargar la información necesaria para
              publicar. Por favor, refresque el sitio e intente nuevamente.
            </Trans>
          </Alert>
        ) : null}
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl fullWidth required>
            <TextField
              placeholder={t('Título de la publicación')}
              fullWidth
              margin="dense"
              name="title"
              value={data.title}
              error={errors.title}
              onChange={handleInputChange}
              inputProps={{ maxLength: 100 }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth required>
            <NumericField
              precision={0}
              margin="dense"
              placeholder={t('Valor')}
              name="price"
              value={data.price ? data.price : 0}
              onChange={handleInputChange}
              error={errors.price}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth required>
            {currencies.length > 0 ? (
              <Select
                displayEmpty
                value={data.currency ? data.currency.id : 1}
                sx={{ margin: '.5rem 0' }}
                name="currency"
                onChange={handleCurrencyChange}
              >
                {currencies.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl required>
            <NumericField
              onMouseEnter={() => setShowInfo(true)}
              onMouseLeave={() => setShowInfo(!showInfo)}
              margin="dense"
              placeholder={t('Tamaño')}
              precision={0}
              fullWidth
              name="area"
              value={data.area ? data.area : 0}
              onChange={handleInputChange}
              error={errors.area}
            />
            {showInfo ? (
              <FormHelperText>
                {t('Superficie en metros cuadrados (m²) o hectáreas (Ha.)')}
              </FormHelperText>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl required>
            {units.length > 0 ? (
              <Select
                displayEmpty
                value={data.unit ? data.unit.id : 1}
                sx={{ margin: '.5rem 0' }}
                fullWidth
                name="unit"
                onChange={handleUnitChange}
              >
                {units.map((u) => (
                  <MenuItem key={u.id} value={u.id}>
                    {u.name}
                  </MenuItem>
                ))}
              </Select>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth required>
            <TextField
              placeholder={t('Buscar Dirección')}
              margin="dense"
              InputProps={{
                startAdornment: (
                  <Icon
                    icon="et:map-pin"
                    fontSize="1.125rem"
                    style={{ marginLeft: '.25rem' }}
                  />
                ),
              }}
              inputRef={addressRef}
              error={errors.address}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth required>
            <TextField
              placeholder={
                t('Descripción') + ' (' + t('1000 caracteres max') + ')'
              }
              margin="dense"
              fullWidth
              multiline
              sx={{
                '.MuiInputBase-multiline': {
                  height: '8rem',
                  padding: 0,
                },
              }}
              name="description"
              value={data.description ? data.description : ''}
              error={errors.description}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl error={errors.type} required>
            <FormLabel sx={{ fontSize: '1.25rem' }}>
              {t('Tipo de terrenos')}
            </FormLabel>
            <FormGroup row>
              <RadioGroup
                name="type"
                onChange={handleInputChange}
                row
                value={data.type}
              >
                {landTypes.map((type) => (
                  <FormControlLabel
                    key={`type_${type.id}`}
                    label={type.name}
                    value={type.id}
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl error={errors.suitabilities} required>
            <FormLabel sx={{ fontSize: '1.25rem' }}>
              {t('Aptitud del Terreno')}
            </FormLabel>
            {errors.suitabilities ? (
              <Typography color="red">
                {t('Tiene que seleccionar al menos una aptitud')}
              </Typography>
            ) : null}
            <FormGroup row>
              {suitabilities.map((suitability) => (
                <FormControlLabel
                  key={`suitability_${suitability.id}`}
                  label={suitability.name}
                  value={suitability.id}
                  control={<Checkbox onChange={handleSuitabilitiesChange} />}
                  checked={suitabilitiesValues.includes(suitability.id)}
                />
              ))}
              <FormControlLabel
                key={`suitability_7`}
                label={t('Otros')}
                value={7}
                control={<Checkbox onChange={handleSuitabilitiesChange} />}
                checked={suitabilitiesValues.includes(7)}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        {suitabilitiesValues.includes(7) ? (
          <Grid item xs={12}>
            <TextField
              placeholder={t('Otras aptitudes')}
              margin="dense"
              fullWidth
              name="customSuitabilities"
              value={data.customSuitabilities ? data.customSuitabilities : ''}
              onChange={handleInputChange}
              error={errors.customSuitabilities}
            />
          </Grid>
        ) : null}
      </Grid>
      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          size="large"
          sx={{ width: '200px', mt: '2.5rem' }}
          onClick={handleNextClick}
          disabled={optionsError}
        >
          {t('Siguiente')}
        </Button>
      </Box>
    </Content>
  );
}
