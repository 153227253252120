import { accordionClasses } from '@mui/material';

export default function Accordion() {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: '10px',
          borderRadius: '10px',
          boxShadow: '0px 0px 20px 5px rgba(54, 73, 102, 0.05)',

          ':first-of-type': {
            borderRadius: '10px',
          },

          ':last-of-type': {
            borderRadius: '10px',
          },

          ':before': {
            background: 'none',
          },

          [`&.${ accordionClasses.expanded }`]: {
            marginTop: 0,
            marginBottom: '10px',
          },
        },
      },
    },
  };
}
