import { Alert, Box, Button, styled, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PackageSelector from '../../components/PackageSelector';
import { createPackage, Package } from '../../features/packages/packagesSlice';
import { store } from '../../store';
import { getErrorMessage } from '../../utils/errorHandler';

const Content = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '2.5rem',
}));

interface StepPackagesProps {
  packages: Package[];

  onFinish: (caseId: string, selected: Package) => void;
}

export default function StepPackages(props: StepPackagesProps) {
  const { packages, onFinish } = props;

  const { t } = useTranslation();

  const [error, setError] = useState('');
  const [selected, setSelected] = useState<Package>();

  const handleSelection = (newPackage: Package) => {
    setSelected(newPackage);
  };

  const handleNextClick = () => {
    if (!selected) {
      return;
    }

    setError('');

    store
      .dispatch(createPackage(selected))
      .unwrap()
      .then(data => {
        onFinish(data.cdCase, selected);
      })
      .catch(err => {
        console.error(getErrorMessage(err));
        setError(getErrorMessage(err));
      });
  };

  return (
    <Content>
      <Box sx={{ width: '100%', textAlign: 'center', mb: '2.25rem', mt: '2.25rem' }}>
        <Typography sx={{ fontSize: '1.625rem', fontWeight: 600 }}>
          { t('Seleccioná un paquete') }
        </Typography>
      </Box>
      <PackageSelector packages={ packages } onSelect={ handleSelection } />
      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          size="large"
          sx={{ width: '200px', mt: '2.5rem' }}
          disabled={ !selected }
          onClick={ handleNextClick }
        >
          { t('Siguiente') }
        </Button>
        {
          error
            ? <Alert severity="error" sx={{ fontSize: '1rem', marginTop: '2rem' }}>{ error }</Alert>
            : null
        }
      </Box>
    </Content>
  );
}
