import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

import Image from '../assets/logo-qk.svg';

interface LogoQkProps {
  sx?: SxProps<Theme>
  size?: number
}

export default function LogoQk(props: LogoQkProps) {
  const { sx, size = 1 } = props;

  return <Box
    component="img"
    src={ Image }
    sx={{ width: 102 * size, height: 30 * size, ...sx }}
  />;
}
