import { useParams } from 'react-router-dom';
import { Container, Grid, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { RootState, store } from '../../store';
import { fetchLotById, fetchLotIsSharable, visitLot } from '../../features/lots/lotsSlice';

import SimilarLots from './SimilarLots';
import Page from '../../components/Page';

import LotPhotos from './Photos';
import LotSummary from './Summary';
import LotContact from './Contact';
import LotMap from './Map';
import config from '../../config';
import { getUser } from '../../features/users/usersSlice';
import SocialMedia from './SocialMedia';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: `calc(${theme.sizes.navbar.height} + 1rem)`
}));

export default function Lot() {
  const { id } = useParams();

  const lot = useSelector((state: RootState) => state.lots.current);
  const shareable = useSelector((state: RootState) => state.lots.shareable);
  const user = useSelector(getUser);

  useEffect(() => {
    if (!id) {
      return;
    }

    store
      .dispatch(fetchLotById(parseInt(id)))
      .unwrap()
      .then(() => {
        store.dispatch(visitLot(parseInt(id)));
      });
  }, [id]);

  useEffect(()=>{
    if (!id) return;

    store.dispatch(fetchLotIsSharable(parseInt(id)));
  }, [id]);


  return (
    <RootStyle
      title={`${lot?.title ? lot.title : 'Lote'} | ${config.site.name}`}
    >
      <Container>
        {lot?.photos && Array.isArray(lot.photos) ? (
          <LotPhotos lot={lot} />
        ) : null}
        <Grid container spacing={2} mt="2rem">
          <Grid item xs={12} sm={8}>
            {lot ? (
              <>
                <LotSummary lot={lot} sx={{ marginBottom: '2rem' }} />
                <LotMap lot={lot} />
              </>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={4}>
            {lot ? (
              <>
                <LotContact lot={lot} user={user} />
                {user && shareable ? <SocialMedia /> : null}
              </>
            ) : null}
          </Grid>
        </Grid>
      </Container>
      {lot ? <SimilarLots current={lot} sx={{ marginTop: '2rem' }} /> : null}
    </RootStyle>
  );
}
