import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Lot } from '../../features/lots/lotsSlice';

import { useTheme } from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';

import mapStyle from '../../assets/mapStyle.json';
import { useSelector } from 'react-redux';
import { getMapIsLoaded } from '../../features/ui/UISlice';

import GoldMarker from '../../assets/markers/gold.png';
import RegularMarker from '../../assets/markers/regular.png';

interface MapProps {
  lots: Lot[];
}

export default function Map(props: MapProps) {
  const { lots } = props;

  const theme = useTheme();
  const desktop = theme.breakpoints.up('sm');

  const navigate = useNavigate();
  const isLoaded = useSelector(getMapIsLoaded);

  const onLoad = useCallback((mapInstance: google.maps.Map) => {
    const bounds = new google.maps.LatLngBounds();
    for (const lot of lots) {
      bounds.extend({ lat: lot.lat, lng: lot.lng });
    }

    google.maps.event.addListenerOnce(mapInstance, 'bounds_changed', () => {
      let newZoom = mapInstance.getZoom();

      if (!newZoom) {
        newZoom = 14;
      }

      mapInstance.setZoom(Math.min(14, newZoom));
    });

    mapInstance.setCenter(bounds.getCenter());
    mapInstance.fitBounds(bounds);
  }, []);

  const map = useMemo(() => {
    if (!isLoaded) {
      return null;
    }

    return (
      <GoogleMap
        mapContainerStyle={{
          height: desktop ? '600px' : '85vh',
          width: desktop ? '1200px' : '100%',
          maxWidth: '100%',
          borderRadius: '10px',
        }}
        onLoad={ onLoad }
        options={{
          controlSize: null,
          disableDefaultUI: true,
          zoomControl: true,
          gestureHandling: 'greedy',
          styles: mapStyle as google.maps.MapTypeStyle[],
        }}
      >
        {
          lots.map(lot => (
            <Marker
              key={ lot.id }
              icon={{
                url: lot.opportunity ? GoldMarker : RegularMarker,
                scaledSize: new window.google.maps.Size(40, 52),
              }}
              position={{ lat: lot.lat, lng: lot.lng }}
              onClick={ () => navigate(`/lots/${ lot.url }`) }
            />
          ))
        }
      </GoogleMap>
    );
  }, [isLoaded, desktop, lots]);

  return isLoaded ? map : null;
}
