import { Box, Paper, Typography, styled } from '@mui/material';
import colors from '../../theme/colors';
import { useTranslation } from 'react-i18next';

import {
  Facebook,
  Instagram,
  WhatsApp,
  Twitter,
  Mail,
} from '@mui/icons-material';

const SocialNetworkStyle = styled(Box)(() => ({
  width: '2.25rem',
  height: '2.25rem',
  lineHeight: '2.25rem',
  borderRadius: '1.125rem',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#BEA356',
  cursor: 'pointer',
}));

const SocialWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',

  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default function SocialMedia() {
  const { t } = useTranslation();

  const onClickFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
    );
  };

  const onClickTwitter = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=${window.location.href}&text=Miren este lote!`,
    );
  };

  const onClickWhatsApp = () => {
    window.open('https://wa.me/?text=' + window.location.href);
  };

  const onClickMail = () => {
    window.location.href = `mailto:?body=${window.location.href}`;
  };

  const onClickInstagram = () => {
    window.open(
      `https://www.instagram.com/create/campaign/?type=PROFILE_PROMOTE_POST&destination_url=${window.location.href}`,
    );
  };

  return (
    <Paper
      sx={{ borderRadius: '10px', paddingBottom: '1rem', marginTop: '1rem' }}
    >
      <Typography
        sx={{
          color: colors.brown,
          fontSize: '1.375rem',
          fontWeight: 600,
          paddingTop: '1.4375rem',
          paddingBottom: '1.25rem',
          textAlign: 'center',
        }}
      >
        {t('Compartir')}
      </Typography>
      <hr style={{ border: 'none', borderTop: '1px solid #EEEEEE' }} />
      <Paper
        sx={{
          borderRadius: '5px',
          margin: '2rem',
          padding: '1.125rem .875rem .875rem .875rem',
          display: 'flex',
          mb: '1.25rem',
        }}
      >
        <SocialWrapper>
          <SocialNetworkStyle>
            <Facebook htmlColor="white" onClick={() => onClickFacebook()} />
          </SocialNetworkStyle>
          <SocialNetworkStyle>
            <Twitter htmlColor="white" onClick={() => onClickTwitter()} />
          </SocialNetworkStyle>
          <SocialNetworkStyle>
            <Mail htmlColor="white" onClick={() => onClickMail()} />
          </SocialNetworkStyle>
          <SocialNetworkStyle>
            <WhatsApp htmlColor="white" onClick={() => onClickWhatsApp()} />
          </SocialNetworkStyle>
          {/* <SocialNetworkStyle>
            <Instagram htmlColor="white" onClick={() => onClickInstagram()} />
          </SocialNetworkStyle> */}
        </SocialWrapper>
      </Paper>
    </Paper>
  );
}
