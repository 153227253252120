import {
  Alert,
  Button,
  Container,
  FormControl,
  Grid,
  outlinedInputClasses,
  Paper,
  styled,
  TextField,
  Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Page from '../components/Page';
import { CreateQueryOptions } from '../features/comms/commsAPI';
import { createQuery } from '../features/comms/commsSlice';
import { getUser } from '../features/users/usersSlice';
import { store } from '../store';
import { useTranslation } from 'react-i18next';
import AboutUs from '../components/AboutUs';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: `calc(${ theme.sizes.navbar.height } + 1rem)`,
  backgroundImage: 'url(/static/images/mountains.png)',
  backgroundSize: '100% auto',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom',
  paddingBottom: '2rem',
}));

const FormWrapper = styled(Paper)(() => ({
  textAlign: 'center',
  padding: '1.875rem 3.5rem',
  borderRadius: '20px',
  boxShadow: '0px 0px 30px 5px rgba(54, 73, 102, 0.05)',
}));

const Message = styled(TextField)(() => ({
  [`.${ outlinedInputClasses.root }`]: {
    padding: 0,
    height: '8rem',
    marginBottom: '1rem',
    borderRadius: '28px',
  },
}));

type QueryErrors = Partial<Record<keyof CreateQueryOptions, boolean>>;

export default function Contact() {
  const { t } = useTranslation();

  const [data, setData] = useState<CreateQueryOptions>({
    name: '',
    lastname: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState<QueryErrors>({});

  const [success, setSuccess] = useState<boolean>();

  const user = useSelector(getUser);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });

    // Una vez que verificamos que la propiedad existe en el objeto, suprimimos el error ts(7053)
    if (event.target.name in errors && errors[event.target.name as keyof QueryErrors]) {
      setErrors({
        ...errors,
        [event.target.name]: false,
      });
    }
  };

  const handleSubmit = () => {
    const newErrors: QueryErrors = {};
    let count = 0;

    if (!data.name.trim()) {
      newErrors.name = true;
      count++;
    }

    if (!data.lastname.trim()) {
      newErrors.lastname = true;
      count++;
    }

    if (!data.email.trim()) {
      newErrors.email = true;
      count++;
    }

    if (!data.phone.trim()) {
      newErrors.phone = true;
      count++;
    }

    if (!data.message.trim()) {
      newErrors.message = true;
      count++;
    }

    if (count > 0) {
      setErrors(newErrors);
      return;
    }

    store
      .dispatch(createQuery(data))
      .unwrap()
      .then(result => {
        setSuccess(result);

        if (result) {
          setData({
            ...data,
            message: '',
          });
        }
      });
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    setData({
      ...data,
      name: user.nombre,
      lastname: user.apellido,
      email: user.correoEle,
      phone: user.telefono,
    });
  }, [user]);

  return (
    <RootStyle title={ t('Contacto') }>
      <Container sx={{ marginTop: '8rem', marginBottom: '12rem' }}>
        <Grid container>
          <Grid item sm={ 2 }></Grid>
          <Grid item sm={ 8 }>
            <FormWrapper>
              <Typography variant="h5" mb="1rem">{ t('Contactanos') }</Typography>
              <form>
                <Grid container spacing={ 2 }>
                  <Grid item sm={ 6 }>
                    <FormControl fullWidth required>
                      <TextField
                        margin="dense"
                        fullWidth
                        placeholder={ t('Nombre') }
                        name="name"
                        value={ data.name }
                        error={ errors.name }
                        onChange={ handleInputChange }
                        disabled={ !!user?.nombre }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={ 6 }>
                    <FormControl fullWidth required>
                      <TextField
                        margin="dense"
                        fullWidth
                        placeholder={ t('Apellido') }
                        name="lastname"
                        value={ data.lastname }
                        error={ errors.lastname }
                        onChange={ handleInputChange }
                        disabled={ !!user?.apellido }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={ 6 }>
                    <FormControl fullWidth required>
                      <TextField
                        margin="dense"
                        fullWidth
                        placeholder={ t('Correo electrónico') }
                        type="email"
                        name="email"
                        value={ data.email }
                        error={ errors.email }
                        onChange={ handleInputChange }
                        disabled={ !!user?.correoEle }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={ 6 }>
                    <FormControl fullWidth required>
                      <TextField
                        margin="dense"
                        fullWidth
                        placeholder={ t('Teléfono') }
                        type="tel"
                        name="phone"
                        value={ data.phone }
                        error={ errors.phone }
                        onChange={ handleInputChange }
                        disabled={ !!user?.telefono }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={ 12 }>
                    <FormControl fullWidth required>
                      <Message
                        multiline
                        placeholder={ t('Mensaje') }
                        fullWidth
                        name="message"
                        value={ data.message }
                        error={ errors.message }
                        onChange={ handleInputChange }
                        inputProps={{
                          maxLength: 2000,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={ 12 }>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={ handleSubmit }
                      disabled={ !data.message }
                    >
                      { t('Enviar') }
                    </Button>
                  </Grid>
                  <Grid item sm={ 12 }>
                    { success === true &&
                      <Alert severity="success" sx={{ fontSize: '1rem' }}>
                        { t('Mensaje enviado') }
                      </Alert>
                    }
                    { success === false &&
                      <Alert severity="error" sx={{ fontSize: '1rem' }}>
                        { t('Se produjo un error al intentar enviar el mensaje') }.
                        { t('Por favor, intente nuevamente') }
                      </Alert>
                    }
                  </Grid>
                </Grid>
              </form>
            </FormWrapper>
          </Grid>
          <Grid item sm={ 2 }></Grid>
        </Grid>
      </Container>
      <AboutUs />
    </RootStyle>
  );
}
