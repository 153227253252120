import { ReactNode } from 'react';
import { Formatter, Unit, Suffix } from 'react-timeago';

const formatter: Formatter = (value: number, unit: Unit, suffix: Suffix): ReactNode => {
  let output = '';

  if (suffix == 'ago') {
    output += 'hace';
  } else {
    output += 'dentro de';
  }

  output += ` ${ value } `;

  switch (unit) {
    case 'second': output += value == 1 ? 'segundo' : 'segundos'; break;
    case 'minute': output += value == 1 ? 'minuto' : 'minutos'; break;
    case 'hour': output += value == 1 ? 'hora' : 'horas'; break;
    case 'day': output += value == 1 ? 'día' : 'días'; break;
    case 'week': output += value == 1 ? 'semana' : 'semanas'; break;
    case 'month': output += value == 1 ? 'mes' : 'meses'; break;
    case 'year': output += value == 1 ? 'año' : 'años'; break;
  }

  return output;
};

export {
  formatter,
};
