import { Icon } from '@iconify/react';
import { Box, Card, CardActionArea, styled, SxProps, Theme, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Lot } from '../features/lots/lotsSlice';

const CardStyled = styled(Card)(({ theme }) => ({
  borderRadius: '1.125rem',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  boxShadow: 'none',

  [theme.breakpoints.up('sm')]: {
    boxShadow: theme.shadows[4],
  },
}));

const ContentWrap = styled(Box)(() => ({
  width: '100%',
  height: '546px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  // eslint-disable-next-line max-len
  background: 'linear-gradient(180deg, rgba(0, 0, 0, .5) 0%, rgba(1, 1, 1, 0) 25%, rgba(1, 1, 1, 0) 60%, rgba(0, 0, 0, .7) 75%, rgba(0, 0, 0, .5) 100%)',
  padding: '1.5rem',
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  lineHeight: '2.375rem',
  fontWeight: 600,
  color: '#fff',
  textShadow: theme.shadows[6],
  marginBottom: '1.125rem',

  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
}));

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  borderBottom: 'solid 1px white',
  paddingBottom: '1.125rem',
}));

interface LotCardProps {
  lot: Lot;
  sx?: SxProps<Theme>;
}

export default function LotCard(props: LotCardProps) {
  const { lot, sx } = props;
  const { address, area, price, thumbnail, title, url } = lot;

  const { i18n } = useTranslation();

  const [parsedPrice, setParsedPrice] = useState('');

  useEffect(() => {
    const parsed = new Intl.NumberFormat(i18n.language, {
      minimumFractionDigits: 0,
      useGrouping: true,
    }).format(price);

    setParsedPrice(parsed);
  }, [price, i18n.language]);

  return (
    <CardStyled sx={{ backgroundImage: `url(${ thumbnail })`, ...sx }}>
      <CardActionArea href={`/lots/${ url }`}>
        <ContentWrap>
          <CardHeader>
            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
              <Typography
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 400,
                  letterSpacing: '0.05rem',
                  color: '#fff',
                  mr: '.5rem',
                }}
              >
                { lot.currency.name }
              </Typography>
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 700,
                  letterSpacing: '0.05rem',
                  color: '#fff',
                }}
              >
                { parsedPrice }
              </Typography>
            </Box>
          </CardHeader>
          <Box>
            <CardTitle>{ title }</CardTitle>
            <Typography
              sx={{
                color: '#fff',
                mb: '0.875rem',
              }}
            >
              <Icon
                icon="ep:location"
                style={{ width: '1rem', marginRight: '.25rem', verticalAlign: 'middle' }}
              />
              { address }
            </Typography>
            <Typography
              sx={{
                color: '#fff',
              }}
            >
              <Icon
                icon="bi:arrows-angle-expand"
                style={{ width: '.875rem', marginRight: '.475rem', verticalAlign: 'middle' }}
              />
              { area } { lot.unit.name }
            </Typography>
          </Box>
        </ContentWrap>
      </CardActionArea>
    </CardStyled>
  );
}
