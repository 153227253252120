import { ElementType, useEffect, useMemo, useState } from 'react';
import { default as DropzoneWrapper } from 'react-dropzone';

import { useSelector } from 'react-redux';
import { store } from '../../store';
import { getS3Status, uploadFile } from '../../features/s3/s3Slice';

import { Alert, Box, Button, Grid, styled, SvgIcon, SvgIconProps } from '@mui/material';
import Image from './Image';

import { ReactComponent as UploadIcon } from '../../assets/upload.svg';
import { ReactComponent as SpinnerIcon } from '../../assets/spinner.svg';
import config from '../../config';
import { useTranslation } from 'react-i18next';

const Dropzone = styled(Box)(() => ({
  height: '100%',
  maxHeight: '50vh',
  width: '100%',
  border: '1px dashed #E0E0E0',
  borderRadius: '20px',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
  textAlign: 'center',

  color: '#C4C4C4',
}));

const UploaderIcon = styled(SvgIcon)<SvgIconProps<ElementType>>(() => ({
  height: '69px',
  width: '69px',
  marginBottom: '.875rem',
}));

const GridItemStyled = styled(Grid)(({ sm }) => ({
  aspectRatio: sm === 12 ? '2 / 1' : '1 / 1',
}));

interface UploaderProps {
  onDelete?: (file: string) => void;
  onUpload?: (file: string) => void;
  onFinish?: (files: string[]) => void;
  defaultValue?: string[];
}

export default function Uploader(props: UploaderProps) {
  const { defaultValue = [], onUpload, onDelete, onFinish } = props;

  const { t } = useTranslation();

  const [selectedFiles, setSelectedFiles] = useState<string[]>(defaultValue);

  const S3Status = useSelector(getS3Status);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleAcceptedFiles = async (files: File[]) => {
    if (files.length != 1) {
      return;
    }

    setLoading(true);

    const path = await store.dispatch(uploadFile(files[0]))
      .unwrap()
      .catch(err => {
        console.error(err);
        setError('Se produjo un error mientras se subía la imagen. Por favor, intente nuevamente');
      });

    if (!path) {
      return;
    }

    setSelectedFiles([...selectedFiles, config.s3.url + path]);

    if (onUpload) {
      onUpload(config.s3.url + path);
    }
  };

  const handleDeteleFiles = async (url: string) => {
    setSelectedFiles(selectedFiles.filter(f => f != url));

    if (onDelete) {
      onDelete(url);
    }
  };

  const handleFinish = () => {
    if (!onFinish) {
      return;
    }

    onFinish(selectedFiles);
  };

  const gridCols = useMemo(() => {
    if (selectedFiles.length == 0) {
      return 12;
    }

    if (selectedFiles.length == 1) {
      return 6;
    }

    return 4;
  }, [selectedFiles.length]);

  useEffect(() => {
    setLoading(S3Status == 'loading');
  }, [S3Status]);

  return (
    <Grid container spacing={ 2 }>
      {
        selectedFiles.map(f => (
          <Grid key={`file_${ f }`} item sm={ gridCols }>
            <Image src={ f } onDelete={ () => handleDeteleFiles(f) } />
          </Grid>
        ))
      }
      <GridItemStyled item sm={ gridCols }>
        <DropzoneWrapper
          onFileDialogOpen={ () => setError('') }
          onDrop={ handleAcceptedFiles }
          disabled={ loading }
          multiple={ false }
          accept={{
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <Dropzone {...getRootProps()}>
              <input {...getInputProps()} />
              {
                loading
                  ? <>
                      <UploaderIcon component={ SpinnerIcon } inheritViewBox />
                      <p>{ t('Subiendo imagen...') }</p>
                    </>
                  : <>
                      <UploaderIcon component={ UploadIcon } inheritViewBox />
                      <p>{ t('Arrastrá o seleccioná las imágenes') }</p>
                    </>
              }
            </Dropzone>
          )}
        </DropzoneWrapper>
      </GridItemStyled>
      {
        error
          ? <Grid item xs={ 12 }>
              <Alert severity="error" sx={{ fontSize: '1rem' }}>{ error }</Alert>
            </Grid>
          : null
      }
      {
        onFinish
          ? <Grid item xs={ 12 } sx={{ textAlign: 'center' }}>
              <Button variant="contained" onClick={ handleFinish }>
                { t('Finalizar Edición') }
              </Button>
            </Grid>
          : null
      }
    </Grid>
  );
}
