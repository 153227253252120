import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

import subscriptionsAPI, {
  CancelSubscriptionOptions,
  CreateSubscriptionOptions,
  MyPaymentsData,
  MySubscriptionData } from './subscriptionsAPI';

export interface Subscription {
  id: number;

  name: string;
  description: string[];

  term: number;
  zones: number;

  currency: string;
  price: number;
}

export interface SubscriptionZone {
  id: string;
  label: string;
  location: google.maps.LatLngLiteral;
}

export interface MySubscription extends MySubscriptionData {
  payments?: MyPaymentsData[];
}

export interface SubscriptionsState {
  list?: Subscription[];
  owned?: MySubscription[];
  status: 'booting' | 'idle' | 'loading' | 'failed';
  error?: { message: string; name: string; } | false;
};

export const initialState: SubscriptionsState = {
  status: 'booting',
};

export const fetchSubscriptions = createAsyncThunk(
  'subscription/fetch',
  async () => {
    const response = await subscriptionsAPI.fetchSubscriptions();
    return response;
  },
);

export const fetchMySubscriptions = createAsyncThunk(
  'subscription/fetch-mine',
  async (payments: boolean, thunkAPI) => {
    const response = await subscriptionsAPI.fetchMySubscriptions();

    if (payments) {
      thunkAPI.dispatch(fetchMyPayments(response.map(s => s.id)));
    }

    return response;
  },
);

export const fetchMyPayments = createAsyncThunk(
  'subscription/fetch-mine/payments',
  async (subscriptionsIds?: number[]) => {
    const response = await subscriptionsAPI.fetchMyPayments(subscriptionsIds);
    return response;
  },
);

export const createSubscription = createAsyncThunk(
  'subscription/create',
  async (options: CreateSubscriptionOptions) => {
    const response = await subscriptionsAPI.createSubscription(options);
    return response.data;
  },
);

export const cancelSubscription = createAsyncThunk(
  'subscription/cancel',
  async (options: CancelSubscriptionOptions) => {
    const response = await subscriptionsAPI.cancelSubscription(options);
    return response.data;
  },
);

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSubscriptions.pending, state => {
        state.status = 'loading';
        state.list = undefined;
        state.error = false;
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(fetchSubscriptions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = {
          name: action.error.name ? action.error.name : '',
          message: action.error.message ? action.error.message : '',
        };
      });

    builder
      .addCase(fetchMySubscriptions.pending, state => {
        state.status = 'loading';
        state.owned = undefined;
        state.error = false;
      })
      .addCase(fetchMySubscriptions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.owned = action.payload;
      })
      .addCase(fetchMySubscriptions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = {
          name: action.error.name ? action.error.name : '',
          message: action.error.message ? action.error.message : '',
        };
      });

    builder
      .addCase(fetchMyPayments.pending, state => {
        state.status = 'loading';
        state.error = false;
      })
      .addCase(fetchMyPayments.fulfilled, (state: SubscriptionsState, action) => {
        state.status = 'idle';

        // Shallow copy objetos arreglo de subscripciones
        const newData = state.owned ? state.owned.map(({ ...e }) => e) : [];

        // Procesamos cada pago
        action.payload.forEach(payment => {
          // Buscamos la suscripción
          const idx = newData.findIndex(subscription => subscription.id == +payment.productAcquisition);

          // Si no la encontramos, pasamos al siguiente pago
          if (idx < 0) {
            return;
          }

          // Recuperamos los pagos de la suscripción (necesario para verificación TS)
          let newPayments = newData[idx].payments;

          // Si no había cargado ninguno inicializamos el arreglo
          if (!newPayments || !Array.isArray(newPayments)) {
            newPayments = [];
          }

          // Agregamos el pago
          newPayments.push(payment);

          // Actualizamos los pagos en la suscripción
          newData[idx].payments = newPayments;
        });

        // Actualizamos la información de las suscripciones
        state.owned = newData;
      })
      .addCase(fetchMyPayments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = {
          name: action.error.name ? action.error.name : '',
          message: action.error.message ? action.error.message : '',
        };
      });
  },
});

export const selectSubscriptions = (state: RootState) => state.subscription;
export const getSubscriptions = (state: RootState) => state.subscription.list ? state.subscription.list : [];
export const getMySubscriptions = (state: RootState) => state.subscription.owned ? state.subscription.owned : [];
export const getSubscriptionById = (id: number) => (state: RootState) => state.subscription.list?.find(s => s.id == id);

export default subscriptionSlice.reducer;
