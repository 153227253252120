import { Suspense, useState } from 'react';

import { Lot } from '../../features/lots/lotsSlice';

import { Badge, Box, Button, Dialog, DialogContent, DialogTitle, styled } from '@mui/material';
import Skeleton from 'react-loading-skeleton';

import SuspenseImage from '../../components/SuspenseImage';

import imageIcon from '../../assets/images.png';

import 'react-loading-skeleton/dist/skeleton.css';
import Gallery from '../../components/Gallery';
import Uploader from '../../components/Uploader/Uploader';
import { useTranslation } from 'react-i18next';

interface EditorWrapper {
  edit?: boolean;
}

const editorOptions = {
  shouldForwardProp: (prop: PropertyKey) => prop !== 'edit',
};

const PhotosWrapper = styled(Box, editorOptions)<EditorWrapper>(({ theme, edit = false }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: '10px',
  gridAutoRows: '276px',
  overflow: 'hidden',
  minHeight: '576px',

  [theme.breakpoints.up('sm')]: {
    borderRadius: '20px',
    gridGap: '20px',
    gridTemplateColumns: edit ? 'repeat(6, 1fr)' : 'repeat(3, 1fr)',
  },
}));

const MainPhotoWrapper = styled(Box, editorOptions)<EditorWrapper>(({ theme, edit = false }) => ({
  gridColumn: '1 / span 2',
  position: 'relative',

  [theme.breakpoints.up('sm')]: {
    gridColumn: edit ? '1 / span 4' : '1 / span 2',
    gridRow: '1 / span 2',
  },
}));

const SideTopPhotoWrapper = styled(Box, editorOptions)<EditorWrapper>(({ theme, edit = false }) => ({
  gridColumn: '1',
  gridRow: '2',

  [theme.breakpoints.up('sm')]: {
    gridColumn: edit ? '5 / span 2' : '3',
    gridRow: '1',
  },
}));

const SideBottomPhotoWrapper = styled(Box, editorOptions)<EditorWrapper>(({ theme, edit = false }) => ({
  gridColumn: '2',
  gridRow: '2',

  [theme.breakpoints.up('sm')]: {
    gridColumn: edit ? '5' : '3',
    gridRow: '2',
  },
}));

const PhotoEditorWrapper = styled(Box)(({ theme }) => ({
  gridColumn: '2',
  gridRow: '2',

  border: '1px #e0e0e0 dashed',
  borderBottomRightRadius: '20px',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  button: {
    width: '100%',
    height: '100%',
    borderRadius: 0,
  },

  [theme.breakpoints.up('sm')]: {
    gridColumn: '6',
    gridRow: '2',
  },
}));

const GalleryWrapper = styled(Button)(() => ({
  position: 'absolute',
  bottom: '1rem',
  left: '2rem',
  zIndex: 3,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, .4)',
  borderRadius: '6px',
  minWidth: 'auto',
  padding: '.5rem',
}));

interface LotPhotosProps {
  lot: Lot;

  handleEdit?: (data: Partial<Lot>) => void;
}

export default function LotPhotos(props: LotPhotosProps) {
  const { lot, handleEdit } = props;

  const { t } = useTranslation();

  const showEditors = !!handleEdit;

  const [galleryOpen, setGalleryOpen] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);

  const handleEditorFinish = (files: string[]) => {
    if (handleEdit) {
      handleEdit({
        thumbnail: files[0],
        photos: files,
      });
    }

    setEditorOpen(false);
  };

  return (
    <PhotosWrapper edit={ showEditors }>
      <MainPhotoWrapper edit={ showEditors }>
        <Suspense fallback={ <Skeleton height="100%" /> }>
          <SuspenseImage
            src={ lot.photos[0] }
            style={{ objectFit: 'cover', height: '100%', width: '100%' }}
          />
        </Suspense>
        <GalleryWrapper onClick={ () => setGalleryOpen(true) }>
          <Badge
            badgeContent={ lot.photos.length }
            color="secondary"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <img src={ imageIcon } />
          </Badge>
        </GalleryWrapper>
        <Gallery images={ lot.photos } open={ galleryOpen } onClose={ () => setGalleryOpen(false) } />
      </MainPhotoWrapper>
      <SideTopPhotoWrapper edit={ showEditors }>
        {
          !!lot.photos[1]
            ? <Suspense fallback={ <Skeleton height="100%" /> }>
                <SuspenseImage
                  src={ lot.photos[1] }
                  style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                />
              </Suspense>
            : <Skeleton height="100%" />
        }
      </SideTopPhotoWrapper>
      <SideBottomPhotoWrapper edit={ showEditors }>
        {
          !!lot.photos[2]
            ? <Suspense fallback={ <Skeleton height="100%" /> }>
                <SuspenseImage
                  src={ lot.photos[2] }
                  style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                />
              </Suspense>
            : <Skeleton height="100%" />
        }
      </SideBottomPhotoWrapper>
      {
        showEditors
          ? <>
              <PhotoEditorWrapper>
                <Button onClick={ () => setEditorOpen(true) }>{ t('Editar fotos') }</Button>
              </PhotoEditorWrapper>
              <Dialog open={ editorOpen } onClose={ () => setEditorOpen(false) } maxWidth="lg" fullWidth>
                <DialogTitle>{ t('Editar las fotos del Lote') }</DialogTitle>
                <DialogContent>
                  <Uploader onFinish={ handleEditorFinish } defaultValue={ lot.photos } />
                </DialogContent>
              </Dialog>
            </>
          : null
      }
    </PhotosWrapper>
  );
}
