import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

import packagesAPI from './packagesAPI';

export interface Package {
  id: number;
  name: string;
  description: string;
  term: number;
  posts: number;
  currency: string;
  price: number;
}

export interface PackageState {
  list?: Package[];
  status: 'booting' | 'idle' | 'loading' | 'failed';
  error?: { message: string; name: string; } | false;
};

export const initialState: PackageState = {
  status: 'booting',
};

export const fetchPackages = createAsyncThunk(
  'packages/fetch',
  async () => {
    let response = await packagesAPI.fetchPackages();

    if (response.length > 4) {
      response = response.slice(0, 4);
    }

    return response;
  },
);

export const createPackage = createAsyncThunk(
  'packages/create',
  async (packageData: Package) => {
    const response = await packagesAPI.createPackage(packageData);
    return response.data;
  },
);

export const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPackages.pending.type]: state => {
      state.status = 'loading';
      state.list = undefined;
      state.error = false;
    },
    [fetchPackages.fulfilled.type]: (state, action) => {
      state.status = 'idle';
      state.list = action.payload;
    },
    [fetchPackages.rejected.type]: (state, action) => {
      state.status = 'failed';
      state.error = {
        name: action.error.name,
        message: action.error.message,
      };
    },
  },
});

export const selectPackages = (state: RootState) => state.packages;
export const getPackages = (state: RootState) => state.packages.list ? state.packages.list : [];

export default packagesSlice.reducer;
