import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { v4 as uuidv4 } from 'uuid';
import mime from 'mime';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

import config from '../../config';

const s3Client = new S3Client({
  region: 'us-east-1',
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: 'us-east-1' }),
    identityPoolId: 'us-east-1:a78724b4-6d55-47b3-b5c1-1cec7b8b17b6',
  }),
});

interface S3State {
  status: 'loading' | 'idle' | 'error';
  response?: string;
}

export const initialState: S3State = {
  status: 'idle',
};

export const uploadFile = createAsyncThunk(
  's3/upload',
  async (file: File) => {
    try {
      const fileName = uuidv4().replaceAll('-', '') + '.' + (mime.getExtension(file.type) ?? 'bin');

      const command = new PutObjectCommand({
        Bucket: 'websites-contents',
        Key: config.s3.path + fileName,
        Body: file,
        ContentLength: file.size,
        ACL: 'public-read',
      });

      await s3Client.send(command);

      return fileName;
    } catch (err) {
      console.error(err);
    }
  },
);


export const S3State = createSlice({
  name: 'S3State',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(uploadFile.pending, state => {
        state.status = 'loading';
        state.response = undefined;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.status = 'idle';
        state.response = action.payload;
      })
      .addCase(uploadFile.rejected, state => {
        state.status = 'error';
      });
  },
});

export const getS3Status = (state: RootState) => state.s3.status;

export default S3State.reducer;
