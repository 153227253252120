import { Box, styled, SxProps, Theme } from '@mui/material';
import { ReactNode, useMemo } from 'react';

const Wrapper = styled(Box)(({ theme }) => ({
  height: 0,
  width: '100%',
  paddingBottom: '322px',
  backgroundSize: 'cover',
  borderRadius: '20px',

  [theme.breakpoints.up('sm')]: {
    paddingBottom: '400px',
  },
}));

const Content = styled(Box)(() => ({
  padding: '1.25rem 1.75rem',
}));

interface ImageCardProps {
  url: string;
  children: ReactNode;
  sx?: SxProps<Theme>;
}

export default function ImageCard(props: ImageCardProps) {
  const { children, url, sx } = props;

  const backgroundImage = useMemo(() => {
    let value = 'linear-gradient(180deg, rgba(0, 0, 0, 0) -6.97%, rgba(1, 1, 1, 0.35) 93.03%), ';
    value += `url(${ url })`;

    return value;
  }, [url]);

  return (
    <Wrapper sx={{ backgroundImage, ...sx }}>
      <Content>{ children }</Content>
    </Wrapper>
  );
}
