import { Grid, styled, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ImageCard from '../../components/ImageCard';
import { getMostConsultedAreas } from '../../features/options/optionsSlice';

const GridStyled = styled(Grid)(({ theme }) => ({
  width: '100%',
  paddingLeft: '1rem',
  paddingRight: 0,

  [theme.breakpoints.up('sm')]: {
    width: theme.sizes.content.width,
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

interface FeaturedZonesProps {
  sx?: SxProps<Theme>;
}

export default function FeaturedZones(props: FeaturedZonesProps) {
  const { sx } = props;

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();

  const areas = useSelector(getMostConsultedAreas);

  if (areas.length === 0) {
    return null;
  }

  return (
    <GridStyled container spacing={ desktop ? 4 : 2 } sx={ sx }>
      <Grid item xs={ 12 } sm={ 4 } sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography variant="overline">{ t('Zonas destacadas') }</Typography>
        <Typography variant="h3">{ t('Lo más buscado en Plotland') }</Typography>
        <Typography sx={{ fontSize: '1.25rem' }}>
          { t(`Ya sea en la zona más austral del planeta, en el norte o en la zonas más
          exclusivas, Plotland tiene una oportunidad de negocio para vos.`) }
        </Typography>
      </Grid>
      <Grid item xs={ 12 } sm={ 4 }>
        <ImageCard url={ areas[0].image }>
          <Typography sx={{ color: '#fff', fontSize: '1.825rem', fontWeight: 600 }}>
            { areas[0].name }
          </Typography>
        </ImageCard>
      </Grid>
      {
        areas[1] !== undefined
          ? <Grid item xs={ 12 } sm={ 4 }>
              <ImageCard url={ areas[1].image }>
                <Typography sx={{ color: '#fff', fontSize: '1.825rem', fontWeight: 600 }}>
                  { areas[1].name }
                </Typography>
              </ImageCard>
            </Grid>
          : null
      }
      {
        areas[2] !== undefined
          ? <Grid item xs={ 12 } sm={ 4 }>
              <ImageCard url={ areas[2].image } sx={{ paddingBottom: '170px' }}>
                <Typography sx={{ color: '#fff', fontSize: '1.825rem', fontWeight: 600 }}>
                  { areas[2].name }
                </Typography>
              </ImageCard>
            </Grid>
          : null
      }
      {
        areas[3] !== undefined
          ? <Grid item xs={ 12 } sm={ 8 }>
              <ImageCard url={ areas[3].image } sx={{ paddingBottom: '170px' }}>
                <Typography sx={{ color: '#fff', fontSize: '1.825rem', fontWeight: 600 }}>
                  { areas[3].name }
                </Typography>
              </ImageCard>
            </Grid>
          : null
      }
    </GridStyled>
  );
}
