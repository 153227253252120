import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { getErrorMessage } from '../../utils/errorHandler';
import commsAPI, { Conversation, CreateQueryOptions, Message, SendMessageOptions } from './commsAPI';

export interface CommsState {
  status: 'booting' | 'idle' | 'loading' | 'failed';
  error?: { message: string; name: string; } | false;
  conversations: Conversation[];
  messages: Message[];
};

export const initialState: CommsState = {
  status: 'booting',
  conversations: [],
  messages: [],
};

export const fetchConversations = createAsyncThunk(
  'comms/fetch-conversations',
  async (_, thunkAPI) => {
    try {
      const result = await commsAPI.getConversations();

      return result;
    } catch (err) {
      console.error(err);
      return thunkAPI.rejectWithValue(getErrorMessage(err));
    }
  },
);

export const fetchMessages = createAsyncThunk(
  'comms/fetch-messages',
  async (conversationId: number, thunkAPI) => {
    try {
      const result = await commsAPI.getMessages(conversationId);

      return result;
    } catch (err) {
      console.error(err);
      return thunkAPI.rejectWithValue(getErrorMessage(err));
    }
  },
);

export const sendMessage = createAsyncThunk(
  'comms/send-messages',
  async (options: SendMessageOptions, thunkAPI) => {
    try {
      const result = await commsAPI.sendMessage(options);

      return result;
    } catch (err) {
      console.error(err);
      return thunkAPI.rejectWithValue(getErrorMessage(err));
    }
  },
);

export const createQuery = createAsyncThunk<boolean, CreateQueryOptions, { rejectValue: string }>(
  'comms/create-query',
  async (options: CreateQueryOptions, thunkAPI) => {
    try {
      const result = await commsAPI.createQuery(options);

      return result;
    } catch (err) {
      console.error(err);
      return thunkAPI.rejectWithValue(getErrorMessage(err));
    }
  },
);

export const commsSlice = createSlice({
  name: 'comms',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createQuery.pending, state => {
        state.status = 'loading';
      })
      .addCase(createQuery.fulfilled, state => {
        state.status = 'idle';
      })
      .addCase(createQuery.rejected, (state, action) => {
        state.status = 'failed';
        state.error = {
          name: action.error.name ? action.error.name : '',
          message: action.error.message ? action.error.message : '',
        };
      });

    builder
      .addCase(fetchConversations.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchConversations.fulfilled, (state, action) => {
        state.status = 'idle';
        state.conversations = action.payload;
      })
      .addCase(fetchConversations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = {
          name: action.error.name ? action.error.name : '',
          message: action.error.message ? action.error.message : '',
        };
      });

    builder
      .addCase(fetchMessages.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.status = 'idle';
        state.messages = action.payload;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = {
          name: action.error.name ? action.error.name : '',
          message: action.error.message ? action.error.message : '',
        };
      });
  },
});

export const selectComms = (state: RootState) => state.comms;
export const getConversations = (state: RootState) => state.comms.conversations;
export const getMessages = (state: RootState) => state.comms.messages;

export default commsSlice.reducer;
