import { useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Package } from '../features/packages/packagesSlice';
import { useTranslation } from 'react-i18next';

const PackageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

interface PackageItemProps {
  selected?: boolean;
  disabled?: boolean;
  initial?: boolean;
}

const PackageItem = styled(Box, {
  shouldForwardProp: prop => prop !== 'selected' && prop !== 'initial',
})<PackageItemProps>(({ theme, selected = false, initial = false, disabled = false }) => ({

  border: selected
    ? `solid 2px ${ disabled ? theme.palette.grey[100] : theme.palette.primary.main }`
    : `1px solid ${ initial ? theme.palette.primary.light : theme.palette.text.disabled }`,
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1.5rem 1.25rem',
  color: disabled ? theme.palette.grey[100] : undefined,
  cursor: disabled ? 'default' : 'pointer',

  marginRight: 0,
  marginBottom: '1.125rem',

  [theme.breakpoints.up('md')]: {
    ':not(:last-child)': {
      marginRight: '1.125rem',
    },

    marginBottom: 0,
  },
}));

const PackageName = styled(Typography)(() => ({
  fontSize: '2rem',
  fontWeight: 600,
}));

const PackageCurrency = styled(Typography)(() => ({
  display: 'inline-block',
  fontWeight: 'lighter',
  fontSize: '1.125rem',
  letterSpacing: '0.05rem',
}));

const PackagePrice = styled(Typography)(() => ({
  display: 'inline-block',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '1.25rem',
  fontWeight: 600,
  letterSpacing: '0.05rem',
}));

const PackagePeriod = styled(Typography)(() => ({
  display: 'inline-block',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '1.125rem',
  fontWeight: 600,
  letterSpacing: '0.05rem',
}));

const PackageDescription = styled(Typography)(() => ({
  textAlign: 'center',
  marginTop: '2rem',
  fontSize: '0.875rem',
}));

interface PackageSelectorProps {
  packages: Package[];
  defaultValue?: Package;
  disableLower?: boolean;

  onSelect: (selected: Package) => void;
}

export default function PackageSelector(props: PackageSelectorProps) {
  const { packages, defaultValue, disableLower = true, onSelect } = props;

  const { t } = useTranslation();

  const [selected, setSelected] = useState<Package | undefined>(defaultValue);

  const handleSelection = (newPackage: Package) => {
    if (disableLower && !!defaultValue && newPackage.id < defaultValue.id) {
      return;
    }

    setSelected(newPackage);

    if (onSelect) {
      onSelect(newPackage);
    }
  };

  return (
    <PackageWrapper>
      {
        packages.map(p => (
          <PackageItem key={ p.id }
            sx={{ width: {
              lg: `calc(${ 100 / packages.length }% - ${ 1.125 * (packages.length - 1) / packages.length }rem )`,
              md: '100%',
            } }}
            onClick={ () => handleSelection(p) }
            selected={ p.id === selected?.id }
            initial={ p.id === defaultValue?.id }
            disabled={ disableLower && !!defaultValue && p.id < defaultValue.id }
          >
            <PackageName>{ p.name }</PackageName>
            <Box>
              <PackageCurrency>{ p.currency }</PackageCurrency>
              <PackagePrice>&nbsp;{ p.price }&nbsp;</PackagePrice>
              <PackagePeriod>/ { t('mes') }</PackagePeriod>
            </Box>
            <PackageDescription>
              { p.description }
              <br /><br />
              { p.posts } { t('lote', { count: p.posts }) }
            </PackageDescription>
          </PackageItem>
        ))
      }
    </PackageWrapper>
  );
}
