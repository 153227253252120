import { Button, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchMyOpportunities, getMyOpportunities } from '../../features/lots/lotsSlice';
import { store } from '../../store';
import LotItem from '../../components/LotItem';
import { openSidebar } from '../../features/ui/UISlice';
import { Icon } from '@iconify/react';
import Sidebar from '../../components/Sidebar/FiltersOpportunities';
import { useSearchParams } from 'react-router-dom';
import { FetchLotsOptions } from '../../features/lots/lotsSlice';
import { useTranslation } from 'react-i18next';

export default function MyLots() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const lots = useSelector(getMyOpportunities);

  useEffect(() => {
    // Si no hay parámetros, despachamos inmediatamente la petición
    if (!Array.from(searchParams).length) {
      store.dispatch(fetchMyOpportunities());
      return;
    }

    const options: FetchLotsOptions = {};

    if (searchParams.has('page')) {
      const page = searchParams.get('page');
      if (page && parseInt(page) > 0) {
        options['page'] = parseInt(page);
      }
    }

    if (searchParams.has('zone')) {
      const zone = searchParams.get('zone');
      if (zone) {
        options['zone'] = zone;
      }
    }

    if (searchParams.has('money')) {
      const money = searchParams.get('money')?.split(',');
      if (money) {
        options['priceMin'] = parseInt(money[0]);
        options['priceMax'] = parseInt(money[1]);
      }
    }

    if (searchParams.has('userType')) {
      const userType = searchParams.get('userType');
      if (userType) {
        options['userType'] = parseInt(userType);
      }
    }

    if (searchParams.has('landType')) {
      const landType = searchParams.get('landType');
      if (landType) {
        options['landType'] = parseInt(landType);
      }
    }

    if (searchParams.has('suitabilities')) {
      const suitabilities = searchParams.get('suitabilities');
      if (suitabilities) {
        options['suitabilities'] = suitabilities.split(',').map(s => parseInt(s)).filter(e => e > 0);
      }
    }

    store.dispatch(fetchMyOpportunities(options));
  }, [searchParams]);

  return (
    <>
      <Sidebar />
      <Grid container>
        <Grid item xs={ 6 }>
          <Typography variant="h6">{ t('Mis Oportunidades') }</Typography>
        </Grid>
        <Grid item xs={ 6 } sx={{ textAlign: 'right' }}>
          <Button
            variant="outlined"
            sx={{
              paddingLeft: '2rem !important',
              paddingRight: '2rem !important',
              lineHeight: '1.25rem !important',
            }}
            size="large"
            startIcon={ <Icon icon="akar-icons:settings-horizontal" /> }
            onClick={ () => store.dispatch(openSidebar('opportunities')) }
          >
            { t('Filtros') }
          </Button>
        </Grid>
        <Grid item xs={ 12 }>
          {
            lots.map(l => (
              <LotItem
                key={ l.id }
                lot={ l }
                sx={{ marginBottom: '1rem' }}
              />))
          }
        </Grid>
      </Grid>
    </>
  );
}
