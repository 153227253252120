import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export type SidebarStatus = 'general' | 'filters' | 'opportunities' | false;

export type LotsViewType = 'grid' | 'map'

interface UIState {
  sidebar?: SidebarStatus;
  lots: {
    view: LotsViewType;
  },
  mapLoaded: boolean;
  position?: {
    lat: number;
    lng: number;
  };
  placeId?: string;
  lang: string;
}

export const initialState: UIState = {
  sidebar: false,
  lots: {
    view: 'grid',
  },
  mapLoaded: false,
  lang: 'es',
};

export const UISlice = createSlice({
  name: 'UISlice',
  initialState,
  reducers: {
    closeSidebar(state) {
      state.sidebar = false;
    },
    openSidebar(state, action: PayloadAction<Exclude<SidebarStatus, false> | undefined>) {
      if (action.payload) {
        state.sidebar = action.payload;
        return;
      }

      state.sidebar = 'general';
    },
    selectLotsView(state, action: PayloadAction<LotsViewType>) {
      state.lots.view = action.payload;
    },
    setMapLoadedState(state, action: PayloadAction<boolean>) {
      state.mapLoaded = action.payload;

      if (!action.payload) {
        state.position = undefined;
      }
    },
    setPosition(state, action: PayloadAction<GeolocationCoordinates | undefined>) {
      if (!action.payload) {
        state.position = undefined;
        return;
      }

      state.position = {
        lat: action.payload.latitude,
        lng: action.payload.longitude,
      };
    },
    setPlaceId(state, action: PayloadAction<string | undefined>) {
      state.placeId = action.payload;
    },
  },
});

export const {
  openSidebar,
  closeSidebar,
  selectLotsView,
  setMapLoadedState,
  setPosition,
  setPlaceId } = UISlice.actions;

export const getDrawerStatus = (sidebar: Exclude<SidebarStatus, false>) => {
  return (state: RootState) => state.ui.sidebar === sidebar;
};

export const getLotsSelectedView = (state: RootState) => state.ui.lots.view;

export const getMapIsLoaded = (state: RootState) => state.ui.mapLoaded;
export const getUserPlaceId = (state: RootState) => state.ui.placeId;

export default UISlice.reducer;
