import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  styled,
  SxProps,
  Theme,
  Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Lot } from '../features/lots/lotsSlice';

interface DisabledProp {
  disabled?: boolean;
}

const CardStyled = styled(Card)(() => ({
  display: 'flex',
  width: '100%',
  borderRadius: '10px',
  overflow: 'hidden',
  position: 'relative',
}));

const ContentWrap = styled(Box, {
  shouldForwardProp: prop => prop !== 'disabled',
})<DisabledProp>(({ theme, disabled = false }) => ({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  opacity: disabled ? '.75' : undefined,

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

const Thumbnail = styled(Box, {
  shouldForwardProp: prop => prop !== 'disabled',
})<DisabledProp>(({ theme, disabled = false }) => ({
  backgroundSize: 'cover',
  minHeight: '240px',
  textAlign: 'right',
  filter: disabled ? 'grayscale(1)' : undefined,

  [theme.breakpoints.up('sm')]: {
    flex: '1 0 30%',
    minHeight: '160px',
  },
}));

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    flex: '1 0 70%',
    flexDirection: 'row',
    padding: '.5rem 1rem',
  },
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 600,

  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
}));

const CardActions = styled(Box)(() => ({
  position: 'absolute',
  right: '1rem',
  bottom: '1rem',
  display: 'flex',
}));

const ActionButton = styled(Button)(() => ({
  padding: '7px 28px',
}));

const LotCurrency = styled(Typography)(() => ({
  fontSize: '1.25rem',
  fontWeight: 400,
  letterSpacing: '0.05rem',
  marginRight: '.5rem',
}));

const LotPrice = styled(Typography)(() => ({
  fontSize: '1.625rem',
  fontWeight: 700,
  letterSpacing: '0.05rem',
}));

interface LotItemProps {
  lot: Lot;
  disabled?: boolean;
  surrounding?: boolean;
  sx?: SxProps<Theme>;
  edit?: boolean;
  onEdit?: (lot: Lot) => void;
}

export default function LotItem(props: LotItemProps) {
  const { lot, disabled = false, edit = false, onEdit, sx } = props;
  const { address, area, url, price, thumbnail, title } = lot;

  const { i18n, t } = useTranslation();

  const [parsedPrice, setParsedPrice] = useState('');

  useEffect(() => {
    const parsed = new Intl.NumberFormat(i18n.language, {
      minimumFractionDigits: 0,
      useGrouping: true,
    }).format(price);

    setParsedPrice(parsed);
  }, [price, i18n.language]);

  return (
    <CardStyled sx={ sx } elevation={ disabled ? 3 : 1 }>
      <CardActionArea href={ `/lots/${ url }` } disabled={ disabled }>
        <ContentWrap disabled={ lot.status == 3 }>
          <Thumbnail sx={{ backgroundImage: `url(${ thumbnail })` }} disabled={ lot.status == 3 } />
          <CardContentStyled>
            <Box>
              <CardTitle>{ title ? title : address }</CardTitle>
              <Typography color="text.secondary">
                <Icon
                  icon="ep:location"
                  style={{ width: '1rem', marginRight: '.25rem', verticalAlign: 'middle' }}
                />
                { address }
              </Typography>
              <Typography color="text.secondary">
                <Icon
                  icon="bi:arrows-angle-expand"
                  style={{ width: '.875rem', marginRight: '.475rem', verticalAlign: 'middle' }}
                />
                { area } { lot.unit.name }
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
              <LotCurrency>{ lot.currency.name }</LotCurrency>
              <LotPrice>{ parsedPrice }</LotPrice>
            </Box>
          </CardContentStyled>
        </ContentWrap>
      </CardActionArea>
      <CardActions>
        {
          lot.status === 3
            ? <Chip
                label={ t('Publicación Inactiva') }
                sx={{ height: '2.25rem', marginRight: '1rem' }}
                variant="outlined"
              />
            : null
        }
        {
          edit && onEdit
            ? <ActionButton
                variant="outlined"
                startIcon={ <Icon icon="ant-design:edit-outlined" /> }
                onClick= { () => onEdit(lot) }
              >
                { t('Editar') }
              </ActionButton>
            : null
        }
      </CardActions>
    </CardStyled>
  );
}
