import {
  Box,
  Card,
  CardActionArea,
  cardActionAreaClasses,
  cardClasses,
  Container,
  Grid,
  styled,
  Typography,
  typographyClasses,
  useMediaQuery,
  useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AboutUs from '../components/AboutUs';
import Page from '../components/Page';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: `calc(${ theme.sizes.navbar.height } + 1rem)`,
  backgroundImage: 'url(/static/images/mountains.png)',
  backgroundSize: '100% auto',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom',
  paddingBottom: '2rem',
}));

const Menu = styled(Box)(({ theme }) => ({
  maxWidth: '100%',

  [theme.breakpoints.up('sm')]: {
    maxWidth: '80%',
  },

  [`.${ cardActionAreaClasses.root }`]: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'start',
  },

  [`.${ typographyClasses.root }`]: {
    marginBottom: 0,
  },

  [`.${ cardClasses.root }`]: {
    marginBottom: '1rem',
  },
}));

const TermNumber = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '2.375rem',
  color: 'rgba(0, 0, 0, 0.2)',
  width: '2.5rem',
  lineHeight: '1rem',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.25rem',
  marginBottom: '1rem',
  scrollMargin: `calc(${ theme.sizes.navbar.height } + 1rem)`,
}));

const Paragraph = styled(Typography)(() => ({
  marginBottom: '1rem',
}));

export default function Terms() {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();

  useEffect(() => {
    document.querySelectorAll<HTMLAnchorElement>('a.term_link').forEach(anchor => {
      anchor.addEventListener('click', function(this: HTMLAnchorElement, e) {
        const href = this.getAttribute('href');
        if (!href) {
          return;
        }

        e.preventDefault();

        document.querySelector(href)?.scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
  });

  return (
    <RootStyle title={ t('Términos y Condiciones') }>
      <Container>
        <Grid container direction={ desktop ? 'row' : 'column' }>
          <Grid item sm={ 5 }>
            {
              desktop
                ? null
                : <>
                    <Typography variant="h5" mb="20px">{ t('Términos y Condiciones') }</Typography>
                    <Typography sx={{
                      marginBottom: '2.75rem',
                    }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius diam sed magna.
                      Dignissim ullamcorper tristique massa semper amet, pretium neque ipsum.</Typography>
                  </>
            }
            <Menu>
              <Card>
                <CardActionArea className="term_link" href="#title_1">
                  <TermNumber>1.</TermNumber>
                  <Title>Lorem ipsum dolor sit.</Title>
                </CardActionArea>
              </Card>
              <Card>
                <CardActionArea className="term_link" href="#title_2">
                  <TermNumber>2.</TermNumber>
                  <Title>Lorem ipsum dolor sit.</Title>
                </CardActionArea>
              </Card>
              <Card>
                <CardActionArea className="term_link" href="#title_3">
                  <TermNumber>3.</TermNumber>
                  <Title>Lorem ipsum dolor sit.</Title>
                </CardActionArea>
              </Card>
              <Card>
                <CardActionArea className="term_link" href="#title_4">
                  <TermNumber>4.</TermNumber>
                  <Title>Lorem ipsum dolor sit.</Title>
                </CardActionArea>
              </Card>
              <Card>
                <CardActionArea className="term_link" href="#title_5">
                  <TermNumber>5.</TermNumber>
                  <Title>Lorem ipsum dolor sit.</Title>
                </CardActionArea>
              </Card>
              <Card>
                <CardActionArea className="term_link" href="#title_6">
                  <TermNumber>6.</TermNumber>
                  <Title>Lorem ipsum dolor sit.</Title>
                </CardActionArea>
              </Card>
            </Menu>
          </Grid>
          <Grid item sm={ 7 } sx={{ maxWidth: '768px !important', marginBottom: '10rem', paddingTop: '1rem' }}>
            {
              desktop
                ? <>
                    <Typography variant="h5" mb="20px">{ t('Términos y Condiciones') }</Typography>
                    <Typography sx={{
                      marginBottom: '2.75rem',
                    }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius diam sed magna.
                      Dignissim ullamcorper tristique massa semper amet, pretium neque ipsum.</Typography>
                  </>
                : null
            }
            <Title id="title_1">1.  Lorem ipsum dolor sit.</Title>
            <Paragraph>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt massa fringilla non quam sem nulla
              viverra diam elit. Eu arcu sed amet fermentum vitae vitae. Nisi, consectetur elementum elementum nunc
              viverra urna leo. Vitae, faucibus consectetur nibh diam leo. Viverra ultrices mauris, ipsum leo quis.
              Lobortis proin duis sed bibendum.
            </Paragraph>
            <Paragraph>
              Et ipsum felis nunc, aliquet ultrices. Sit bibendum dolor in senectus amet, suspendisse fringilla sit
              ultrices. Nibh sed suspendisse fringilla diam risus netus et. Pretium, sit pretium mi, elementum ac
              lobortis. Eu amet, quis ante dui nisl risus, laoreet auctor. Sit sit malesuada duis a, condimentum
              ipsum mattis pharetra enim.
            </Paragraph>
            <Paragraph>
              Enim sollicitudin dolor nisl nulla duis dictum auctor et. Fermentum, quis nibh et id non faucibus.
              Risus ac quis leo nam ipsum sed est.
            </Paragraph>

            <Title id="title_2">2.  Lorem ipsum dolor sit.</Title>
            <Paragraph>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt massa fringilla non quam sem nulla
              viverra diam elit. Eu arcu sed amet fermentum vitae vitae. Nisi, consectetur elementum elementum nunc
              viverra urna leo. Vitae, faucibus consectetur nibh diam leo. Viverra ultrices mauris, ipsum leo quis.
              Lobortis proin duis sed bibendum.
            </Paragraph>

            <Title id="title_3">3.  Lorem ipsum dolor sit.</Title>
            <Paragraph>
              Et ipsum felis nunc, aliquet ultrices. Sit bibendum dolor in senectus amet, suspendisse fringilla sit
              ultrices. Nibh sed suspendisse fringilla diam risus netus et. Pretium, sit pretium mi, elementum ac
              lobortis. Eu amet, quis ante dui nisl risus, laoreet auctor. Sit sit malesuada duis a, condimentum
              ipsum mattis pharetra enim.
            </Paragraph>
            <Paragraph>
              Enim sollicitudin dolor nisl nulla duis dictum auctor et. Fermentum, quis nibh et id non faucibus.
              Risus ac quis leo nam ipsum sed est.
            </Paragraph>

            <Title id="title_4">4.  Lorem ipsum dolor sit.</Title>
            <Paragraph>
              Et ipsum felis nunc, aliquet ultrices. Sit bibendum dolor in senectus amet, suspendisse fringilla sit
              ultrices. Nibh sed suspendisse fringilla diam risus netus et. Pretium, sit pretium mi, elementum ac
              lobortis. Eu amet, quis ante dui nisl risus, laoreet auctor. Sit sit malesuada duis a, condimentum
              ipsum mattis pharetra enim.
            </Paragraph>
            <Paragraph>
              Enim sollicitudin dolor nisl nulla duis dictum auctor et. Fermentum, quis nibh et id non faucibus.
              Risus ac quis leo nam ipsum sed est.
            </Paragraph>

            <Title id="title_5">5.  Lorem ipsum dolor sit.</Title>
            <Paragraph>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt massa fringilla non quam sem nulla
              viverra diam elit. Eu arcu sed amet fermentum vitae vitae. Nisi, consectetur elementum elementum nunc
              viverra urna leo. Vitae, faucibus consectetur nibh diam leo. Viverra ultrices mauris, ipsum leo quis.
              Lobortis proin duis sed bibendum.
            </Paragraph>
            <Paragraph>
              Et ipsum felis nunc, aliquet ultrices. Sit bibendum dolor in senectus amet, suspendisse fringilla sit
              ultrices. Nibh sed suspendisse fringilla diam risus netus et. Pretium, sit pretium mi, elementum ac
              lobortis. Eu amet, quis ante dui nisl risus, laoreet auctor. Sit sit malesuada duis a, condimentum
              ipsum mattis pharetra enim.
            </Paragraph>
            <Paragraph>
              Enim sollicitudin dolor nisl nulla duis dictum auctor et. Fermentum, quis nibh et id non faucibus.
              Risus ac quis leo nam ipsum sed est.
            </Paragraph>

            <Title id="title_6">6.  Lorem ipsum dolor sit.</Title>
            <Paragraph>
              Et ipsum felis nunc, aliquet ultrices. Sit bibendum dolor in senectus amet, suspendisse fringilla sit
              ultrices. Nibh sed suspendisse fringilla diam risus netus et. Pretium, sit pretium mi, elementum ac
              lobortis. Eu amet, quis ante dui nisl risus, laoreet auctor. Sit sit malesuada duis a, condimentum
              ipsum mattis pharetra enim.
            </Paragraph>
            <Paragraph>
              Enim sollicitudin dolor nisl nulla duis dictum auctor et. Fermentum, quis nibh et id non faucibus.
              Risus ac quis leo nam ipsum sed est.
            </Paragraph>
          </Grid>
        </Grid>
      </Container>
      <AboutUs />
    </RootStyle>
  );
}
