import { SvgIcon, SvgIconProps } from '@mui/material';

interface PhoneProps extends SvgIconProps {
  sx?: Record<string, unknown>;
}

/* eslint-disable max-len */
export default function PhoneIcon(props: PhoneProps) {
  const { sx, ...rest } = props;

  return (
    <SvgIcon viewBox="0 0 22 22" sx={{ verticalAlign: 'middle', ...sx }} { ...rest }>
      <path d="M20.8881 16.0702V19.1003C20.8892 19.3816 20.8322 19.6601 20.7206 19.9178C20.6091 20.1756 20.4454 20.4069 20.2402 20.5971C20.035 20.7873 19.7927 20.932 19.5289 21.0222C19.265 21.1123 18.9855 21.1458 18.7081 21.1204C15.631 20.7827 12.6751 19.7206 10.0781 18.0196C7.66194 16.4688 5.61345 14.3997 4.07812 11.9592C2.38809 9.32419 1.33636 6.32411 1.00812 3.20202C0.983127 2.92271 1.01599 2.6412 1.10462 2.37542C1.19324 2.10963 1.33569 1.8654 1.52288 1.65827C1.71008 1.45114 1.93792 1.28565 2.19191 1.17233C2.44589 1.05902 2.72046 1.00036 2.99812 1.0001H5.99812C6.48342 0.995273 6.95391 1.16886 7.32188 1.48849C7.68985 1.80813 7.93019 2.25201 7.99812 2.7374C8.12474 3.70712 8.35957 4.65926 8.69812 5.57566C8.83266 5.93718 8.86178 6.33008 8.78202 6.70781C8.70227 7.08554 8.51698 7.43226 8.24812 7.70688L6.97812 8.98965C8.40167 11.5184 10.4746 13.6121 12.9781 15.05L14.2481 13.7672C14.52 13.4957 14.8633 13.3085 15.2372 13.2279C15.6112 13.1474 16.0002 13.1768 16.3581 13.3127C17.2654 13.6547 18.2081 13.8918 19.1681 14.0197C19.6539 14.089 20.0975 14.3361 20.4146 14.7141C20.7318 15.0922 20.9003 15.5748 20.8881 16.0702Z" stroke="#646464" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
}
