import React from 'react';
import { SxProps, TextField, TextFieldProps, Theme } from '@mui/material';

// https://github.com/SampaioLeal/material-ui-numeric-input/blob/master/src/index.tsx

export interface HTMLNumericElement extends Omit<HTMLInputElement, 'value'> {
  value: number;
}

type NumericFieldProps = Omit<TextFieldProps, 'onChange'> & {
  value?: number | string;
  onChange?: (e: React.ChangeEvent<HTMLNumericElement>) => void;

  precision?: number;
  thousandChar?: string;
  decimalChar?: string;

  sx?: SxProps<Theme>;
};

function isNumber(string: string) {
  return !isNaN(Number(string));
}

function NumericField(props: NumericFieldProps) {
  const { value, precision = 0, thousandChar = '.', decimalChar = ',', sx, ...rest } = props;

  const formatter = new Intl.NumberFormat('es-AR', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  function format(number: number) {
    const result = formatter
      .format(number)
      .replace(',', decimalChar)
      .replaceAll('.', thousandChar);

    return result;
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.key === ' ') e.preventDefault();
    if (e.ctrlKey || e.shiftKey || e.key === 'Backspace' || e.key === 'Enter' || e.key === 'Tab' ||
    e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'End' || e.key === 'Home' || e.key === 'Delete' ||
    e.key === 'F5') {
      return;
    }
    if (!isNumber(e.key)) e.preventDefault();
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const newEvent: React.ChangeEvent<HTMLNumericElement> = {
      ...e,
      currentTarget: {
        ...e.currentTarget,
        name: rest.name ? rest.name : '',
        value: 0,
      },
      target: {
        ...e.target,
        name: rest.name ? rest.name : '',
        value: 0,
      },
    };
    let numericRepresentation = e.target.value;

    numericRepresentation = numericRepresentation.replaceAll(thousandChar, '');
    numericRepresentation = numericRepresentation.replace(decimalChar, '');

    if (numericRepresentation === '') {
      e.target.value = '';
      newEvent.target.value = 0;
      newEvent.currentTarget.value = 0;
      return props.onChange && props.onChange(newEvent);
    }

    if (isNumber(numericRepresentation)) {
      const withPrecision = +numericRepresentation / 10 ** precision;
      const formattedNumber = format(withPrecision);

      newEvent.target.value = withPrecision;
      newEvent.currentTarget.value = withPrecision;

      e.target.value = formattedNumber;

      props.onChange && props.onChange(newEvent);
    }
  }

  function handlePaste(event: React.ClipboardEvent<HTMLInputElement>): void {
    event.preventDefault();
  }

  return (
    <TextField
      defaultValue={value ? Number(value) : undefined}
      variant="outlined"
      {...rest}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      onPaste={handlePaste}
      sx={ sx }
    />
  );
}

export default NumericField;
