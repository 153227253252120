import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import lotsAPI, { CreateLot, FetchLotsOptionsAPI } from './lotsAPI';

export interface Lot {
  id: number;

  title: string;
  description?: string;
  address: string;
  url: string;

  type: number;
  suitabilities: number[];
  customSuitabilities?: string;

  thumbnail: string;
  photos: string[];

  price: number;
  currency: {
    id: number;
    name: string;
  }

  area: number;
  unit: {
    id: number;
    name: string;
  }

  opportunity: boolean;

  lat: number;
  lng: number;
  placeId: string;

  status: number;

  publisher?: {
    name: string;
    tel: string;
  }
};

export interface LotsState {
  current?: Lot;
  shareable?:boolean;
  surrounding?: Lot[];
  owned?: Lot[];
  opportunities?: Lot[];
  list?: Lot[];
  pagination?: {
    current: number;
    total: number;
  }
  status: 'booting' | 'idle' | 'loading' | 'failed';
  error?: { message: string; name: string; } | false;
};

export const initialState: LotsState = {
  status: 'booting'
};

export interface FetchLotsOptions {
  zone?: string;

  areaMin?: number;
  areaMax?: number;

  priceMin?: number;
  priceMax?: number;

  userType?: number;
  landType?: number;

  suitabilities?: number[];

  surroundingAreas?: boolean;

  page?: number;
  count?: number;

  overwrite?: boolean;

  exclude?: number;
}

export const fetchLots = createAsyncThunk(
  'lots/fetch',
  async (options: FetchLotsOptions = { overwrite: true }) => {
    const apiOptions: FetchLotsOptionsAPI = {
      locationZone: options?.zone,
      page: options?.page,
      count: options?.count,
      areaMin: options?.areaMin,
      areaMax: options?.areaMax,
      priceMax: options?.priceMax,
      priceMin: options?.priceMin,
      userType: options?.userType,
      landType: options?.landType,
      suitabilities: options?.suitabilities,
      surroundingAreas: options?.surroundingAreas
    };

    const lots = await lotsAPI.fetchLots(apiOptions);

    if (options?.exclude) {
      lots.data.filter(l => l.id !== options.exclude);
    }

    return lots;
  }
);

export const fetchMyLots = createAsyncThunk(
  'lots/fetch-mine',
  async () => {
    const lots = await lotsAPI.fetchMyLots();
    return lots;
  }
);

export const fetchMyOpportunities = createAsyncThunk(
  'opportunities/fetch-mine',
  async (options: FetchLotsOptions = {}) => {
    const apiOptions: FetchLotsOptionsAPI = {
      locationZone: options?.zone,
      page: options?.page,
      count: options?.count,
      areaMin: options?.areaMin,
      areaMax: options?.areaMax,
      priceMax: options?.priceMax,
      priceMin: options?.priceMin,
      userType: options?.userType,
      landType: options?.landType,
      suitabilities: options?.suitabilities,
      surroundingAreas: options?.surroundingAreas
    };

    const lots = await lotsAPI.fetchMyOpportunities(apiOptions);
    return lots;
  }
);

export const fetchLotById = createAsyncThunk(
  'lots/fetchById',
  async (id: number) => {
    const response = await lotsAPI.fetchLotById(id);
    return response.data;
  }
);

export const fetchLotIsSharable = createAsyncThunk('lots/fetchIsSharable',
  async (id:number) =>{
    const response = await lotsAPI.fetchLotIsSharable(id);
    return { ...response };
  }
);


export const createLot = createAsyncThunk(
  'lots/create',
  async (lot: CreateLot) => {
    const response = await lotsAPI.createLot(lot);
    return response.data;
  }
);

export const updateLot = createAsyncThunk(
  'lots/update',
  async (lot: Lot) => {
    const response = await lotsAPI.updateLot(lot);
    return response.data;
  }
);

export const visitLot = createAsyncThunk(
  'lots/visit',
  async (id: number) => await lotsAPI.visitLot(id)
);

export const lotsSlice = createSlice({
  name: 'lots',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchLots.pending.type]: state => {
      state.status = 'loading';
      state.error = false;
    },
    [fetchLots.fulfilled.type]: (state, action) => {
      state.status = 'idle';
      if (action.meta?.arg?.surroundingAreas) {
        state.surrounding = action.payload.data;
      } else if (!action.meta || !action.meta.arg || action.meta.arg.overwrite) {
        state.list = action.payload.data;
      } else if (!action.meta.arg.overwrite && state.list && state.list?.length > 0) {
        state.list = [...state.list, ...action.payload.data];
      } else if (action.payload.data.length > 0) {
        state.list = action.payload.data;
      }
      state.pagination = {
        current: action.payload.pagination.current,
        total: action.payload.pagination.total
      };
    },
    [fetchLots.rejected.type]: (state, action) => {
      state.status = 'failed';
      state.error = {
        name: action.error.name,
        message: action.error.message
      };
    },
    [fetchMyOpportunities.pending.type]: state => {
      state.status = 'loading';
      state.error = false;
    },
    [fetchMyOpportunities.fulfilled.type]: (state, action) => {
      state.status = 'idle';
      state.opportunities = action.payload.data,
      state.pagination = {
        current: action.payload.current_page,
        total: action.payload.last_page
      };
    },
    [fetchMyOpportunities.rejected.type]: (state, action) => {
      state.status = 'failed';
      state.error = {
        name: action.error.name,
        message: action.error.message
      };
    },
    [fetchMyLots.pending.type]: state => {
      state.status = 'loading';
      state.error = false;
    },
    [fetchMyLots.fulfilled.type]: (state, action) => {
      state.status = 'idle';
      state.owned = action.payload.data;
      state.pagination = {
        current: action.payload.current_page,
        total: action.payload.last_page
      };
    },
    [fetchMyLots.rejected.type]: (state, action) => {
      state.status = 'failed';
      state.error = {
        name: action.error.name,
        message: action.error.message
      };
    },
    [fetchLotById.pending.type]: state => {
      state.status = 'loading';
      state.current = undefined;
      state.error = false;
    },
    [fetchLotById.fulfilled.type]: (state, action) => {
      state.status = 'idle';
      state.current = action.payload;
    },
    [fetchLotById.rejected.type]: (state, action) => {
      state.status = 'failed';
      state.error = {
        name: action.error.name,
        message: action.error.message
      };
    },
    [fetchLotIsSharable.fulfilled.type]: (state, action) =>{
      state.status='idle';
      state.shareable= action.payload.shareable;
    },
    [fetchLotIsSharable.rejected.type]: (state, action) => {
      state.status = 'failed';
      state.error = {
        name: action.error.name,
        message: action.error.message
      };
    }
  }
});

export const selectLots = (state: RootState) => state.lots;
export const getLots = (state: RootState) => state.lots.list ? state.lots.list : [];
export const getSurroundingLots = (state: RootState) => state.lots.surrounding ? state.lots.surrounding : [];
export const getMyLots = (state: RootState) => state.lots.owned ? state.lots.owned : [];
export const getMyOpportunities = (state: RootState) => state.lots.opportunities ? state.lots.opportunities : [];
export const getLotsError = (state: RootState) => state.lots.error;
export const getCurrentLot = (state: RootState) => state.lots.current;
export const getCurrentPage = (state: RootState) => state.lots.pagination?.current ? state.lots.pagination.current : 1;
export const getTotalPages = (state: RootState) => state.lots.pagination?.total ? state.lots.pagination.total : 1;

export default lotsSlice.reducer;
