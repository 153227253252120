import { useMemo, useState } from 'react';
import { Slider, sliderClasses, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SliderStyled = styled(Slider)(({ theme }) => ({
  [`.${ sliderClasses.markLabel }[data-index="0"]`]: {
    fontSize: '1rem',
    transform: 'none',
    color: theme.palette.primary.main,

    strong: {
      fontSize: '1.125rem',
    },
  },
  [`.${ sliderClasses.markLabel }[data-index="1"]`]: {
    fontSize: '1rem',
    transform: 'translate(-100%)',
    color: theme.palette.primary.main,

    strong: {
      fontSize: '1.125rem',
    },
  },
}));

interface MoneyRangeSliderProps {
  onChange?: (value: number[]) => void;

  min?: number;
  max?: number;

  leftValue?: number;
  rightValue?: number;

  minDistance?: number;
  step?: number;
}

export default function MoneyRangeSlider(props: MoneyRangeSliderProps) {
  const { onChange, min = 0, max = 100, step = 5000, leftValue, rightValue, minDistance = 500 } = props;

  const { i18n } = useTranslation();

  const [moneyRange, setMoneyRange] = useState<number[]>([
    leftValue ? leftValue : min,
    rightValue ? rightValue : max,
  ]);

  const handleThumbChange = (_event: unknown, value: number | number[], activeThumb: number) => {
    if (!Array.isArray(value)) {
      return;
    }

    if (activeThumb === 0) {
      value[0] = Math.min(value[0], value[1] - minDistance);
    } else {
      value[1] = Math.max(value[1], value[0] + minDistance);
    }

    setMoneyRange(value);
  };

  const handleMoneyChange = (_event: unknown, value: number | number[]) => {
    if (!onChange || !Array.isArray(value)) {
      return;
    }

    onChange(value);
  };

  const marks = useMemo(() => {
    const formatter = new Intl.NumberFormat(i18n.language, {
      minimumFractionDigits: 0,
      useGrouping: true,
    });

    return [
      {
        value: min,
        label: (<>USD <strong>{ formatter.format(min) }</strong></>),
      },
      {
        value: max,
        label: (<>USD <strong>{ formatter.format(max) }</strong></>),
      },
    ];
  }, [min, max, i18n.language]);

  return (
    <SliderStyled
      sx={{ width: '100%' }}
      value={ moneyRange }
      onChange={ handleThumbChange }
      onChangeCommitted={ handleMoneyChange }
      min={ min }
      max={ max }
      marks={ marks }
      step={ step }
      valueLabelDisplay="auto"
      disableSwap
    />
  );
}
