import { useEffect, useRef, useState } from 'react';

import { Box, Container, Fab, styled, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';

import { Lot } from '../features/lots/lotsSlice';

import EmblaSlider from './EmblaSlider';
import LotCard from './LotCard';
import LeftArrowIcon from '../icons/LeftArrow';
import RightArrowIcon from '../icons/RightArrow';

const CustomBox = styled(Box)(({ theme }) => ({
  padding: '1.5rem 0 !important',

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
  },
}));

const ArrowsWrapper = styled(Container)(() => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  marginBottom: '1rem',
}));

const CustomFab = styled(Fab)(({ theme }) => ({
  backgroundColor: 'transparent',
  marginLeft: theme.spacing(2),
}));

interface SliderProps {
  lots: Lot[];
  cols?: number;
  sx?: SxProps<Theme>;
}

export default function LotSlider(props: SliderProps) {
  const { cols = 3, lots, sx } = props;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.up('sm') && theme.breakpoints.down('lg'));
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const goLeftArrow = useRef<HTMLButtonElement>(null);
  const goRightArrow = useRef<HTMLButtonElement>(null);

  const [realCols, setRealCols] = useState(cols);
  const [align, setAlign] = useState<'start' | 'center' | 'end'>('center');
  const [draggable, setDraggable] = useState(true);

  useEffect(() => {
    if (lots.length > cols + 2) {
      setRealCols(cols);
      setAlign('center');
      setDraggable(true);
      return;
    }

    let newCols = cols;

    if (tablet) {
      newCols -= 1;
    } else {
      if (lots.length == newCols + 1) {
        newCols += 1;
      }
    }

    if (lots.length < newCols + 2) {
      setAlign('start');
    } else {
      setAlign('center');
    }

    if (lots.length < newCols) {
      newCols = lots.length;
    }

    if (mobile) {
      setRealCols(1);
      setDraggable(lots.length > 1);
    } else {
      setRealCols(newCols);
      setDraggable(newCols > lots.length);
    }
  }, [cols, lots, mobile, tablet]);

  return (
    <CustomBox sx={ sx }>
      {
        desktop
          ? <ArrowsWrapper>
              <CustomFab ref={ goLeftArrow } disabled={ lots.length <= realCols }>
                <LeftArrowIcon />
              </CustomFab>
              <CustomFab ref={ goRightArrow } disabled={ lots.length <= realCols }>
                <RightArrowIcon />
              </CustomFab>
            </ArrowsWrapper>
          : null
      }
      <EmblaSlider
        cols={ realCols }
        draggable={ draggable }
        prevArrow={ goLeftArrow.current }
        nextArrow={ goRightArrow.current }
        align={ align }
      >
        {
          lots.map(l => <LotCard key={ l.id } lot={ l } />)
        }
      </EmblaSlider>
    </CustomBox>
  );
}
