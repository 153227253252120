import { useEffect } from 'react';

import config from '../config';
import { store } from '../store';
import {
  getUserFlow,
  login,
  getUserById,
  User
} from '../features/users/usersSlice';

import { Box, Container, Grid, styled } from '@mui/material';
import Page from '../components/Page';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: `calc(${theme.sizes.navbar.height} + 1rem)`,
  backgroundImage: 'url(/static/images/mountains.png)',
  backgroundSize: '100% auto',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom',
  paddingBottom: '2rem'
}));

const IFrameWrapper = styled(Box)(() => ({
  borderRadius: '20px',
  overflow: 'hidden',
  padding: '2rem',
  backgroundColor: '#ffffff',
  boxShadow: '0px 0px 30px 5px rgba(54, 73, 102, 0.05)'
}));

const IframeStyled = styled('iframe')(() => ({
  width: '100%',
  border: 'none',
  height: '380px'
}));

export default function Login() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleMessage = async (event: MessageEvent) => {
    if (event.origin !== config.api.url) {
      return;
    }

    if (event.data.type !== 'login_ok') {
      return;
    }

    const userData: Required<User> = {
      id: event.data.idPlotlandUser,
      type: event.data.userProfileCode === 1 ? 'individual' : 'realstate',
      name: event.data.fullname ? event.data.fullname : 'S/D'
    };

    localStorage.setItem('plotland_private_token', event.data.access_token);
    localStorage.setItem('plotland_private_refresh', event.data.refresh_token);

    // Cargamos la información de login
    await store.dispatch(login(userData));

    // Recuperar datos usuario
    await store.dispatch(getUserById(userData.id)).unwrap();

    // Recuperar proceso de publicación usuario
    await store.dispatch(getUserFlow()).unwrap();

    navigate('/', { replace: true });
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <RootStyle title={t('Ingresar')}>
      <Container sx={{ marginTop: '8rem', marginBottom: '12rem' }}>
        <Grid container>
          <Grid item sm={2}></Grid>
          <Grid item sm={8}>
            <IFrameWrapper>
              <IframeStyled
                src={`${config.api.url}/SLPage?idPage=824c3b96-8505-4b8e-b880-e2bfcf82b629&lang=${i18n.language}`}
              />
            </IFrameWrapper>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
