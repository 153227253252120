import { Button } from '@mui/material';

interface CircleButtonProps {
  children: string;
  size?: number;

  [key: string]: unknown;
}

export default function CircleButton(props: CircleButtonProps) {
  const { children, size = 111, ...rest } = props;
  return (
    <Button
      variant="outlined"
      sx={{
        height: `${ size }px`,
        width: `${ size }px`,
        borderRadius: '50%',
      }}
      { ...rest }
    >
      { children }
    </Button>
  );
}
