import {
  ListItem,
  ListItemText,
  styled,
  SxProps,
  Theme } from '@mui/material';
import { useSelector } from 'react-redux';
import { Message as MessageType } from '../../features/comms/commsAPI';
import { getUser } from '../../features/users/usersSlice';

interface OwnMessage {
  own?: boolean;
}

const MessageWrapper = styled(ListItem, {
  shouldForwardProp: prop => prop != 'own',
})<OwnMessage>(({ own = false }) => ({
  padding: 0,
  paddingLeft: own ? '1rem' : 0,
  paddingRight: own ? 0 : '1rem',
}));

const MessageContent = styled(ListItemText, {
  shouldForwardProp: prop => prop != 'own',
})<OwnMessage>(({ theme, own = false }) => ({
  backgroundColor: '#fff',
  padding: '.5rem',
  borderTopLeftRadius: own ? '5px' : 0,
  borderBottomLeftRadius: own ? '5px' : 0,
  borderTopRightRadius: own ? 0 : '5px',
  borderBottomRightRadius: own ? 0 : '5px',
  boxShadow: theme.shadows[7],
}));

export interface MessageProps {
  value: MessageType;
  sx?: SxProps<Theme>;
}

export default function Message(props: MessageProps) {
  const { value: message, sx } = props;

  const user = useSelector(getUser);

  const ownMessage = parseInt(message.senderPlotUser) == user?.id;
  const timestamp = new Date(+message.messageDate);

  return (
    <MessageWrapper sx={{ ...sx }} own={ ownMessage }>
      <MessageContent
        own={ ownMessage }
        primary={ message.message }
        secondary={<>
          { message.sender }&nbsp;
          <i>
            { timestamp.getHours() }:{ timestamp.getMinutes() }&nbsp;
            { timestamp.getDate() }/{ timestamp.getMonth() }
          </i>
        </>}
        secondaryTypographyProps={{ fontSize: '.7rem' }}
      />
    </MessageWrapper>
  );
}
