import { Add } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AboutUs from '../components/AboutUs';
import Page from '../components/Page';
import colors from '../theme/colors';

import JoinLeft from '../assets/images/join-left.svg';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: `calc(${ theme.sizes.navbar.height } + 1rem)`,
  backgroundImage: 'url(/static/images/mountains.png)',
  backgroundSize: '100% auto',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom',
  paddingBottom: '2rem',
}));

const SummaryNumber = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '2.375rem',
  color: 'rgba(0, 0, 0, 0.2)',
  marginRight: '1.625rem',
  width: '2.5rem',
}));

export default function FAQ() {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();

  return (
    <RootStyle title={ t('Preguntas Frecuentes') }>
      <Grid container>
        {
          desktop
            ? <Grid item sm={ 6 } sx={{ maxWidth: '690px !important' }}>
                <img src={ JoinLeft } style={{ maxWidth: '95%' }} />
              </Grid>
            : null
        }
        <Grid item sm={ 6 } sx={{ maxWidth: '768px !important', marginBottom: '10rem', paddingTop: '1rem' }}>
          <Typography variant="h5" mb="20px">{ t('Preguntas Frecuentes') }</Typography>
          <Typography fontSize="1.25rem" mb="45px">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
            varius diam sed magna. Dignissim ullamcorper tristique massa semper amet, pretium neque ipsum.</Typography>
          <Accordion expanded>
            <AccordionSummary expandIcon={ <Add htmlColor={ colors.brown } /> }>
              <SummaryNumber>1.</SummaryNumber>
              ¿Lorem ipsum dolor sit amet, consectetur adipiscing elit?
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius diam sed magna.
                Dignissim ullamcorper tristique massa semper amet, pretium neque ipsum.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={ <Add htmlColor={ colors.brown } /> }>
              <SummaryNumber>2.</SummaryNumber>
              ¿Lorem ipsum dolor sit amet, consectetur adipiscing elit?
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius diam sed magna.
                Dignissim ullamcorper tristique massa semper amet, pretium neque ipsum.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={ <Add htmlColor={ colors.brown } /> }>
              <SummaryNumber>3.</SummaryNumber>
              ¿Lorem ipsum dolor sit amet, consectetur adipiscing elit?
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius diam sed magna.
                Dignissim ullamcorper tristique massa semper amet, pretium neque ipsum.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={ <Add htmlColor={ colors.brown } /> }>
              <SummaryNumber>4.</SummaryNumber>
              ¿Lorem ipsum dolor sit amet, consectetur adipiscing elit?
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius diam sed magna.
                Dignissim ullamcorper tristique massa semper amet, pretium neque ipsum.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={ <Add htmlColor={ colors.brown } /> }>
              <SummaryNumber>5.</SummaryNumber>
              ¿Lorem ipsum dolor sit amet, consectetur adipiscing elit?
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius diam sed magna.
                Dignissim ullamcorper tristique massa semper amet, pretium neque ipsum.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={ <Add htmlColor={ colors.brown } /> }>
              <SummaryNumber>6.</SummaryNumber>
              ¿Lorem ipsum dolor sit amet, consectetur adipiscing elit?
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius diam sed magna.
                Dignissim ullamcorper tristique massa semper amet, pretium neque ipsum.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={ <Add htmlColor={ colors.brown } /> }>
              <SummaryNumber>7.</SummaryNumber>
              ¿Lorem ipsum dolor sit amet, consectetur adipiscing elit?
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius diam sed magna.
                Dignissim ullamcorper tristique massa semper amet, pretium neque ipsum.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={ <Add htmlColor={ colors.brown } /> }>
              <SummaryNumber>8.</SummaryNumber>
              ¿Lorem ipsum dolor sit amet, consectetur adipiscing elit?
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In varius diam sed magna.
                Dignissim ullamcorper tristique massa semper amet, pretium neque ipsum.</Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <AboutUs />
    </RootStyle>
  );
}
