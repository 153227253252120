export default function TableRow() {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          ['&:last-child td']: {
            border: 0,
          },
        },
      },
    },
  };
}
