export default function AccordionDetails() {
  return {
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          marginLeft: '4.25rem',
        },
      },
    },
  };
}
