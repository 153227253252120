import { ImgHTMLAttributes } from 'react';

import { loadImage } from '../services/cache';

export default function SuspenseImage(props: ImgHTMLAttributes<HTMLImageElement>): JSX.Element {
  if (!props.src) {
    throw new Error('Se tiene que setear una imagen para que funcione la suspensión');
  }

  loadImage(props.src).read();

  return <img {...props} />;
}
