import LotSlider from '../../components/LotSlider';
import Section from '../../components/Section';
import { BoxProps } from '@mui/material';
import { Lot } from '../../features/lots/lotsSlice';
import { useTranslation } from 'react-i18next';

interface SurroundingProps extends BoxProps {
  lots: Lot[];
}

export default function Surrounding(props: SurroundingProps) {
  const { lots, ...rest } = props;

  const { t } = useTranslation();

  return (
    <Section title={ t('Zonas Aledañas') } { ...rest }>
      <LotSlider lots={ lots } cols={ 3 } />
    </Section>
  );
}
