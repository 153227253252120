import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';

import componentsOverride from './overrides';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';

interface ThemeConfigParams {
  children: JSX.Element | JSX.Element[]
}

declare module '@mui/material/styles' {
  // eslint-disable-next-line no-unused-vars
  interface Theme {
    sizes: {
      page: {
        width: string;
      }
      content: {
        width: string;
        height: string;
      }
      navbar: {
        width: string;
        height: string;
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  interface ThemeOptions {
    sizes?: {
      page?: {
        width?: string;
      }
      content?: {
        width?: string;
        height?: string;
      }
      navbar?: {
        width?: string;
        height?: string;
      }
    }
  }
}

export default function ThemeConfig({ children }: ThemeConfigParams): JSX.Element {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    shadows,
    sizes: {
      page: {
        width: '1440px',
      },
      content: {
        width: '1200px',
      },
      navbar: {
        height: '100px',
      },
    },
    palette,
    typography,
  });

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
