import { useMemo } from 'react';
import LotGrid from '../../components/LotGrid';
import LotItem from '../../components/LotItem';
import { Lot } from '../../features/lots/lotsSlice';

interface GridProps {
  lots: Lot[];
}

export default function Grid(props: GridProps) {
  const { lots } = props;

  const lotsList = useMemo(() => {
    const mutableLots = [...lots];
    const list = [];

    list.push(
      <LotGrid
        key='lot-grid-1'
        lots={ mutableLots.splice(0, 2) }
        cols={ 2 }
        sx={{ marginBottom: '1rem' }}
      />);

    if (mutableLots.length > 3) {
      list.push(
        <LotGrid
          key='lot-grid-2'
          lots={ mutableLots.splice(0, 3) }
          cols={ 3 }
          sx={{ marginBottom: '1rem' }}
        />);
    }

    if (mutableLots.length > 3) {
      list.push(
        <LotGrid
          key='lot-grid-3'
          lots={ mutableLots.splice(0, 3) }
          cols={ 3 }
          sx={{ marginBottom: '1rem' }}
        />);
    }

    if (mutableLots.length > 0) {
      list.push(...mutableLots.map(lot => (
        <LotItem key={`lot-list-${ lot.id }`} lot={ lot } sx={{ marginBottom: '1rem' }} />
      )));
    }

    return <>{ list }</>;
  }, [lots]);

  return lotsList;
}
