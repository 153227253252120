import { closeSidebar } from '../../features/ui/UISlice';
import { store } from '../../store';

import { Box, IconButton, ListItem, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import Logo from '../Logo';

export default function SidebarHeader() {
  const theme = useTheme();

  return (
    <ListItem sx={{ borderBottom: `solid 1px ${ theme.palette.grey[200] }` }}>
      <Box sx={{ flexGrow: 1 }}>
        <Logo sx={{ height: '26px', width: 'auto' }} />
      </Box>
      <IconButton onClick={ () => store.dispatch(closeSidebar()) }>
        <Close />
      </IconButton>
    </ListItem>
  );
}
