import {
  Box,
  Button,
  Container,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';

import config from '../config';

import Logo from './Logo';
import LogoQk from './LogoQk';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FooterStyle = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  paddingTop: '2rem',

  [theme.breakpoints.up('sm')]: {
    width: '100%',
    paddingTop: '15rem',
    display: 'flex',
    justifyContent: 'center'
  }
}));

const ContactInformationStyle = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: '2rem',
  fontWeight: 400,
  letterSpacing: '0.03em',
  fontSize: '0.75rem'
}));

const SocialNetworkStyle = styled(Box)(() => ({
  width: '2.25rem',
  height: '2.25rem',
  lineHeight: '2.25rem',
  borderRadius: '1.125rem',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#BEA356'
}));

const TitleStyle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  letterSpacing: '0.0075rem',
  fontWeight: 600,
  lineHeight: '2.5rem',
  marginBottom: '1.25rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',

  [theme.breakpoints.up('sm')]: {
    paddingLeft: 0,
    paddingRight: 0
  }
}));

const OptionStyle = styled(Typography)(({ theme }) => ({
  lineHeight: '1.25rem',
  marginBottom: '.625rem',
  letterSpacing: '0.0168rem',

  a: {
    color: theme.palette.text.primary,
    textDecoration: 'none'
  }
}));

const CopyrightStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.disabled,
  fontSize: '.875rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingBottom: '5.75rem',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '5.75rem'
  }
}));

const CopyrightNoticeStyle = styled(Box)(({ theme }) => ({
  justifyContent: 'center',
  marginBottom: 1,

  [theme.breakpoints.up('sm')]: {
    display: 'block',
    alignItems: 'center',
    letterSpacing: '0.1rem'
  }
}));

const AuthorshipStyle = styled(Typography)(({ theme }) => ({
  justifyContent: 'center',
  marginBottom: 1,

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const JoinWrapper = styled(Box)(({ theme }) => ({
  marginBottom: '3.75rem',
  textAlign: 'center',

  [theme.breakpoints.up('sm')]: {
    textAlign: 'right',
    marginBottom: 0,
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'top',
    alignItems: 'center'
  }
}));

const SocialWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-around',
  paddingLeft: '4.1875rem',
  paddingRight: '4.1875rem',
  marginTop: '3.625rem',

  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0
  }
}));

const WannaBeButton = styled(Button)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
    textTransform: 'none'
  }
}));

export default function Footer() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const desktop = useMediaQuery(theme.breakpoints.up('sm'));

  const handleWannaBeButtonClick = () => {
    navigate('/opportunities');
  };

  return (
    <Container>
      <FooterStyle>
        <Grid container direction={desktop ? 'row' : 'column'}>
          <Grid item md={4}>
            <Logo
              sx={{ margin: desktop ? '0 0 1rem 0' : '1rem auto 2rem auto' }}
            />
            {desktop ? (
              <>
                <ContactInformationStyle>
                  +54 9 221 123 4567
                </ContactInformationStyle>
                <ContactInformationStyle>
                  mail@ejemplo.com
                </ContactInformationStyle>
              </>
            ) : null}
          </Grid>
          <Grid item md={4} mb={desktop ? 0 : 2}>
            <TitleStyle>{t('Institucional')}</TitleStyle>
            <OptionStyle>
              <Link to="/contact">{t('Contactanos')}</Link>
            </OptionStyle>
            <OptionStyle>
              <Link to="/faq">{t('Preguntas Frecuentes')}</Link>
            </OptionStyle>
            <OptionStyle>
              <Link to="/terms">{t('Términos y Condiciones')}</Link>
            </OptionStyle>
          </Grid>
          <Grid item md={4}>
            <JoinWrapper>
              <TitleStyle>
                <WannaBeButton
                  variant="contained"
                  onClick={handleWannaBeButtonClick}
                >
                  {t('Quiero ser premium')}
                </WannaBeButton>
              </TitleStyle>
              <SocialWrapper>
                <SocialNetworkStyle>
                  <Facebook htmlColor="white" />
                </SocialNetworkStyle>
                <SocialNetworkStyle>
                  <Twitter htmlColor="white" />
                </SocialNetworkStyle>
                <SocialNetworkStyle>
                  <LinkedIn htmlColor="white" />
                </SocialNetworkStyle>
                <SocialNetworkStyle>
                  <Instagram htmlColor="white" />
                </SocialNetworkStyle>
              </SocialWrapper>
            </JoinWrapper>
          </Grid>
        </Grid>
      </FooterStyle>
      <CopyrightStyle>
        <CopyrightNoticeStyle>
          Copyright &copy; 2022 PLOTLAND v{config.version}
        </CopyrightNoticeStyle>
        <AuthorshipStyle>
          {t('Diseñado por')}
          <LogoQk sx={{ ml: 2 }} />
        </AuthorshipStyle>
      </CopyrightStyle>
    </Container>
  );
}
