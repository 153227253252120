import { Children, ReactNode, useEffect, useMemo } from 'react';
import useEmblaCarousel from 'embla-carousel-react';

import { styled, useMediaQuery, useTheme } from '@mui/material';

const EmblaViewport = styled('div')(() => ({
  padding: '10px',
  overflow: 'hidden',
  width: '100%',
}));

const EmblaContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 0 100%',

  [theme.breakpoints.up('sm')]: {
    flex: '0 0 25%',
  },
}));

const EmblaSlide = styled('div')(({ theme }) => ({
  position: 'relative',
  marginRight: '.625rem',
  paddingRight: 0,

  [theme.breakpoints.up('sm')]: {
    marginRight: 0,
    paddingRight: '1rem',
  },
}));

const EmblaSlideInner = styled('div')(() => ({
  position: 'relative',
}));

interface EmblaSliderProps {
  children: ReactNode;
  cols?: number;
  align?: 'start' | 'center' | 'end' | number;
  loop?: boolean;
  draggable?: boolean;

  prevArrow?: HTMLButtonElement | null;
  nextArrow?: HTMLButtonElement | null;
}

export default function EmblaSlider(props: EmblaSliderProps) {
  const { children, cols= 3, prevArrow, nextArrow, align = 'center', loop = true, draggable = true } = props;

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align,
    loop,
    draggable,
  });

  // Reiniciamos el componente cuando cambian los children. Esto soluciona problemas
  // con el render de los child y los handle de los events el la carga inicial
  useEffect(() => {
    if (!children || !emblaApi) {
      return;
    }

    emblaApi.reInit();
  }, [emblaApi, children, cols]);

  // Si se pasó un element que representa el scroll al slide anterior, asociamos
  // el evento click a la función correspondiente del slider
  useEffect(() => {
    if (!emblaApi || !prevArrow) {
      return;
    }

    prevArrow.addEventListener('click', () => {
      emblaApi.scrollPrev();
    });
  }, [emblaApi, prevArrow]);

  // Si se pasó un element que representa el scroll al slide siguiente, asociamos
  // el evento click a la función correspondiente del slider
  useEffect(() => {
    if (!emblaApi || !nextArrow) {
      return;
    }

    nextArrow.addEventListener('click', () => {
      emblaApi.scrollNext();
    });
  }, [emblaApi, nextArrow]);

  const slideStyle = useMemo(() => {
    return {
      minWidth: `calc(${ desktop ? (100 / cols).toFixed(4) : 100 }% + .25rem)`,
      maxWidth: `calc(${ desktop ? (100 / cols).toFixed(4) : 100 }% + .25rem)`,
    };
  }, [cols, desktop]);

  return (
    <div className="embla">
      <EmblaViewport className="embla__viewport" ref={ emblaRef }>
        <EmblaContainer className="embla__container">
          {
            Children.map(children, item => (
              <EmblaSlide sx={ slideStyle }>
                <EmblaSlideInner>
                  { item }
                </EmblaSlideInner>
              </EmblaSlide>
            ))
          }
        </EmblaContainer>
      </EmblaViewport>
    </div>
  );
}
