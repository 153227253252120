import { Box, Dialog, styled } from '@mui/material';

import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const GalleryWrapper = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, .5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '.image-gallery': {
    width: '100vw',
  },
}));

interface GalleryProps {
  images: string[];
  open: boolean;
  onClose: () => void;
}

export default function Gallery(props: GalleryProps) {
  const { images, open = false, onClose } = props;

  const items: ReactImageGalleryItem[] = images.map(i => ({
    original: i,
    loading: 'eager',

  }));

  return (
    <Dialog
      open={ open }
      onClose={ onClose }
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
        },
      }}
      fullWidth
      maxWidth="lg"
    >
      <GalleryWrapper>
        <ImageGallery
          items={ items }
          showBullets
        />
      </GalleryWrapper>
    </Dialog>
  );
}
