import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GoogleMap, Marker } from '@react-google-maps/api';

import { Lot } from '../../features/lots/lotsSlice';
import { getMapIsLoaded } from '../../features/ui/UISlice';

import { useTheme } from '@mui/material';

import mapStyle from '../../assets/mapStyle.json';

import GoldMarker from '../../assets/markers/gold.png';
import RegularMarker from '../../assets/markers/regular.png';

interface LotMapProps {
  lot: Lot;
}

export default function LotMap(props: LotMapProps) {
  const { lot } = props;

  const theme = useTheme();
  const desktop = theme.breakpoints.up('sm');

  const navigate = useNavigate();
  const isLoaded = useSelector(getMapIsLoaded);

  const map = useMemo(() => {
    if (!isLoaded) {
      return null;
    }

    return (
      <GoogleMap
        mapContainerStyle={{
          height: '400px',
          width: '100%',
          borderRadius: '10px',
        }}
        center= {{ lat: lot.lat, lng: lot.lng }}
        zoom={14}
        options={{
          controlSize: null,
          disableDefaultUI: true,
          zoomControl: true,
          gestureHandling: 'greedy',
          styles: mapStyle as google.maps.MapTypeStyle[],
        }}
      >
        <Marker
          key={ lot.id }
          icon={{
            url: lot.opportunity ? GoldMarker : RegularMarker,
            scaledSize: new window.google.maps.Size(40, 52),
          }}
          position={{ lat: lot.lat, lng: lot.lng }}
          onClick={ () => navigate(`/lots/${ lot.url }`) }
        />
      </GoogleMap>
    );
  }, [isLoaded, desktop, lot]);

  return isLoaded ? map : null;
}
