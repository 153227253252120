import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../store';

import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import Price from '../../components/Price';
import {
  cancelSubscription,
  fetchMySubscriptions,
  getMySubscriptions,
  MySubscription } from '../../features/subscriptions/subscriptionsSlice';
import { useTranslation } from 'react-i18next';

import BadgeBag from '../../assets/images/badge-bag.svg';
import BadgePremium from '../../assets/images/badge-premium.svg';
import config from '../../config';
import { getRefreshToken, getToken } from '../../features/users/usersSlice';
import { getCurrencies, getPaymentGateways, getPaymentStatus } from '../../features/options/optionsSlice';
import { getLabel } from '../../utils/lists';
import { getErrorMessage } from '../../utils/errorHandler';

const TitleWrapper = styled(Paper)(({ theme }) => ({
  border: '1px solid #e0e0e0',
  padding: '.75rem',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  marginBottom: '1rem',
  alignItems: 'start',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const SubscriptionIcon = styled('img')(() => ({
  maxWidth: '45px',
  margin: '1rem',
  alignSelf: 'start',
}));

const DetailsBox = styled(Box)(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
}));

const SubscriptionTitle = styled(Typography)(() => ({
  fontSize: '1.5rem',
  fontWeight: 600,
}));

const InfoBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const ActionsWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  textAlign: 'center',

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const TableWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
  overflowX: 'scroll',
  marginBottom: '2rem',

  [theme.breakpoints.up('sm')]: {
    overflowX: 'auto',
  },
}));

const ButtonLink = styled(Button)(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.primary.main,

  ':hover': {
    textDecoration: 'underline',
    color: theme.palette.primary.light,
    backgroundColor: 'transparent',
  },
}));

const IframeStyled = styled('iframe')(() => ({
  width: '100%',
  border: 'none',
  height: '680px',
}));

const baseIframeUrl = `${ config.api.url }/SLResourceShorcut` +
  '?_idCreateSession=true' +
  '&_idExecuteInMainFrame=false' +
  '&_idResource=SLProcessDispatcher' +
  '&cdAsunto=9da94503ebd64f82a5dbb80168184fc1' +
  '&cdProcess=9da94503ebd64f82a5dbb80168184fc1@|@1' +
  '&button=new' +
  '&FORM_INITIAL_VALUES_HIDDEN=';

export default function Subscriptions() {
  const { t } = useTranslation();

  const token = useSelector(getToken);
  const refreshToken = useSelector(getRefreshToken);

  const [iframeUrl, setIframeUrl] = useState<string>();

  const mySubscriptions = useSelector(getMySubscriptions);
  const paymentGateways = useSelector(getPaymentGateways);
  const paymentStatus = useSelector(getPaymentStatus);
  const currencies = useSelector(getCurrencies);

  const [packages, setPackages] = useState<MySubscription[]>([]);
  const [subscriptions, setSubscriptions] = useState<MySubscription[]>([]);

  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  const [alert, setAlert] = useState<string>();

  const [activeSubscription, setActiveSubscription] = useState<MySubscription>();
  const [expandedSubscription, setExpandedSubscription] = useState<MySubscription>();

  const handleSubscriptionDialogOpen = (subscription: MySubscription) => {
    setIframeUrl(`${ baseIframeUrl
    }productToBeCanceled=${subscription.id
    }&access_token=${ token
    }&refresh_token=${ refreshToken }`);

    setActiveSubscription(subscription);
    setSubscriptionDialogOpen(true);
  };

  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false);
  };

  const handleOpenCancelDialog = (subscription: MySubscription) => {
    setActiveSubscription(subscription);
    setCancelDialogOpen(true);
  };

  const handleAcceptCancelDialog = () => {
    if (!activeSubscription) {
      return;
    }

    store.dispatch(cancelSubscription({
      subscriptionID: activeSubscription.pgwSubscriptionId,
      paymentGateway: parseInt(activeSubscription.paymentGateway),
    }))
      .unwrap()
      .then(() => {
        store.dispatch(fetchMySubscriptions(true));

        if (activeSubscription.tipoDeProducto == 1) {
          setAlert('Sus Lotes se mantendrán activos hasta el momento en que se produzca el fin de la vigencia de la ' +
            'presente suscripción. En dicha fecha, aquellas Publicaciones que se encuentren en estado activo, serán ' +
            'inactivadas');
        } else {
          setAlert('La cancelación ha sido exitosa');
        }
      })
      .catch(err => {
        console.error(err);
        setAlert(getErrorMessage(err));
      })
      .finally(() => {
        setCancelDialogOpen(false);
      })
    ;
  };

  const getPaymentGatewayLabel = (id: number) => getLabel(paymentGateways, id);
  const getPaymentStatusLabel = (id: number) => getLabel(paymentStatus, id);
  const getCurrencyLabel = (id: number) => getLabel(currencies, id);

  useEffect(() => {
    store.dispatch(fetchMySubscriptions(true));
  }, []);

  useEffect(() => {
    setPackages(mySubscriptions.filter(s => s.tipoDeProducto == 1));
    setSubscriptions(mySubscriptions.filter(s => s.tipoDeProducto == 2));
  }, [mySubscriptions]);

  return (
    <>
      <Typography variant="h6">{ t('Mis Suscripciones') }</Typography>
      {
        alert
          ? <Alert
              severity="info"
              onClose={ () => setAlert(undefined) }
              sx={{ fontSize: '1rem', marginBottom: '1rem' }}
            >
              { alert }
            </Alert>
          : null
      }
      {
        subscriptions.map(s => (
          <Fragment key={ `s_${ s.id }` }>
            <TitleWrapper elevation={ 0 }>
              <Box sx={{ flexGrow: 1, flexDirection: 'row', display: 'flex' }}>
                <SubscriptionIcon src={ BadgePremium } />
                <DetailsBox>
                  <SubscriptionTitle>
                    { s.acquiredProduct ? s.acquiredProduct : 'S/D' }
                  </SubscriptionTitle>
                  <Typography>{ t('Zonas seleccionadas') }:</Typography>
                  <Stack
                    direction="row"
                    spacing={ 2 }
                    justifyContent="start">
                    {
                      s.zonasSuscriptasIter.map(z => (
                        <Chip
                          key={ z['zonasSuscriptasIter/locationId'] }
                          label={ z['zonasSuscriptasIter/locationName'] }
                        />
                      ))
                    }
                  </Stack>
                </DetailsBox>
              </Box>
              <InfoBox>
                <Price
                  value={ s.productPrice }
                  period={ t('mes') }
                  sx={{ alignSelf: 'center' }}
                />
                <ActionsWrapper>
                  {
                    s.id && s.pgwSubscriptionId && !s.subscriptionEndDate
                      ? <ButtonLink
                          variant="text"
                          size="small"
                          disableRipple
                          onClick={ () => handleOpenCancelDialog(s) }
                        >{ t('Cancelar') }</ButtonLink>
                      : null
                  }
                  {
                    s.subscriptionEndDate
                      ? `${ t('Fin de Vigencia') }: ${ new Date(+s.subscriptionEndDate).toLocaleDateString() }`
                      : null
                  }
                </ActionsWrapper>
              </InfoBox>
            </TitleWrapper>
            {
              Array.isArray(s.payments) && s.payments.length > 0
                ? <TableWrapper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{ t('Fecha') }</TableCell>
                          <TableCell>{ t('Forma de Pago') }</TableCell>
                          <TableCell>{ t('Total') }</TableCell>
                          <TableCell>{ t('Estado') }</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          s.payments.map(payment => (
                            <TableRow key={ `payment_${ payment.paymentId }` }>
                              <TableCell>{ new Date(+payment.createTime).toLocaleDateString() }</TableCell>
                              <TableCell>{ getPaymentGatewayLabel(+payment.paymentGateway) }</TableCell>
                              <TableCell>{ getCurrencyLabel(+payment.currency) } { payment.totalAmountPaid }</TableCell>
                              <TableCell>{ getPaymentStatusLabel(+payment.status) }</TableCell>
                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </TableWrapper>
                : null
            }
          </Fragment>
        ))
      }
      {
        packages.map(p => (
          <Fragment key={ `p_${ p.id }` }>
            <TitleWrapper elevation={ 0 }>
              <SubscriptionIcon src={ BadgeBag } />
              <DetailsBox>
                <SubscriptionTitle>
                  { p.acquiredProduct ? p.acquiredProduct : 'S/D' }
                </SubscriptionTitle>
                <Typography>
                  { t('Publicaciones (disponibles / total)') }: { p.availablePublics } / { p.totalPublications }
                </Typography>
              </DetailsBox>
              <InfoBox sx={{ display: 'flex', flexDirection: 'column' }}>
                <Price
                  value={ p.packPrice }
                  period={ t('mes') }
                  sx={{ alignSelf: 'center' }}
                />
                <ActionsWrapper>
                  {
                    p.id && p.pgwSubscriptionId && !p.subscriptionEndDate
                      ? <ButtonLink
                          variant="text"
                          size="small"
                          disableRipple
                          onClick={ () => handleOpenCancelDialog(p) }
                        >{ t('Cancelar') }</ButtonLink>
                      : null
                  }
                  {
                    !p.subscriptionEndDate
                      ? <ButtonLink
                          variant="text"
                          size="small"
                          disableRipple
                          onClick= { () => handleSubscriptionDialogOpen(p) }
                        >
                          { t('Actualizar') }
                        </ButtonLink>
                      : null
                  }
                  {
                    p.subscriptionEndDate
                      ? `${ t('Fin de Vigencia') }: ${ new Date(+p.subscriptionEndDate).toLocaleDateString() }`
                      : null
                  }
                </ActionsWrapper>
              </InfoBox>
            </TitleWrapper>
            {
              Array.isArray(p.payments) && p.payments.length > 0
                ? <TableWrapper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{ t('Fecha') }</TableCell>
                          <TableCell>{ t('Forma de Pago') }</TableCell>
                          <TableCell>{ t('Total') }</TableCell>
                          <TableCell>{ t('Estado') }</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          expandedSubscription?.id === p.id
                            ? p.payments.map(payment => (
                              <TableRow key={ `payment_${ payment.paymentId }` }>
                                <TableCell>{ new Date(+payment.createTime).toLocaleDateString() }</TableCell>
                                <TableCell>{ getPaymentGatewayLabel(+payment.paymentGateway) }</TableCell>
                                <TableCell>
                                  { getCurrencyLabel(+payment.currency) } { payment.totalAmountPaid }
                                </TableCell>
                                <TableCell>{ getPaymentStatusLabel(+payment.status) }</TableCell>
                              </TableRow>
                            ))
                            : p.payments.slice(0, 10).map(payment => (
                              <TableRow key={ `payment_${ payment.paymentId }` }>
                                <TableCell>{ new Date(+payment.createTime).toLocaleDateString() }</TableCell>
                                <TableCell>{ getPaymentGatewayLabel(+payment.paymentGateway) }</TableCell>
                                <TableCell>
                                  { getCurrencyLabel(+payment.currency) } { payment.totalAmountPaid }
                                </TableCell>
                                <TableCell>{ getPaymentStatusLabel(+payment.status) }</TableCell>
                              </TableRow>
                            ))
                        }
                      </TableBody>
                      {
                        p.payments.length > 10
                          ? <TableFooter>
                              <TableRow>
                                <TableCell colSpan={4}>
                                  {
                                    expandedSubscription?.id === p.id
                                      ? <ButtonLink
                                          variant="text"
                                          size="small"
                                          disableRipple
                                          onClick={ () => setExpandedSubscription(undefined) }
                                        >{ t('Ocultar pagos') }</ButtonLink>
                                      : <ButtonLink
                                          variant="text"
                                          size="small"
                                          disableRipple
                                          onClick={ () => setExpandedSubscription(p) }
                                        >{ t('Ver pagos anteriores') }...</ButtonLink>
                                  }
                                </TableCell>
                              </TableRow>
                            </TableFooter>
                          : null
                      }
                    </Table>
                  </TableWrapper>
                : null
            }
          </Fragment>
        ))
      }
      <Dialog
        open={ cancelDialogOpen }
        maxWidth="lg"
        onClose={ handleCloseCancelDialog }
      >
        <DialogTitle>{ t('Cancelar') }</DialogTitle>
        <DialogContent>
          { t('¿Está seguro que desea cancelar la suscripción?') }
        </DialogContent>
        <DialogActions sx={{ padding: '1rem' }}>
          <Button
            variant="outlined"
            onClick={ handleCloseCancelDialog }
            sx={{ padding: '.625rem 2rem' }}
          >{ t('Cancelar') }</Button>
          <Button
            variant="contained"
            onClick={ handleAcceptCancelDialog }
            sx={{ padding: '.625rem 2rem' }}
            autoFocus
          >{ t('Aceptar') }</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={ subscriptionDialogOpen } maxWidth="md" fullWidth>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <IconButton onClick={ () => setSubscriptionDialogOpen(false) }>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          {
            iframeUrl
              ? <IframeStyled src={ iframeUrl } />
              : null
          }
        </DialogContent>
      </Dialog>
    </>
  );
}
