import { ChevronRight } from '@mui/icons-material';
import {
  List,
  ListItemButton,
  ListItemIcon,
  listItemIconClasses,
  ListItemText,
  listItemTextClasses,
  styled,
  useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const ListItemStyled = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== 'selected',
})(({ theme, selected }) => ({
  borderBottom: `solid 1px ${ theme.palette.grey[200] }`,

  [`.${ listItemTextClasses.primary }`]: {
    fontSize: '1.125rem',
    fontWeight: selected ? 600 : 400,
  },

  [`.${ listItemIconClasses.root }`]: {
    minWidth: 'auto',
  },
}));

interface MenuProps {
  sticky?: boolean;
}

export default function Menu(props: MenuProps) {
  const { sticky = false } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  const handleProfileClick = () => navigate('/profile');
  const handleLotsClick = () => navigate('/profile/lots');
  const handleOpportunitiesClick = () => navigate('/profile/opportunities');
  const handleMySubscriptionsClick = () => navigate('/profile/subscriptions');
  const handleMyMessagesClick = () => navigate('/profile/messages');

  const sx = useMemo(() => {
    if (sticky) {
      return {
        position: 'sticky',
        top: `calc(${ theme.sizes.navbar.height } + 1rem)`,
      };
    }

    return { position: 'relative', top: 'auto' };
  }, [sticky]);

  return (
    <List sx={ sx }>
      <ListItemStyled onClick={ handleProfileClick } selected={ location.pathname == '/profile' }>
        <ListItemText primary={ t('Mi Perfil') } />
        <ListItemIcon>
          <ChevronRight />
        </ListItemIcon>
      </ListItemStyled>
      <ListItemStyled onClick={ handleLotsClick } selected={ location.pathname == '/profile/lots' }>
        <ListItemText primary={ t('Mis Lotes') } />
        <ListItemIcon>
          <ChevronRight />
        </ListItemIcon>
      </ListItemStyled>
      <ListItemStyled onClick={ handleOpportunitiesClick } selected={ location.pathname == '/profile/opportunities' }>
        <ListItemText primary={ t('Mis Oportunidades') } />
        <ListItemIcon>
          <ChevronRight />
        </ListItemIcon>
      </ListItemStyled>
      <ListItemStyled onClick={ handleMySubscriptionsClick } selected={ location.pathname == '/profile/subscriptions' }>
        <ListItemText primary={ t('Mis Suscripciones') } />
        <ListItemIcon>
          <ChevronRight />
        </ListItemIcon>
      </ListItemStyled>
      <ListItemStyled onClick={ handleMyMessagesClick } selected={ location.pathname == '/profile/messages' }>
        <ListItemText primary={ t('Mis Mensajes') } />
        <ListItemIcon>
          <ChevronRight />
        </ListItemIcon>
      </ListItemStyled>
    </List>
  );
}
