import { outlinedInputClasses } from '@mui/material';

export default function OutlinedInput() {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 'calc((1.4375em + 33px) / 2)',
          [`&.${ outlinedInputClasses.sizeSmall }`]: {
            borderRadius: 'calc((1.4375em + 17px) / 2)',
          },
          [`&.${ outlinedInputClasses.multiline }`]: {
            alignItems: 'start',
            minHeight: '6.5rem',
            borderRadius: '10px',
          },
        },
      },
    },
  };
}
