import { useSelector } from 'react-redux';
import { getS3Status } from '../../features/s3/s3Slice';

import { Alert, Box, Button, styled, Typography } from '@mui/material';
import Uploader from '../../components/Uploader/Uploader';
import { useState } from 'react';
import { Lot } from '../../features/lots/lotsSlice';
import { useTranslation } from 'react-i18next';

const Content = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '2.5rem',
}));

interface StepImagesProps {
  onFinish: (lot: Partial<Lot>) => void;
}

export default function StepImages(props: StepImagesProps) {
  const { onFinish } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<string[]>([]);
  const [required, setRequired] = useState(false);

  const S3Status = useSelector(getS3Status);

  const handleFileUploaded = (file: string) => {
    setFiles([...files, file]);

    if (required) {
      setRequired(false);
    }
  };

  const handleFileDeleted = (file: string) => {
    setFiles(files.filter(f => f != file));
  };

  const handleNextClick = () => {
    if (files.length < 3) {
      setRequired(true);
      return;
    }

    setLoading(true);

    onFinish({
      photos: files,
    });
  };

  return (
    <Content>
      <Box sx={{ width: '100%', textAlign: 'center', mb: '2.25rem', mt: '2.25rem' }}>
        <Typography sx={{ fontSize: '1.625rem', fontWeight: 600 }}>
          { t('Agregá fotos del lote') }
        </Typography>
      </Box>
      <Uploader onUpload={ handleFileUploaded } onDelete={ handleFileDeleted } />
      {
        required
          ? <Alert severity="error" sx={{ fontSize: '1rem', marginTop: '1rem' }}>
              { t('Tiene que subir al menos 3 imágenes para poder proceder con la publicación') }
            </Alert>
          : null
      }
      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          size="large"
          sx={{ width: '200px', mt: '2.5rem' }}
          onClick={ handleNextClick }
          disabled={ S3Status === 'loading' || loading }
        >
          { t('Siguiente') }
        </Button>
      </Box>
    </Content>
  );
}
