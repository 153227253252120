import colors from '../colors';

export default function ToggleButtonGroup() {
  return {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: '30px',
          border: `solid 1px ${ colors.brown }`,
        },
      },
    },
  };
}
