import api from '../../services/api';
import { store } from '../../store';

export interface UserDeyel {
  correoEle: string;
  userStatus: number;
  nroIdPersonal: string;
  nombre: string;
  userProfile: number;
  realEstater: string;
  apellido: string;
  id: number;
  telefono: string;
  deyelUser: string;
};

export interface InmoDeyel {
  lastName: string;
  correoEle: string;
  personalIdNumber: string;
  provincia: string;
  cdUserLastUpdate: string;
  dtLastUpdate: string;
  razonSocial: string;
  cdUserStore: string;
  dtStore: string;
  id: number;
  telefono: string;
  descripcionInmo: string;
  inmobiliaria: string;
  barrio: string;
  nroIdPerInmueble: string;
  direccion: string;
  nroMatricula: number;
  pais: string;
  realEstaterStatus: number;
  firstName: string;
  ciudad: string;
  colegioDistrital: number;
  realterEmail: string;
  nroIdFiscal: string;
}

export interface UserFlow {
  acquiredProduct?: number;
  availableCapacity?: number;
  packPrice?: number;

  acquisitionCaseNumber: string;
  publicationCaseNumber: string;

  packAcquisition: boolean;
  upgradePackage: boolean;
  publicationProcess: boolean;
  checkout: boolean;
}

interface UserFlowData {
  packId?: number;
  availableCapacity?: number;
  packPrice?: string;

  acquisitionCaseNumber: string;
  publicationCaseNumber: string;

  packAcquisition: boolean;
  upgradePackage: boolean;
  publicationProcess: boolean;
  checkout: boolean;
}

interface UpdateResponse {
  resultRequest?: string;
}

interface UpdateUserData {
  isParticular: boolean;
  isRealter: boolean;
  deyelUser: string;
  userEmail: string;
  userDocument: string;
  userName: string;
  userSurname: string;
  userTelephone: string;
}

export interface RefreshTokenResponse {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
}

export async function fetchUserById(id: number) {
  const response = await api<UserDeyel>('GET', `forms/USUA1640616459/instances/${ id }`);

  return response.data;
}

export async function fetchInmoById(id: number) {
  const response = await api<InmoDeyel>('GET', `forms/INMO1640616728/instances/${ id }`);

  return response.data;
}

export async function fetchUserFlow(): Promise<{ data: UserFlow }> {
  const response = await api<UserFlowData>('PUT', 'rules/plotUserStatusOnPublication-1/execute');

  return {
    data: {
      ...response.data,
      packPrice: response.data.packPrice ? parseInt(response.data.packPrice) : undefined,
    },
  };
}

async function updateUser(data: Partial<UserDeyel>) {
  const state = store.getState();
  const user = state.users;

  const payload: UpdateUserData = {
    isParticular: user.type === 'individual',
    isRealter: user.type === 'realstate',
    deyelUser: `${ user.current?.deyelUser }`,
    userEmail: `${ data.correoEle }`,
    userDocument: `${ data.nroIdPersonal }`,
    userName: `${ data.nombre }`,
    userSurname: `${ data.apellido }`,
    userTelephone: `${ data.telefono }`,
  };

  const response = await api<UpdateResponse>('PUT', 'rules/plotModifyUserData-1/execute', payload);

  return {
    data: response.data,
  };
}

async function changePassword(data: { currentPassword: string, newPassword: string }) {
  const response = await api<{ result: string }>('PUT', 'rules/dyModifyPassword-1/execute', data);

  return {
    data: response.data,
  };
}

const usersAPI = {
  fetchUserById,
  fetchInmoById,
  fetchUserFlow,
  updateUser,
  changePassword,
};

export default usersAPI;
