import { Box, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import FieldImage from '../../assets/field.png';

const Content = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8rem',
}));

export default function StepSuccess() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Content>
      <img src={ FieldImage } />
      <br /><br />
      <Typography variant="h4" color="primary">{ t('¡Publicaste tu Lote!' )}</Typography>
      <br /><br />
      <Link to="/" style={{ color: theme.palette.primary.light }}>{ t('Volver al inicio') }</Link>
    </Content>
  );
}
