import { Box, styled } from '@mui/material';

import Page from '../components/Page';

import Header from '../assets/header.jpg';
import Star from '../assets/star.svg';

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: theme.breakpoints.values.lg,
  maxWidth: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

export default function About() {
  return (
    <Page>
      <img src={ Header } style={{
        width: '100%',
        height: 'auto',
        clipPath: 'ellipse(150% 100% at top)',
        zIndex: -1,
      }} />
      <Box>
        <ContentWrapper>
          <img src={ Star } />
        </ContentWrapper>
      </Box>
    </Page>
  );
}
