import { RootState } from '../store';

export const loadState = () => {
  try {
    const serializedData = localStorage.getItem('plotland_state');
    if (serializedData === null) {
      return undefined;
    }

    return JSON.parse(serializedData);
  } catch (error) {
    return undefined;
  }
};


export const saveState = (state: Partial<RootState>) => {
  try {
    const serializedData = JSON.stringify(state);
    localStorage.setItem('plotland_state', serializedData);
  } catch (error) {
    console.error(error);
  }
};
