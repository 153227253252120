import { Grid, Skeleton } from '@mui/material';

export default function GridLoader() {
  return (
    <Grid container spacing={ 2 }>
      <Grid item sm={ 6 }>
        <Skeleton variant="rectangular" height="546px" sx={{ borderRadius: '20px' }} />
      </Grid>
      <Grid item sm={ 6 }>
        <Skeleton variant="rectangular" height="546px" sx={{ borderRadius: '20px' }} />
      </Grid>

      <Grid item sm={ 4 }>
        <Skeleton variant="rectangular" height="546px" sx={{ borderRadius: '20px' }} />
      </Grid>
      <Grid item sm={ 4 }>
        <Skeleton variant="rectangular" height="546px" sx={{ borderRadius: '20px' }} />
      </Grid>
      <Grid item sm={ 4 }>
        <Skeleton variant="rectangular" height="546px" sx={{ borderRadius: '20px' }} />
      </Grid>

      <Grid item sm={ 4 }>
        <Skeleton variant="rectangular" height="546px" sx={{ borderRadius: '20px' }} />
      </Grid>
      <Grid item sm={ 4 }>
        <Skeleton variant="rectangular" height="546px" sx={{ borderRadius: '20px' }} />
      </Grid>
      <Grid item sm={ 4 }>
        <Skeleton variant="rectangular" height="546px" sx={{ borderRadius: '20px' }} />
      </Grid>
    </Grid>
  );
}
