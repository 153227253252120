import { Box, Container, styled, Typography, useTheme } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import Marker from '../assets/map-marker.png';

const AboutWrapper = styled(Container)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'end',
  },
}));

const About = styled(Box)(({ theme }) => ({
  boxShadow: '0px 0px 20px 15px rgba(54, 73, 102, 0.05)',
  padding: '2rem',
  margin: '1rem',
  borderRadius: '10px',
  backgroundColor: '#fff',

  [theme.breakpoints.up('sm')]: {
    width: '570px',
    maxWidth: '100%',
    height: '427px',

    transform: 'translateY(25%)',

    padding: '4.25rem 7rem 4.25rem 4.625rem',
  },
}));

export default function AboutUs() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <AboutWrapper>
      <About>
        <Typography>{ t('Quiénes somos') }</Typography>
        <Typography sx={{ fontSize: '2rem' }}>
          <Trans i18nKey="socio.estrategico">
            Somos tu socio estratégico, en&nbsp;
            <span style={{ color: theme.palette.primary.light }}>Plotland te ayudamos a invertir mejor</span>
          </Trans>
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1.4375rem' }}>
          <img src={ Marker } style={{ width: '60px', height: '60px' }} />
          <Typography sx={{ marginLeft: '2rem' }}>
            { t('Una Mirada distinta, una nueva forma de hacer negocios.') }
            &nbsp;
            { t('Descubrí el lugar más grande de Latinoamerica, la mayor base de datos del mundo.') }
          </Typography>
        </Box>
      </About>
    </AboutWrapper>
  );
}
