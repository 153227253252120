import { sliderClasses, Theme } from '@mui/material';

export default function Slider(theme: Theme) {
  return {
    MuiSlider: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
          height: '6px',
          [`&.${ sliderClasses.colorPrimary }`]: {
            [`.${ sliderClasses.rail }`]: {
              opacity: 1,
            },

            [`.${ sliderClasses.track }`]: {
              color: theme.palette.primary.light,
            },

            [`.${ sliderClasses.thumb }`]: {
              color: theme.palette.primary.light,
            },
          },
        },
      },
    },
  };
}
