import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getSuitabilities,
  getPriceRangeMax,
  getPriceRangeMin,
  getLandTypes,
  getUserTypes } from '../../features/options/optionsSlice';
import { store } from '../../store';

import { closeSidebar, getDrawerStatus, getMapIsLoaded } from '../../features/ui/UISlice';

import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  List,
  ListItem,
  Radio,
  RadioGroup,
  styled,
  Typography,
  useTheme } from '@mui/material';
import { Icon } from '@iconify/react';
import SidebarHeader from './Header';
import MoneyRangeSlider from '../MoneyRangeSlider';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';

const Title = styled(Typography)(({ theme }) => ({
  paddingLeft: '.5rem',
  borderRadius: 0,
  justifyContent: 'start',
  fontSize: '1.375rem',
  color: theme.palette.primary.main,

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const ListItemWithBorder = styled(ListItem)(({ theme }) => ({
  borderBottom: `solid 1px ${ theme.palette.grey[200] }`,
}));

export default function SidebarFilters() {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>();

  const open = useSelector(getDrawerStatus('opportunities'));
  const suitabilities = useSelector(getSuitabilities);
  const landTypes = useSelector(getLandTypes);
  const priceMin = useSelector(getPriceRangeMin);
  const priceMax = useSelector(getPriceRangeMax);
  const mapIsLoaded = useSelector(getMapIsLoaded);
  const userTypes = useSelector(getUserTypes);

  const [moneyValues, setMoneyValues] = useState([priceMin, priceMax]);
  const [suitabilitiesValues, setSuitabilitiesValues] = useState<number[]>([]);
  const [zoneValue, setZoneValue] = useState('');
  const [zoneId, setZoneId] = useState<string>('');
  const [canQueryZone, setCanQueryZone] = useState(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setZoneValue(event.target.value);
    setZoneId('');
    setCanQueryZone(false);
  };

  useEffect(() => {
    const suitabilitiesRaw = searchParams.get('suitabilities');
    if (suitabilitiesRaw) {
      const suitabilitiesParsed = suitabilitiesRaw
        .split(',')
        .map(a => parseInt(a))
        .filter(a => a > 0);

      setSuitabilitiesValues(suitabilitiesParsed);
    }

    const moneyRaw = searchParams.get('money');
    if (moneyRaw) {
      const moneyParsed = moneyRaw.split(',');

      setMoneyValues([
        parseInt(moneyParsed[0]),
        parseInt(moneyParsed[1]),
      ]);
    }

    const zone = searchParams.get('zone');
    if (!zone) {
      setZoneValue('');
    }
  }, [searchParams]);

  useEffect(() => {
    if (!mapIsLoaded || !inputRef.current) {
      return;
    }

    const zone = searchParams.get('zone');
    if (zone) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({
        placeId: zone,
      })
        .then(({ results }) => {
          if (!results[0]) {
            setZoneValue('');
            return;
          }

          setZoneValue(results[0].formatted_address);
        });
    }

    const newAutocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      { types: ['(cities)'] },
    );

    newAutocomplete.setFields(['formatted_address', 'place_id']); // specify what properties we will get from API

    // add a listener to handle when the place is selected
    newAutocomplete.addListener('place_changed', () => {
      if (!newAutocomplete) {
        return;
      }

      const place = newAutocomplete.getPlace();

      if (!place.place_id || !place.formatted_address) {
        return;
      }

      setZoneValue(place.formatted_address);
      setZoneId(place.place_id);
      setCanQueryZone(true);
    });
  }, [mapIsLoaded, inputRef.current]);

  const handleRangeChange = (value: number[]) => {
    searchParams.set('money', value.join(','));
    navigate(`/profile/opportunities?${ searchParams.toString() }`);
  };

  const handleUserTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '-1') {
      searchParams.delete('userType');
    } else {
      searchParams.set('userType', event.target.value);
    }

    navigate(`/profile/opportunities?${ searchParams.toString() }`);
  };

  const handleLandTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '-1') {
      searchParams.delete('landType');
    } else {
      searchParams.set('landType', event.target.value);
    }

    navigate(`/profile/opportunities?${ searchParams.toString() }`);
  };

  const handleSuitabilitiesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const changedSuitability = parseInt(event.target.value);

    let newSuitabilities = suitabilitiesValues;
    if (suitabilitiesValues.includes(changedSuitability)) {
      newSuitabilities = suitabilitiesValues.filter(a => a !== changedSuitability);
    } else {
      newSuitabilities.push(changedSuitability);
    }

    setSuitabilitiesValues(newSuitabilities);

    searchParams.set('suitabilities', newSuitabilities.join(','));
    navigate(`/profile/opportunities?${ searchParams.toString() }`);
  };

  const handleCloseClick = () => store.dispatch(closeSidebar());

  const handleSearchClick = () => {
    if (!zoneId) {
      searchParams.delete('zone');
    } else {
      searchParams.set('zone', zoneId);
    }

    navigate(`/profile/opportunities?${ searchParams.toString() }`);
  };

  const handleResetSearchClick = () => {
    searchParams.delete('zone');
    navigate(`/profile/opportunities?${ searchParams.toString() }`);
  };

  return (
    <Drawer
      open={ open }
      anchor="right"
      onClose={ handleCloseClick }
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Box sx={{ width: '320px', overflowX: 'hidden' }}>
        <List>
          <SidebarHeader />
          <ListItemWithBorder sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
            <Icon icon="akar-icons:settings-horizontal" height="1.125rem" color={ theme.palette.primary.main } />
            <Title>{ t('Filtros') }</Title>
          </ListItemWithBorder>
          <ListItemWithBorder sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontSize: '1.25rem', alignSelf: 'start' }}>{ t('Localidad') }</Typography>
            <Input
              type="search"
              inputRef={ inputRef }
              sx={{ width: '100%' }}
              value={ zoneValue }
              onChange={ handleInputChange }
              placeholder={ t('Ingresá ubicación, por ej: Buenos Aires') }
              endAdornment={
                searchParams.get('zone') && !!zoneValue
                  ? <IconButton onClick={ handleResetSearchClick }>
                      <Close />
                    </IconButton>
                  : null
              }
            />
            <Button
              variant="contained"
              sx={{ pl: '2rem', pr: '2rem', mt: '1rem' }}
              onClick={ handleSearchClick }
              disabled={ !canQueryZone && !!zoneValue }
            >
              { t('¡Buscar!') }
            </Button>
          </ListItemWithBorder>
          <ListItemWithBorder sx={{ flexDirection: 'column', alignItems: 'start' }}>
            <Typography sx={{ fontSize: '1.25rem' }}>{ t('Rango de Precio') }</Typography>
            <MoneyRangeSlider
              min={ priceMin }
              max={ priceMax }
              leftValue={ moneyValues[0] }
              rightValue={ moneyValues[1] }
              onChange={ handleRangeChange }
            />
          </ListItemWithBorder>
          <ListItemWithBorder sx={{ flexDirection: 'column', alignItems: 'start' }}>
            <Typography sx={{ fontSize: '1.25rem' }}>{ t('Tipo de Anunciante') }</Typography>
            <FormControl>
              <RadioGroup onChange={ handleUserTypeChange }>
                <FormControlLabel
                  label={ t('Todos') }
                  value="-1"
                  control={ <Radio /> }
                  checked={ !searchParams.get('userType') }
                />
                {
                  userTypes.map(type => (
                    <FormControlLabel
                      key={`type_${ type.id }`}
                      label={ type.name }
                      value={ type.id }
                      control={ <Radio /> }
                      checked={ searchParams.get('userType') === type.id.toString() }
                    />
                  ))
                }
              </RadioGroup>
            </FormControl>
          </ListItemWithBorder>
          <ListItemWithBorder sx={{ flexDirection: 'column', alignItems: 'start' }}>
            <Typography sx={{ fontSize: '1.25rem' }}>{ t('Tipo de Terreno') }</Typography>
            <FormControl>
              <RadioGroup onChange={ handleLandTypeChange }>
                <FormControlLabel
                  label={ t('Todos') }
                  value="-1"
                  control={ <Radio /> }
                  checked={ !searchParams.get('landType') }
                />
                {
                  landTypes.map(type => (
                    <FormControlLabel
                      key={`type_${ type.id }`}
                      label={ type.name }
                      value={ type.id }
                      control={ <Radio /> }
                      checked={ searchParams.get('landType') === type.id.toString() }
                    />
                  ))
                }
              </RadioGroup>
            </FormControl>
          </ListItemWithBorder>
          <ListItem sx={{ flexDirection: 'column', alignItems: 'start' }}>
            <Typography sx={{ fontSize: '1.25rem' }}>{ t('Aptitud del Terreno') }</Typography>
            <FormGroup>
              {
                suitabilities.map(suitability => (
                  <FormControlLabel
                    key={`suitability_${ suitability.id }`}
                    label={ suitability.name }
                    value={ suitability.id }
                    control={ <Checkbox onChange={ handleSuitabilitiesChange } /> }
                    checked={ suitabilitiesValues.includes(suitability.id) }
                  />
                ))
              }
            </FormGroup>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}
