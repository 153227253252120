import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import _ from 'lodash';

import { loadState, saveState } from './services/storage';

import commsSlice, { initialState as commsState } from './features/comms/commsSlice';
import lotsSlice, { initialState as lotsState } from './features/lots/lotsSlice';
import packagesSlice, { initialState as packagesState } from './features/packages/packagesSlice';
import optionsSlice, { initialState as optionsState } from './features/options/optionsSlice';
import subscriptionSlice, { initialState as subscriptionState } from './features/subscriptions/subscriptionsSlice';
import S3Slice, { initialState as S3State } from './features/s3/s3Slice';
import UISlice, { initialState as UIState } from './features/ui/UISlice';
import usersSlice, { initialState as usersState } from './features/users/usersSlice';

const initialState = {
  comms: commsState,
  lots: lotsState,
  options: optionsState,
  packages: packagesState,
  subscription: subscriptionState,
  s3: S3State,
  ui: UIState,
  users: usersState,
};

const initialData = _.merge({}, initialState, loadState(), {
  ui: {
    mapLoaded: false,
  },
});

export const store = configureStore({
  reducer: {
    comms: commsSlice,
    lots: lotsSlice,
    options: optionsSlice,
    packages: packagesSlice,
    subscription: subscriptionSlice,
    s3: S3Slice,
    ui: UISlice,
    users: usersSlice,
  },
  preloadedState: initialData,
});

store.subscribe(() => {
  const { ui, users } = store.getState();
  saveState({ ui, users });
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
