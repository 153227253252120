import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import Image from '../assets/logo.svg';

interface LogoProps {
  sx?: SxProps<Theme>
  size?: number
}

export default function Logo(props: LogoProps) {
  const { sx, size = 1 } = props;

  return <Box
    component="img"
    src={ Image }
    sx={{ width: 188 * size, height: 48 * size, ...sx }}
  />;
}
