import { styled, SvgIcon, SvgIconProps } from '@mui/material';

const SvgIconStyled = styled(SvgIcon)(() => ({
  fill: 'none',
}));

export default function BulletCheck(props: Omit<SvgIconProps, 'viewBox'>) {
  return (
    <SvgIconStyled {...props} viewBox="0 0 26 26">
      <circle cx="13" cy="13" r="13" fill="#EACE80"/>
      <path
        d="M19.8193 8.66669L11.4075 17.0785L7.58398 13.2549"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIconStyled>
  );
}
