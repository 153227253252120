import { ReactNode } from 'react';
import { Box, SxProps, Theme, styled } from '@mui/material';
import Header from './Header';

const SectionWrapper = styled(Box)(() => ({
  padding: 0,
}));

interface SectionProps {
  overline?: string;
  title: string;
  children?: ReactNode;
  actions?: ReactNode;
  sx?: SxProps<Theme>;
}

export default function Section(props: SectionProps) {
  const { actions, children, overline, sx, title } = props;

  return (
    <SectionWrapper component="section" sx={ sx }>
      <Header
        actions={ actions }
        overline= { overline }
        title={ title }
      />
      { children }
    </SectionWrapper>
  );
}
