import api, { GenericListResponse } from '../../services/api';
import { store } from '../../store';
import { Currency } from '../currencies/currenciesSlice';

import { Subscription, SubscriptionZone } from './subscriptionsSlice';

interface SubscriptionData {
  plazoDeSuscripcion: number;
  thirdDescription: string;
  suscriptionPrice: string;
  precioMaximo: string;
  precioMinimo: string;
  cantidadDeZonas: number;
  denominacion: string;
  firstDescription: string;
  secondDescription: string;
  moneda: number;
  id: number;
}

export async function fetchSubscriptions() {
  const response = await api<GenericListResponse<SubscriptionData>>('GET', 'forms/SUSC1643197599/instances', {
    search: 'stateSuscrip=eq:1',
  });

  if (!response || !response.data || !Array.isArray(response.data.instances)) {
    return [];
  }

  return response.data.instances.map(s => parseSubscription(s));
}
export interface MySubscriptionData {
  acquiredProduct: string;
  acquirerName: string;
  availablePublics: number;
  cdInstanceOwner: string;
  cdUserLastUpdate: string;
  cdUserStore: string;
  dtLastUpdate: string;
  dtStore: string;
  estado: number;
  fechaDeAdquisicion: string;
  id: number;
  inmobiliaria: null;
  packPrice: string,
  paqueteDePublicacion: string;
  paymentGateway: string;
  plazosDeSuscripcion: number;
  precio: string;
  productPrice: string;
  pgwSubscriptionId: string;
  subscriptionEndDate: string;
  subscriptionPrice: string;
  suscripcionAOportuni: string;
  tipoDeAdquisicion: string;
  tipoDeProducto: number;
  tipoDeUsuario: number;
  totalPublications: number;
  usuarioPlotland: string;
  zonasSuscriptasIter: {
    'zonasSuscriptasIter/locationId': string;
    'zonasSuscriptasIter/selectedZone': string;
    'zonasSuscriptasIter/locationLongitude': string;
    'zonasSuscriptasIter/locationName': string;
    'zonasSuscriptasIter/locationLatitude': string;
    _nuSequence: number;
  }[];
};

async function fetchMySubscriptions(): Promise<MySubscriptionData[]> {
  const state = store.getState();

  let search = 'estado=eq:2,';

  if (state.users.type == 'realstate') {
    search += `tipoDeUsuario=eq:1,inmobiliaria=eq:${ state.users.current?.realEstater }`;
  } else {
    search += `tipoDeUsuario=eq:2,usuarioPlotland=eq:${ state.users.id }`;
  }

  const response = await api<GenericListResponse<MySubscriptionData>>('GET', 'forms/ADQU1643283960/instances', {
    search,
  });

  if (!response || !response.data || !Array.isArray(response.data.instances)) {
    return [];
  }

  return response.data.instances;
}

export interface MyPaymentsData {
  taxes: null;
  totalAmountPaid: string;
  orderNoOfPymGwy: null;
  cdUserLastUpdate: string;
  paymentType: string;
  dtLastUpdate: string;
  cdUserStore: string;
  paymentId: number;
  dtStore: string;
  subcriptionId: string;
  currency: string;
  invoiceNo: null;
  payerEmail: string;
  orderNo: null;
  payerIdOfPymGwy: string;
  pgwPaymentId: string;
  cdInstanceOwner: null;
  subscriberId: string;
  productAcquisition: string;
  updateTime: string;
  payerEmailOfPymGwy: string;
  pymGwyStDetail: null;
  pgwStatus: string;
  transactionFee: string;
  createTime: string;
  subcriptionIdPyGwy: string;
  inmoPayerId: null;
  paymentGateway: string;
  status: string;
}

async function fetchMyPayments(ids?: number[]): Promise<MyPaymentsData[]> {
  const state = store.getState();

  let searchParams = '';
  if (state.users.type === 'realstate') {
    searchParams += `inmoPayerId=eq:${ state.users.current?.realEstater }`;
  } else {
    searchParams += `usuarioPlotland=eq:${ state.users.id }`;
  }

  if (ids && ids.length > 0) {
    searchParams += `,productAcquisition=in:${ ids.join('|') }`;
  }

  const response = await api<GenericListResponse<MyPaymentsData>>('GET', 'forms/PAYM1660760322/instances', {
    search: searchParams,
    'per-page': 1000,
  });

  if (!response || !response.data || !Array.isArray(response.data.instances)) {
    return [];
  }

  return response.data.instances;
}

function parseSubscription(instance: SubscriptionData): Subscription {
  const description: string[] = [];

  if (instance.firstDescription) {
    description.push(instance.firstDescription);
  }

  if (instance.secondDescription) {
    description.push(instance.secondDescription);
  }

  if (instance.thirdDescription) {
    description.push(instance.thirdDescription);
  }

  return {
    id: instance.id,

    name: instance.denominacion,
    description,

    term: instance.plazoDeSuscripcion,
    zones: instance.cantidadDeZonas,

    currency: instance.moneda in Currency ? Currency[instance.moneda] : 'USD',
    price: parseFloat(instance.suscriptionPrice as string),
  };
}

export interface CreateSubscriptionOptions {
  subscription: Subscription;
  zones: SubscriptionZone[];
}

interface CreateSubscriptionData {
  cdProcess: string;
  cdVersion: number;
  idApplication: string;
  fieldsActivity: {
    cdInstanceOwner: string;
    tipoDeUsuario: number;
    usuarioPlotland?: string;
    inmobiliaria?: string;
    precio: string;
    tipoDeProducto: number;
    suscripcionAOportuni: string;
    acquisitionState: number;
    lsZonas: {
      '_nuSequence': number;
      'lsZonas/locationName': string;
      'lsZonas/locationId': string;
      'lsZonas/locationLatitude': string;
      'lsZonas/locationLongitude': string;
    }[];
  };
}

interface CreateSubscriptionResponse {
  cdCase: string,
  cdState: string,
  cdNextActivity: number,
}

async function createSubscription({ subscription, zones }: CreateSubscriptionOptions) {
  const state = store.getState();
  const user = state.users;

  const payload: CreateSubscriptionData = {
    cdProcess: 'bc7e798ceb5c4fc09a181e6f648bae77',
    cdVersion: 1,
    idApplication: 'PLOTLAND',
    fieldsActivity: {
      cdInstanceOwner: `${ user.current?.deyelUser }`,
      tipoDeUsuario: user.type === 'realstate' ? 1 : 2,
      usuarioPlotland: user.type === 'individual' ? `${ user.id }` : undefined,
      inmobiliaria: user.type === 'realstate' ? `${ user.current?.realEstater }` : undefined,
      precio: `${ subscription.price }`,
      tipoDeProducto: 2,
      suscripcionAOportuni: `${ subscription.id }`,
      acquisitionState: 1,
      lsZonas: zones.map((zone, idx) => ({
        _nuSequence: idx,
        'lsZonas/locationName': zone.label,
        'lsZonas/locationId': zone.id,
        'lsZonas/locationLatitude': zone.location.lat.toString(),
        'lsZonas/locationLongitude': zone.location.lng.toString(),
      })),
    },
  };

  const response = await api<CreateSubscriptionResponse>('POST', `cases`, payload);

  return {
    data: response.data,
  };
}

export interface CancelSubscriptionOptions {
  subscriptionID: string;
  paymentGateway: number;
}

async function cancelSubscription({ paymentGateway, subscriptionID }: CancelSubscriptionOptions) {
  const response = await api('PUT', 'rules/pgwCancelSubscription-1/execute', {
    paymentGateway,
    pSubscriptionID: subscriptionID,
  });

  console.debug(response);

  return response;
}

const packageAPI = {
  fetchSubscriptions,
  fetchMySubscriptions,
  fetchMyPayments,
  createSubscription,
  cancelSubscription,
};

export default packageAPI;
