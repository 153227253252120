import { Box, Container, styled, SxProps, Theme, Typography } from '@mui/material';
import { ReactNode } from 'react';

const TitleWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const ActionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: '1rem',

  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
  },
}));

interface HeaderProps {
  overline?: string;
  title: string;
  actions?: ReactNode;
  sx?: SxProps<Theme>;
}

export default function Header(props: HeaderProps) {
  const { overline, title, actions, sx } = props;

  return (
    <Container component="header" sx={ sx }>
      {
        overline
          ? <Typography variant="overline">{ overline }</Typography>
          : null
      }
      <TitleWrapper>
        <Typography variant="h3">{ title }</Typography>
        {
          actions
            ? <ActionsWrapper>{ actions }</ActionsWrapper>
            : null
        }
      </TitleWrapper>
    </Container>
  );
}
