import api from '../../services/api';
import { store } from '../../store';

interface CreateQueryData {
  cdProcess: string;

  cdVersion: number; // Fijo, 1

  fieldsActivity: {
      consultDate: string; // Timestamp en segundos
      consultOriginant: number; // 1, Particular; 2, Inmobiliaria; 3, no registrado,
      plotlandUser?: string; // Id usuario si particular
      realter?: string; // Id usuario si inmobiliaria
      name: string;
      lastName: string;
      email: string;
      phoneNumber: string;
      consultDetail: string; // Max length: 2000
  }

  idApplication: string; // Fijo, "PLOTLAND",
}

export interface CreateQueryOptions {
  email: string;

  lastname: string;

  message: string; // Max length: 2000

  name: string;

  phone: string; // Max length: 2000
}

export interface CreateQueryResponse {
  cdCase: string;

  cdNextActivity: number;

  cdState: string;
}

async function createQuery(options: CreateQueryOptions) {
  const state = store.getState();
  const user = state.users;

  const payload: CreateQueryData = {
    cdProcess: 'bb3bfa96a41a4b8e836f2a512e172041',
    cdVersion: 1,
    idApplication: 'PLOTLAND',
    fieldsActivity: {
      consultDate: Date.now().toString(),
      consultOriginant: user.authed
        ? user.type === 'individual' ? 1 : 2
        : 3,
      plotlandUser: user && user.type === 'individual' ? `${ user.id }` : undefined,
      realter: user && user.type === 'realstate' ? `${ user.current?.realEstater }` : undefined,
      name: options.name,
      lastName: options.lastname,
      email: options.email,
      phoneNumber: options.phone,
      consultDetail: options.message,
    },
  };

  const response = await api<CreateQueryResponse>('POST', `cases`, payload);

  return response.status === 200 || response.status === 201;
}

export interface Conversation {
  cdUserLastUpdate: string;
  cdUserStore: string;

  consultant: string;
  consultantType: number;
  consultantUser: string;

  dtLastUpdate: string;
  dtStore: string;
  id: number;

  publisher: string;
  publisherType: number;
  publisherUser: string;

  consultedPlot: string;
  consultedPlotTitle?: string;

}

interface GetConversationsResponse {
  conversations: {
    instances: Conversation[]
  }
}

async function getConversations() {
  const response = await api<GetConversationsResponse>('PUT', `rules/plotGetConversations-1/execute`, {
    searchParams: {
      search: null,
      sort: '-dtLastUpdate',
      paging: {
        'page-number': '1',
        'per-page': '50',
      },
    },
  });

  return response.data.conversations.instances;
}

export interface Message {
  cdUserLastUpdate: string;

  cdUserStore: string;

  dtLastUpdate: string;

  dtStore: string;

  id: number;

  message: string;

  messageDate: string;

  messageState: number;

  receiver: string;

  receiverPlotUser: string;

  receiverType: number;

  relatedConversation: string;

  sender: string;

  senderPlotUser: string;

  senderType: number;
}

interface GetMessagesResponse {
  messages: {
    instances: Message[];
  };
}

async function getMessages(conversationId: number) {
  const response = await api<GetMessagesResponse>('PUT', `rules/plotGetMessages-1/execute`, {
    conversationId,
    searchParams: {
      search: null,
      description: null,
      paging: {
        'page-number': '1',
        'per-page': '50',
      },
      sort: 'dtStore',
    },
  });

  return response.data.messages.instances;
}

export interface SendMessageOptions {
  conversationId?: number;

  lotId: number;

  message: string;
}

interface SendMessageResponse {
  messageSended: boolean;
}

async function sendMessage(options: SendMessageOptions) {
  const state = store.getState();
  const user = state.users;

  const response = await api<SendMessageResponse>('PUT', `rules/plotSendMessage-1/execute`, {
    conversationId: options.conversationId,
    publishedLotId: options.lotId,
    userSender: user.current?.deyelUser,
    issuingDate: new Date().valueOf().toString(),
    message: options.message,
  });

  return response.data;
}

const commsAPI = {
  createQuery,
  getConversations,
  getMessages,
  sendMessage,
};

export default commsAPI;
