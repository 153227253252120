import { buttonClasses } from '@mui/material';

export default function Button() {
  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 400,
          lineHeight: '1.25rem',
          padding: '.625rem .9375rem',
          borderRadius: '30px',
          textTransform: 'none',

          [`&.${ buttonClasses.sizeLarge }`]: {
            fontSize: '1.25rem',
            fontWeight: 400,
            lineHeight: '1.5625rem',
            padding: '.625rem 3.375rem',
            borderRadius: '30px',
          },
        },
      },
    },
  };
}
