import { cloneElement } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { store } from '../store';
import { openSidebar } from '../features/ui/UISlice';
import { getUserName, isAuthed, logout } from '../features/users/usersSlice';

import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  useTheme } from '@mui/material';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import { ExitToApp, ExpandLess, ExpandMore, Menu as MenuIcon } from '@mui/icons-material';
import { Icon } from '@iconify/react';

import Logo from './Logo';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';

const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  height: theme.sizes.navbar.height,
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    width: theme.breakpoints.values.lg,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const LogoStyled = styled(Link)(({ theme }) => ({
  flexGrow: 1,

  [theme.breakpoints.up('sm')]: {
    flexGrow: 1,
  },
}));

const NavbarItemWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  marginLeft: '1rem',
  marginRight: '1rem',

  a: {
    color: theme.palette.text.primary,
    textDecoration: 'none',

    ':hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'underline',
    },
  },
}));

const NavbarItem = styled(Typography)(() => ({
  lineHeight: '1.25rem',
  fontWeight: 600,
}));

interface ElevationScrollProps {
  children: React.ReactElement;
}

function ElevationScroll(props: ElevationScrollProps) {
  const { children } = props;

  // Monitoreamos el scroll
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });

  // Modificamos las props del children dependiendo del scroll
  return cloneElement(children, {
    elevation: trigger ? 1 : 0,
    color: trigger ? 'inherit' : 'transparent',
  });
}

export default function Navbar() {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const popupState = usePopupState({ variant: 'popover', popupId: 'userMenu' });
  const { t } = useTranslation();

  const authed = useSelector(isAuthed);
  const username = useSelector(getUserName);

  const handleLoginClick = () => {
    navigate('/login');
    popupState.close();
  };

  const handlePublishClick = () => {
    navigate('/publish');
    popupState.close();
  };

  const handleProfileClick = () => {
    navigate('/profile');
    popupState.close();
  };

  const handleMyLotsClick = () => {
    navigate('/profile/lots');
    popupState.close();
  };

  const handleMyOpportunitiesClick = () => {
    navigate('/profile/opportunities');
    popupState.close();
  };

  const handleMySubscriptionsClick = () => {
    navigate('/profile/subscriptions');
    popupState.close();
  };

  const handleMyMessagesClick = () => {
    navigate('/profile/messages');
    popupState.close();
  };

  const handleLogout = () => {
    store.dispatch(logout());
    navigate('/');
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ElevationScroll>
        <AppBar position="fixed" sx={{ transition: 'background-color .5s ease-out, box-shadow .5s ease-out' }}>
          <ToolbarStyled>
            <LogoStyled to="/"><Logo /></LogoStyled>
            {
              desktop
                ? <>
                    <NavbarItemWrapper>
                      <NavbarItem>
                        <Link to="/lots">{ t('Lotes en venta') }</Link>
                      </NavbarItem>
                      <NavbarItem>
                        <Link to="/opportunities">{ t('Oportunidades') }</Link>
                      </NavbarItem>
                    </NavbarItemWrapper>
                    <Button
                      variant="outlined"
                      startIcon={ <Icon icon="et:map" height="1.125rem" /> }
                      sx={{ paddingLeft: '1rem', mr: '1rem' }}
                      onClick={ handlePublishClick }
                    >
                      { t('PUBLICAR UN LOTE') }
                    </Button>
                    {
                      authed
                        ? <Box>
                            <Button
                              sx={{
                                padding: '.25rem .5rem',
                                color: theme.palette.text.primary,
                              }}
                              startIcon={
                                <Icon
                                  icon="carbon:user-avatar-filled"
                                  color={ theme.palette.primary.main }
                                  height={ 36 }
                                  width={ 36 }
                                />
                              }
                              endIcon={
                                popupState.isOpen
                                  ? <ExpandLess fontSize="large" />
                                  : <ExpandMore fontSize="large" />
                              }
                              { ...bindTrigger(popupState) }
                            >
                              { username }
                            </Button>
                            <Menu
                              {...bindMenu(popupState)}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            >
                              <MenuItem onClick={ handleProfileClick }>{ t('Mi Perfil') }</MenuItem>
                              <MenuItem onClick={ handleMyLotsClick }>{ t('Mis Lotes') }</MenuItem>
                              <MenuItem onClick={ handleMyOpportunitiesClick }>{ t('Mis Oportunidades') }</MenuItem>
                              <MenuItem onClick={ handleMySubscriptionsClick }>{ t('Mis Suscripciones') }</MenuItem>
                              <MenuItem onClick={ handleMyMessagesClick }>{ t('Mis Mensajes') }</MenuItem>
                              <MenuItem onClick={ handleLogout }>{ t('Cerrar Sesión') }</MenuItem>
                            </Menu>
                          </Box>
                        : <Button
                            variant="contained"
                            startIcon={ <ExitToApp height="1.125rem" /> }
                            sx={{ paddingLeft: '1.125rem', mr: '1rem' }}
                            onClick={ handleLoginClick }
                          >
                            { t('INGRESAR') }
                          </Button>
                    }
                    <LanguageSelector />
                    <IconButton
                      size="large"
                      edge="end"
                      color="inherit"
                      aria-label="menu"
                      sx={{ ml: 2 }}
                      onClick={ () => store.dispatch(openSidebar()) }
                    >
                      <MenuIcon />
                    </IconButton>
                  </>
                : <IconButton
                    size="large"
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    sx={{ ml: 2 }}
                    onClick={ () => store.dispatch(openSidebar()) }
                  >
                    <MenuIcon />
                  </IconButton>
            }
          </ToolbarStyled>
        </AppBar>
      </ElevationScroll>
    </Box>
  );
}
