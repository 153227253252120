import { Suspense, useState } from 'react';
import Loader from './components/Loader';
import Router from './routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';

export default function App() {
  const [loading] = useState(false);

  return (
    <ThemeConfig>
      <GlobalStyles />
      <Suspense fallback="loading">
        {
          loading
            ? <Loader loading={ true } />
            : <Router />
        }
      </Suspense>
    </ThemeConfig>
  );
}
