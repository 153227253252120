import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationES from '../i18n/es/translation.json';
import translationEN from '../i18n/en/translation.json';

const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
};

export const languages = ['es', 'en'];

let selectedLang = localStorage.getItem('lang');
if (!selectedLang) {
  selectedLang = 'es';
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'es',
    lng: selectedLang,
    debug: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
