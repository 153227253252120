import { Box, Container, styled, Typography, useTheme } from '@mui/material';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import AboutUs from '../../components/AboutUs';

import PremiumLandscape from '../../assets/images/premium-landscape.png';
import PremiumDiamond from '../../assets/premium-diamond.png';

const ContentWrapper = styled(Box)(() => ({
  width: '100%',
  backgroundImage: `url(${ PremiumLandscape })`,
  backgroundSize: 'cover',
}));

const PromoText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: '4rem',
  padding: '1rem',

  [theme.breakpoints.up('sm')]: {
    maxWidth: '1200px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15.5rem',
    padding: 0,
  },
}));

const CountersWrapper = styled(Container)(({ theme }) => ({
  padding: '1rem',

  [theme.breakpoints.up('sm')]: {
    padding: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15.5rem',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const LotCounter = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  paddingLeft: '2.875rem',
  paddingRight: '2.875rem',
  paddingTop: '4.825rem',
  marginRight: '3rem',
  marginBottom: '2rem',

  [theme.breakpoints.up('sm')]: {
    marginBotton: 0,
  },
}));

const OpportunitiesCounter = styled(Box)(() => ({
  backgroundColor: '#fff',
  borderRadius: '10px',
  boxShadow: '0px 0px 20px 15px rgba(54, 73, 102, 0.05)',
  paddingTop: '1.25rem',
  paddingLeft: '2.875rem',
  paddingRight: '2.875rem',
  paddingBottom: '2.5rem',
}));

const Counter = styled(Typography)(() => ({
  fontFamily: 'Roboto',
  fontSize: '7.625rem',
  fontWeight: 700,
  letterSpacing: '-0.05rem',
  lineHeight: '7.625rem',
}));

export default function PremiumPromo() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <PromoText>
        <Typography variant="overline">{ t('Usuarios Premium') }</Typography>
        <Typography variant="h4" sx={{ width: '725px', maxWidth: '100%', mt: '1.5rem' }}>
          { t(`Suscribite, tenemos diferentes planes para vos, ya sea que quieras realizar un único negocio
          o sea tu modo de vida.`) }
          &nbsp;
          <span style={{ color: theme.palette.primary.light, fontWeight: 600 }}>
            { t('Con nuestros planes accederás a un mundo de oportunidades.') }
          </span>
        </Typography>
      </PromoText>
      <CountersWrapper>
        <LotCounter>
          <Counter>200</Counter>
          <Typography sx={{ fontSize: '1.75rem', mb: '2.5rem' }}>
            { t('Lotes') }
          </Typography>
          <Icon icon="cil:arrow-right" width="34px" />
        </LotCounter>
        <OpportunitiesCounter>
          <img src={ PremiumDiamond } />
          <Typography sx={{ fontSize: '1.125rem', color: theme.palette.primary.light, mt: '1.25rem' }}>
            { t('Para clientes Premium') }
          </Typography>
          <Counter sx= {{ color: theme.palette.primary.light }}>500</Counter>
          <Typography sx={{ fontSize: '1.75rem', mb: '2.5rem' }}>
            { t('Oportunidades') }
          </Typography>
          <Box sx={{ width: '100%', textAlign: 'left' }}>
            <Icon icon="cil:arrow-right" width="34px" />
          </Box>
        </OpportunitiesCounter>
      </CountersWrapper>
      <AboutUs />
    </ContentWrapper>
  );
}
