import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { store } from '../store';
import { getUserById, User, login } from '../features/users/usersSlice';

import { Box, styled } from '@mui/material';
import colors from '../theme/colors';

import config from '../config';
import { useTranslation } from 'react-i18next';

const ContentWrapper = styled(Box)(() => ({
  marginTop: '2rem',
  width: '100%',
}));

const IframeStyled = styled('iframe')(() => ({
  width: '100%',
  border: 'none',
  height: '625px',
}));

export default function Register() {
  const { t, i18n } = useTranslation();

  const handleMessage = async (event: MessageEvent) => {
    if (event.origin !== config.api.url) {
      return;
    }

    if (event.data.type !== 'register_ok') {
      console.error(event.data);
      return;
    }

    const userData: Required<User> = {
      id: event.data.idPlotlandUser,
      type: event.data.userProfileCode === 1 ? 'individual' : 'realstate',
      name: event.data.fullname ? event.data.fullname : 'S/D',
    };

    localStorage.setItem('plotland_private_token', event.data.access_token);
    localStorage.setItem('plotland_private_refresh', event.data.refresh_token);

    // Recuperar datos usuario
    await store.dispatch(getUserById(userData.id)).unwrap();

    // Cargamos la información de login
    await store.dispatch(login(userData));
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <ContentWrapper>
      <IframeStyled
        src={`${ config.api.url }/SLPage?idPage=e200e8de-d691-466d-97e9-330563e4bbd0&lang=${ i18n.language }`}
      />
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Link
          to="/login"
          style={{
            textDecoration: 'underline',
            letterSpacing: '0.02rem',
            color: colors.brown,
          }}
        >
          { t('¿Ya tenés una cuenta? Ingresar') }
        </Link>
      </Box>
    </ContentWrapper>
  );
}
