import { AxiosInstance } from 'axios';
import MockAdapter from 'axios-mock-adapter';
import config from '../config';

import plotGetMyLands from '../mocks/plotGetMyLands.json';

const mock = (axios: AxiosInstance) => {
  if (config.api.mocks !== true) {
    return axios;
  }

  const adapter = new MockAdapter(axios, {
    delayResponse: 1000,
    onNoMatch: 'passthrough',
  });

  adapter
    .onPut(`${ config.api.url }/v1.0/rules/plotUserStatusOnPublication-1/execute`)
    .reply(config => {
      const response = {
        packAcquisition: false,
        upgradePackage: true,
        publicationProcess: false,

        checkout: true,
        publicationCaseNumber: '',
        acquisitionCaseNumber: '',

        acquiredProduct: 5,
      };

      console.debug(`Mocked: ${ config.url }`, response);

      return [200, response];
    });

  adapter
    .onPut(`${ config.api.url }/v1.0/rules/plotGetMyLands-1/execute`)
    .reply(config => {
      console.debug(`Mocked: ${ config.url }`, plotGetMyLands);

      return [200, plotGetMyLands];
    });
};

export default mock;
