/* eslint-disable max-len */
import { Shadows } from '@mui/material/styles/shadows';

const shadows: Shadows = [
  'none',
  '0px 0px 20px 5px rgba(54, 73, 102, 0.05)',
  '0px 5px 10px rgba(181, 180, 180, 0.1)',
  '0px 0px 30px 5px rgba(54, 73, 102, 0.05)',
  '0px 0px 20px 15px rgba(54, 73, 102, 0.05)',
  '-4px 0px 30px 20px rgba(0, 0, 0, 0.1)',
  '-1px -1px 0 rgba(0, 0, 0, .15), 0 -1px 0 rgba(0, 0, 0, .15), 1px -1px 0 rgba(0, 0, 0, .15), 1px 0 0 rgba(0, 0, 0, .15), 1px 1px 0 rgba(0, 0, 0, .15), 0 1px 0 rgba(0, 0, 0, .15), -1px 1px 0 rgba(0, 0, 0, .15), -1px 0 0 rgba(0, 0, 0, .15)',
  '0px 2px 5px 0px rgb(0 0 0 / 10%)',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
];

export default shadows;
