import api, { GenericListResponse } from '../../services/api';
import { store } from '../../store';
import { Currency } from '../currencies/currenciesSlice';
import { Package } from './packagesSlice';

interface PackageData {
  id: number;
  statePack: number;
  plazoDeSuscripcion: number;
  packCurrency: number;
  packPrice: string;
  packDescription: string;
  denominacion: string;
  cPublicaciones: number;
}

export async function fetchPackages() {
  const response = await api<GenericListResponse<PackageData>>('GET', 'forms/PAQU1643201834/instances', {
    search: 'statePack=eq:1',
  });

  if (!response || !response.data || !Array.isArray(response.data.instances)) {
    return [];
  }

  return response.data.instances.map(p => parsePackage(p));
}

function parsePackage(instance: PackageData): Package {
  return {
    id: instance.id,
    name: instance.denominacion,
    description: instance.packDescription,
    term: instance.plazoDeSuscripcion,
    posts: instance.cPublicaciones,
    currency: instance.packCurrency in Currency ? Currency[instance.packCurrency] : 'USD',
    price: parseFloat(instance.packPrice),
  };
}

interface CreatePackageData {
  cdProcess: string;
  cdVersion: number;
  idApplication: string;
  fieldsActivity: {
    cdInstanceOwner: string;
    tipoDeUsuario: number;
    usuarioPlotland?: string;
    inmobiliaria?: string;
    packPrice: string;
    tipoDeProducto: number;
    paqueteDePublicacion: string;
    acquisitionState: number;
  };
}

interface CreatePackageResponse {
  cdCase: string;
  cdState: string;
  cdNextActivity: string;
}

async function createPackage(packageData: Package) {
  const state = store.getState();
  const user = state.users;

  const payload: CreatePackageData = {
    cdProcess: 'bc7e798ceb5c4fc09a181e6f648bae77',
    cdVersion: 1,
    idApplication: 'PLOTLAND',
    fieldsActivity: {
      cdInstanceOwner: `${ user.current?.deyelUser }`,
      tipoDeUsuario: user.type === 'realstate' ? 1 : 2,
      usuarioPlotland: user.type === 'individual' ? `${ user.id }` : undefined,
      inmobiliaria: user.type === 'realstate' ? `${ user.current?.realEstater }` : undefined,
      packPrice: `${ packageData.price }`,
      tipoDeProducto: 1, // Paquete de publicaciones
      paqueteDePublicacion: `${ packageData.id }`,
      acquisitionState: 1,
    },
  };

  const response = await api<CreatePackageResponse>('POST', `cases`, payload);

  return {
    data: response.data,
  };
}

const packageAPI = {
  fetchPackages,
  createPackage,
};

export default packageAPI;
