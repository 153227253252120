import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import optionsAPI from './optionsAPI';

interface GenericListItem {
  id: number;
  name: string;
}

export type GenericList = GenericListItem[];

export interface Options {
  minPrice: number;
  maxPrice: number;
  landSuitabilities: GenericList;
  landTypes: GenericList;
  currencies: GenericList;
  units: GenericList;
  userTypes: GenericList;
  publicationStatus: GenericList;
  paymentGateways: GenericList;
  paymentStatus: GenericList;
  mostConsultedAreas: {
    id: number;
    name: string;
    image: string;
  }[];
};

export interface OptionsState {
  data: Options;
  status: 'booting' | 'loaded' | 'loading' | 'failed';
  error?: { message: string; name: string; } | false;
};

export const initialState: OptionsState = {
  status: 'booting',
  data: {
    minPrice: 1000,
    maxPrice: 3600000,
    landSuitabilities: [],
    landTypes: [],
    mostConsultedAreas: [],
    currencies: [],
    units: [],
    userTypes: [],
    paymentGateways: [],
    publicationStatus: [],
    paymentStatus: [],
  },
};

export const fetchOptions = createAsyncThunk(
  'options/fetch',
  async () => {
    const response = await optionsAPI.fetchOptions();
    return response;
  },
);

export const changeLang = createAsyncThunk(
  'options/lang',
  async (language: string) => {
    const response = await optionsAPI.changeLang(language);
    return response;
  },
);

export const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOptions.pending.type]: state => {
      state.status = 'loading';
      state.error = false;
    },
    [fetchOptions.fulfilled.type]: (state, action) => {
      state.status = 'loaded';
      state.data = action.payload;
    },
    [fetchOptions.rejected.type]: (state, action) => {
      state.status = 'failed';
      state.error = {
        name: action.error.name,
        message: action.error.message,
      };
    },
  },
});

export const selectOptions = (state: RootState) => state.options;

export const areOptionsLoaded = (state: RootState) => state.options.status === 'loaded';

export const getMostConsultedAreas = (state: RootState) => state.options.data.mostConsultedAreas
  ? state.options.data.mostConsultedAreas
  : [];
export const getSuitabilities = (state: RootState) => state.options.data.landSuitabilities
  ? state.options.data.landSuitabilities
  : [];
export const getLandTypes = (state: RootState) => state.options.data.landTypes
  ? state.options.data.landTypes
  : [];
export const getUserTypes = (state: RootState) => state.options.data.userTypes
  ? state.options.data.userTypes
  : [];
export const getCurrencies = (state: RootState) => state.options.data.currencies
  ? state.options.data.currencies
  : [];
export const getUnits = (state: RootState) => state.options.data.units
  ? state.options.data.units
  : [];
export const getPaymentGateways = (state: RootState) => state.options.data.paymentGateways
  ? state.options.data.paymentGateways
  : [];
export const getPublicationStatus = (state: RootState) => state.options.data.publicationStatus
  ? state.options.data.publicationStatus
  : [];
export const getPaymentStatus = (state: RootState) => state.options.data.paymentStatus
  ? state.options.data.paymentStatus
  : [];
export const getPriceRangeMin = (state: RootState) => state.options.data.minPrice;
export const getPriceRangeMax = (state: RootState) => state.options.data.maxPrice;

export default optionsSlice.reducer;
