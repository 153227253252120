import TimeAgo from 'react-timeago';
import { Icon } from '@iconify/react/dist/iconify.js';
import {
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  styled,
  Typography,
  useTheme } from '@mui/material';
import { Conversation as ConversationType } from '../../features/comms/commsAPI';
import { formatter } from '../../services/timeago';
import { useTranslation } from 'react-i18next';

const ConversationWrapper = styled(ListItem)(() => ({
  alignItems: 'flex-start',
  padding: 0,
}));

interface ConversationProps {
  value: ConversationType;
  selected?: boolean;
  onClick: (id: number) => void;
}

export default function Conversation(props: ConversationProps) {
  const { value: conversation, selected = false, onClick } = props;

  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const handleClick = () => {
    onClick(conversation.id);
  };

  return (
    <ConversationWrapper >
      <ListItemButton selected={ selected } onClick={ handleClick }>
        <ListItemAvatar>
          <Icon
            icon="carbon:user-avatar-filled"
            color={ theme.palette.primary.main }
            height={ 36 }
            width={ 36 }
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            conversation.consultedPlotTitle
              ? conversation.consultedPlotTitle
              : `${ t('Lote n°') } ${ conversation.consultedPlot }`
          }
          secondary={
            <>
              <Typography sx={{ display: 'inline', fontSize: '.8rem' }} component="span" color="text.primary">
                { conversation.consultant }
                &nbsp;
                <small><em>
                  <TimeAgo
                    date={ +conversation.dtLastUpdate }
                    formatter={ i18n.language == 'es' ? formatter : undefined }
                  />
                </em></small>
              </Typography>
            </>
          }
        />
      </ListItemButton>
    </ConversationWrapper>
  );
}
