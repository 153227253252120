import { Grid, SxProps, Theme } from '@mui/material';

import { Lot } from '../features/lots/lotsSlice';
import LotCard from './LotCard';

interface LotGridProps {
  lots: Lot[];
  cols?: number;
  sx?: SxProps<Theme>;
}

export default function LotGrid(props: LotGridProps) {
  const { cols = 3, lots, sx } = props;

  return (
    <Grid container spacing={ 2 } sx={ sx }>
      {
        lots.map(lot => (
          <Grid item sm={ 12 / cols } key={ lot.id }>
            <LotCard lot={ lot } />
          </Grid>
        ))
      }
    </Grid>
  );
}
