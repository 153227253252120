import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import jwtDecode from 'jwt-decode';

import config from '../config';
import { logout } from '../features/users/usersSlice';
import { store } from '../store';

import mockAdapter from './mocks';

// Sólo para operaciones que no usan token
export const axiosPublic = axios.create({
  withCredentials: false,
});

// Operaciones que requieren token
const axiosPrivate = axios.create({
  withCredentials: false,
});
mockAdapter(axiosPrivate);

export interface GenericListResponse<T = Record<string, unknown>> {
  instances?: T[];
  paging: {
    'page-number': number;
    'per-page': number;
    'pages': number;
    'total-items': number;
    'link-next-page?': string;
    'link-previous-page?': string;
    'link-first-page?': string;
    'link-last-page?': string;
  },
  links: {
    rel: string;
    label: string;
    href: string;
  }[];
}

const query = async <T>(method: Method, route: string, data?: Record<string, any>): Promise<AxiosResponse<T>> => {
  try {
    const options: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
      method,
    };

    // Generamos el URL inicial con la ubicación de la api y los parámetros
    const url = new URL(`${ config.api.url }/v1.0/${ route }`);

    if (data) {
      // Si el método no es PUT, POST o PATCH incluimos los datos en la URL
      if (!['PUT', 'POST', 'PATCH'].includes(method)) {
        Object.entries(data).forEach(entry => {
          let [key, value] = entry;

          if (typeof value === 'boolean') {
            // Convertimos los tipos booleanos a 0 o 1 respectivamente
            value = value === true ? '1' : '0';
          } else if (typeof value === 'object') {
            value = JSON.stringify(value);
          }

          // Agregamos el parámetro a la URL
          url.searchParams.set(key, value);
        });

      // Si vamos a hacer un PUT, POST o PATCH agregamos los datos al body
      } else {
        // Agregamos los datos a la petición
        options['data'] = data;
      }
    }

    // Calculamos la URL final
    options['url'] = url.toString();

    // Hacemos la petición
    const response = await axiosPrivate(options);

    return response;
  } catch (error) {
    // Si el error es de Axios lo procesamos un poco más
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 401) {
        throw error;
      }

      // Si hay acceso denegado, borramos el token y redirigimos al home
      if (error.response.status === 403) {
        window.location.replace('/');
      }

      if (error.response.status === 500 &&
          error.response.data &&
          Array.isArray(error.response.data.errors) &&
          error.response.data.errors.length > 0) {
        throw new Error(error.response.data.errors[0].message);
      }

      console.info(error.response);
    } else {
      console.error(error);
    }

    // Por último, tiramos los errores desconocidos
    throw new Error(`[${ method.toString() } ${ route }: Error desconocido consultando la API`);
  }
};

function setTokenHeader(config: AxiosRequestConfig, token?: string) {
  if (!token) {
    return config;
  }

  if (config.headers) {
    config.headers['Authorization'] = `Bearer ${ token }`;
  } else {
    config.headers = {
      Authorization: `Bearer ${ token }`,
    };
  }

  return config;
}

axiosPrivate.interceptors.request.use(async config => {
  const userData = store?.getState().users;

  let currentToken;
  if (userData.authed) {
    currentToken = localStorage.getItem('plotland_private_token');
  } else {
    currentToken = localStorage.getItem('plotland_public_token');
  }

  // Si no hay token, login público
  if (!currentToken) {
    // Pedimos un token público
    const response = await getPublicToken();

    if (!response) {
      return false;
    }

    // Seteamos la cabecera y seguimos
    return setTokenHeader(config, response);
  }

  // Si hay token, seteamos y seguimos
  return setTokenHeader(config, currentToken);
});

axiosPrivate.interceptors.response.use(response => response, async err => {
  // Si el error no es de Axios o no es un error de token vencido o es un reintento dejamos seguir el error
  if (!axios.isAxiosError(err) || err.response?.status !== 401 || err.config.__isRetryRequest) {
    throw err;
  }

  const userData = store?.getState().users;

  // Pedimos un token público
  await getPublicToken();

  // Si el token actual es privado, renovamos
  if (userData.authed) {
    const response = await refreshPrivateToken();

    // Si falla la renovación, deslogueamos
    if (!response) {
      return store.dispatch(logout());
    }
  }

  err.config.__isRetryRequest = true;

  return axiosPrivate(err.config);
});

interface RefreshTokenResponse {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
}

interface RefreshToken {
  iss: string;
  company: string;
  sub: string;
  exp: number;
  nuSecHidden: string;
  idDataBaseSession: string;
}

export const refreshPrivateToken = async () => {
  const refreshToken = localStorage.getItem('plotland_private_refresh');

  if (!refreshToken) {
    return false;
  }

  const decodedToken = jwtDecode<RefreshToken>(refreshToken);

  const refreshUser = decodedToken.sub;

  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', refreshToken);

  const response = await axiosPublic.post<RefreshTokenResponse>(
    `${ config.api.url }/oauth/token`, params.toString(), {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      auth: {
        username: refreshUser,
        password: '',
      },
    });

  if (response.status !== 200) {
    return false;
  }

  if (!response.data || !response.data.access_token) {
    return false;
  }

  localStorage.setItem('plotland_private_token', response.data.access_token);
  localStorage.setItem('plotland_private_refresh', response.data.refresh_token);

  return response.data.access_token;
};

interface PublicTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
}

const getPublicToken = async () => {
  const params = new URLSearchParams();
  params.append('grant_type', 'client_credentials');

  const response = await axiosPublic.post<PublicTokenResponse>(`${ config.api.url }/oauth/token`, params, {
    auth: {
      username: config.api.user,
      password: config.api.pass,
    },
  });

  if (response.status !== 200) {
    return false;
  }

  if (!response.data || !response.data.access_token) {
    return false;
  }

  localStorage.setItem('plotland_public_token', response.data.access_token);

  return response.data.access_token;
};

export default query;
