import { Backdrop, CircularProgress, styled } from '@mui/material';
import Logo from './Logo';

const BackdropStyled = styled(Backdrop)(() => ({
  flexDirection: 'column',
  backgroundColor: '#ffffff',
}));

interface LoaderProps {
  loading: boolean;
}

export default function Loader(props: LoaderProps) {
  const { loading } = props;

  return (
    <BackdropStyled open={ loading }>
      <Logo sx={{ mb: '2rem' }} />
      <CircularProgress />
    </BackdropStyled>
  );
}
