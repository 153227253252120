import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { refreshPrivateToken } from '../../services/api';
import { RootState } from '../../store';
import { Subscription, SubscriptionZone } from '../subscriptions/subscriptionsSlice';
import usersAPI, { UserDeyel, UserFlow } from './usersAPI';

export interface User {
  id?: number;
  type?: UserType;
  name?: string;
}

export interface UserSubscription {
  subscription: Subscription;
  zone: SubscriptionZone[];
}

export interface UserState extends User {
  status: 'idle' | 'loading' | 'failed';
  authed: boolean;
  current?: UserDeyel;
  flow?: UserFlow;
}

export interface UserIndividual {
  name: string;
  lastname: string;
  country: string;
  phone_prefix: string;
  phone_number: string;
  id_type: string;
  id_number: string;
  cellphone_prefix: string;
  cellphone_number: string;
  email: string;
  password?: string;
}

export interface UserRealState {
  cuit: string;
  fancy_name: string;
  trade_name: string;
  tax_status: string;
  id_type: string;
  id_number: string;
  company_email: string;
  registration_college: string;
  registration_number: string;
  phone_prefix: string;
  phone_number: string;
  additional_phone_prefix: string;
  additional_phone_number: string;
  description: string;
  address: string;
  state: string;
  city: string;
  district: string;
  email: string;
  password?: string;
}

export type UserType = 'individual' | 'realstate';

export const initialState: UserState = {
  status: 'idle',
  authed: false,
};

export const getUserById = createAsyncThunk(
  'users/fetchById',
  async (id: number) => {
    const user = await usersAPI.fetchUserById(id);
    return user;
  },
);

export const getUserFlow = createAsyncThunk(
  'users/fetchFlow',
  async () => {
    const response = await usersAPI.fetchUserFlow();
    return response.data;
  },
);

export const updateUser = createAsyncThunk(
  'users/update',
  async (data: Partial<UserDeyel>) => {
    const response = await usersAPI.updateUser(data);
    return response.data;
  },
);

export const changePassword = createAsyncThunk(
  'users/password',
  async (data: { currentPassword: string, newPassword: string }, thunkAPI) => {
    const response = await usersAPI.changePassword(data);

    const refresh = await refreshPrivateToken();
    if (!refresh) {
      thunkAPI.dispatch(logout());
    }

    return response.data;
  },
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    logout() {
      localStorage.removeItem('plotland_private_token');
      localStorage.removeItem('plotland_private_refresh');

      return {
        status: 'idle',
        authed: false,
      };
    },
    login(state, action: PayloadAction<Required<User>>) {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.type = action.payload.type;
      state.authed = true;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUserById.pending, state => {
        state.status = 'loading';
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.status = 'idle';
        state.current = action.payload;
      })
      .addCase(getUserById.rejected, state => {
        state.status = 'failed';
      });

    builder
      .addCase(getUserFlow.pending, state => {
        state.status = 'loading';
      })
      .addCase(getUserFlow.fulfilled, (state, action) => {
        state.status = 'idle';
        state.flow = action.payload;
      })
      .addCase(getUserFlow.rejected, state => {
        state.status = 'failed';
      });

    builder
      .addCase(updateUser.fulfilled, (state, action) => {
        state.name = `${ action.meta.arg.nombre } ${ action.meta.arg.apellido }`;
      });
  },
});

export const isAuthed = (state: RootState) => state.users.authed;
export const getUser = (state: RootState) => state.users.current;
export const getFlow = (state: RootState) => state.users.flow;
export const getUserName = (state: RootState) => state.users.name;
export const getToken = (state: RootState) => {
  let currentToken;
  if (state.users.authed) {
    currentToken = localStorage.getItem('plotland_private_token');
  } else {
    currentToken = localStorage.getItem('plotland_public_token');
  }

  return currentToken;
};

export const getRefreshToken = () => {
  const refreshToken = localStorage.getItem('plotland_private_refresh');
  return refreshToken;
};

export const { logout, login } = usersSlice.actions;

export default usersSlice.reducer;
