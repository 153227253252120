import { Theme } from '@mui/material';

export default function TableCell(theme: Theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: theme.palette.grey[500],
          textAlign: 'center',
        },
        head: {
          fontWeight: 600,
        },
      },
    },
  };
}
