import { Close } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';

const Wrapper = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  position: 'relative',
}));

const ImageStyled = styled('img')(() => ({
  borderRadius: '20px',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
}));

const DeleteButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: '.5rem',
  right: '.5rem',
  color: '#333333',
  fill: '#333333',
  borderRadius: '50%',
  backgroundColor: 'rgba(255, 255, 255, .5)',
  padding: '2px',

  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, .75)',
  },
}));

interface ImageProps {
  src: string;
  onDelete?: () => void;
}

export default function Image(props: ImageProps) {
  const { onDelete, src } = props;

  const handleDelete = () => {
    if (!onDelete) {
      return;
    }

    onDelete();
  };

  return (
    <Wrapper>
      <ImageStyled src={ src } />
      {
        onDelete
          ? <DeleteButton disableRipple onClick={ handleDelete }>
              <Close fontSize="medium" />
            </DeleteButton>
          : null
      }
    </Wrapper>
  );
}
