import { SimplePaletteColorOptions } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import palette from './palette';

const primary = palette.primary as SimplePaletteColorOptions;

const typography: TypographyOptions = {
  fontFamily: 'Source Sans Pro, sans-serif',
  overline: {
    color: primary.light,
    fontSize: '1.375rem',
    fontWeight: 600,
    lineHeight: '1.375rem',
  },
  h3: {
    fontSize: '2.5rem',
    fontWeight: 700,
    lineHeight: '3.125rem',
  },
  h4: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '2.5rem',
  },
  h5: {
    fontSize: '2.375rem',
    fontWeight: 700,
    lineHeight: '3rem',
  },
  h6: {
    fontSize: '1.625rem',
    fontWeight: 600,
    marginBottom: '2rem',
  },
  body2: {
    fontSize: '1.5rem',
  },
};

export default typography;
