import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getErrorMessage } from '../../utils/errorHandler';

import Page from '../../components/Page';
import Register from '../../components/Register';

import { Alert, Box, Container, Grid, Paper, Step, StepLabel, Stepper, styled, Typography } from '@mui/material';

import { store } from '../../store';
import { CreateLot } from '../../features/lots/lotsAPI';
import { createLot } from '../../features/lots/lotsSlice';
import { fetchPackages, getPackages, Package } from '../../features/packages/packagesSlice';
import { getFlow, getUserFlow, isAuthed } from '../../features/users/usersSlice';

import ChevronLeftIcon from '../../icons/ChevronLeft';
import StepCheckout from './StepCheckout';
import StepImages from './StepImages';
import StepInformation from './StepInformation';
import StepPackages from './StepPackages';
import StepSuccess from './StepSuccess';
import StepUpgrade from './StepUpgrade';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: `calc(${ theme.sizes.navbar.height } + 1rem)`,
}));

export default function Publish() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [error, setError] = useState('');
  const [lotData, setLotData] = useState<CreateLot>({
    id: -1,
    title: '',
    description: '',
    address: '',
    type: 1,
    suitabilities: [],
    thumbnail: '',
    photos: [],
    price: 0,
    currency: {
      id: 1,
      name: 'USD',
    },
    area: 0,
    unit: {
      id: 1,
      name: 'm² (metro cuadrado)',
    },
    opportunity: false,
    lat: 0,
    lng: 0,
    placeId: '',
  });
  const [steps, setSteps] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState<string>();
  const [nuCase, setNuCase] = useState('');

  const flow = useSelector(getFlow);
  const authed = useSelector(isAuthed);
  const packages = useSelector(getPackages);

  useEffect(() => {
    store.dispatch(fetchPackages());
  }, []);

  useEffect(() => {
    if (authed) {
      store.dispatch(getUserFlow());
    }
  }, [authed]);

  const handleNextStep = async (newData?: Partial<CreateLot>) => {
    if (!activeStep) {
      setActiveStep(steps[0]);
      return;
    }

    const newLot = {
      ...lotData,
      ...newData,
    };

    const currentIdx = steps.indexOf(activeStep);
    const nextStep = steps[currentIdx + 1];

    // Si el paso actual es la carga de imágenes (último de carga de datos), creamos el lote
    if (activeStep == 'images') {
      await store.dispatch(createLot(newLot))
        .unwrap()
        .then(() => {
          // Si todavía falta pagar vamos al checkout
          if (nextStep == 'checkout') {
            setActiveStep('checkout');

          // Si no, vamos directo a la pantalla de éxito
          } else {
            setActiveStep('success');
          }
        })
        .catch(err => {
          console.error(getErrorMessage(err));
          setError(getErrorMessage(err));
        });

      return;
    }

    // Si  el próximo paso es el checkout avanzamos sin guardar nada
    if (nextStep == 'checkout') {
      setActiveStep('checkout');

    // Si este es el último paso pero no el checkout redirigimos a mis lotes
    } else if (activeStep != 'checkout' && currentIdx == steps.length) {
      navigate('/profile/lots');

    // En los demás casos guardamos la información del lote y avanzamos
    } else {
      setLotData(newLot);
      setActiveStep(nextStep);
    }
  };

  // La selección de paquete se procesa por separado porque no aporta datos del lote sino
  // el id del case para el checkout
  const handlePackageSelection = (caseId: string, selected: Package) => {
    if (!activeStep) {
      return;
    }

    setNuCase(caseId);

    // Si el precio es 0 quitamos el checkout
    if (selected.price == 0) {
      setSteps(steps.filter(s => s !== 'checkout'));
    }

    const current = steps.indexOf(activeStep);
    setActiveStep(steps[current + 1]);
  };

  const getStepContent = (step: string) => {
    switch (step) {
      case 'register': return <Register />;
      case 'packages': return <StepPackages
        packages={ packages }
        onFinish={ handlePackageSelection }
      />;
      case 'upgrade': return <StepUpgrade packageId={ flow?.acquiredProduct ? flow.acquiredProduct : -1 } />;
      case 'details': return <StepInformation onFinish={ handleNextStep } />;
      case 'images': return <StepImages onFinish={ handleNextStep } />;
      case 'checkout': return <StepCheckout nuCase={ nuCase } />;
      case 'success': return <StepSuccess />;
    }
  };

  useEffect(() => {
    const steps: string[] = [];

    let checkout = false;
    if (!authed) {
      steps.push('register');
    }

    if (flow?.packAcquisition || !authed) {
      steps.push('packages');
      checkout = true;
    } else if (flow?.upgradePackage) {
      steps.push('upgrade');
      checkout = false;
    } else if (flow?.publicationProcess) {
      steps.filter(s => s !== 'packages');
      checkout = false;
    }

    steps.push('details', 'images');

    if (checkout) {
      steps.push('checkout');
    }

    setSteps(steps);
    setActiveStep(steps[0]);
  }, [authed, flow]);

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <RootStyle>
      <Container>
        <Paper sx={{ padding: '1.625rem', borderRadius: '20px' }}>
          <Grid container>
            <Grid item xs={ 12 } sm={ 3 }>
              <ChevronLeftIcon sx={{ height: '18px', verticalAlign: 'text-top', marginRight: '1rem' }} />
              <Typography sx={{ fontSize: '1.375rem', lineHeight: '1.75rem', display: 'inline-block' }}>
                { t('Publicar un lote') }
              </Typography>
            </Grid>
            <Grid item xs={ 12 } sm={ 9 }>
              <Stepper activeStep={ activeStep ? steps.indexOf(activeStep) : -1 }>
                {
                  steps.includes('register')
                    ? <Step expanded>
                        <StepLabel>{ t('Registro') }</StepLabel>
                      </Step>
                    : null
                }
                {
                  steps.includes('packages')
                    ? <Step expanded>
                        <StepLabel>{ t('Paquete') }</StepLabel>
                      </Step>
                    : null
                }
                {
                  steps.includes('upgrade')
                    ? <Step expanded>
                        <StepLabel>{ t('Paquete') }</StepLabel>
                      </Step>
                    : null
                }
                {
                  steps.includes('details')
                    ? <Step expanded>
                        <StepLabel>{ t('Información lote') }</StepLabel>
                      </Step>
                    : null
                }
                {
                  steps.includes('images')
                    ? <Step expanded>
                        <StepLabel>{ t('Imágenes') }</StepLabel>
                      </Step>
                    : null
                }
                {
                  steps.includes('checkout')
                    ? <Step expanded>
                        <StepLabel>{ t('Checkout') }</StepLabel>
                      </Step>
                    : null
                }
              </Stepper>
              <Box>
                { activeStep ? getStepContent(activeStep) : null }
                {
                  error
                    ? <Alert severity="error" sx={{ fontSize: '1rem' }}>{ error }</Alert>
                    : null
                }
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </RootStyle>
  );
}
