import { GoogleMap, Marker } from '@react-google-maps/api';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMapIsLoaded } from '../../features/ui/UISlice';
import { SubscriptionZone } from '../../features/subscriptions/subscriptionsSlice';

import { Icon } from '@iconify/react';
import { Box, Button, Chip, Input, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';

import mapStyle from '../../assets/mapStyle.json';
import { useTranslation } from 'react-i18next';

import GoldMarker from '../../assets/markers/gold.png';

const Content = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));


interface StepMapProps {
  max: number;
  onFinish: (zones: SubscriptionZone[]) => void;
}

export default function StepMap(props: StepMapProps) {
  const { max, onFinish } = props;

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>();

  const mapIsLoaded = useSelector(getMapIsLoaded);

  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  const [selectedZones, setSelectedZones] = useState<SubscriptionZone[]>([]);
  const [completedSelection, setCompletedSelection] = useState(false);

  const [zoneId, setZoneId] = useState('');
  const [zoneValue, setZoneValue] = useState('');
  const [zoneCoords, setZoneCoords] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setZoneValue(event.target.value);
    setZoneId('');
  };

  const handleMapLoad = (map: google.maps.Map) => {
    setMapInstance(map);
  };

  const handleMapUnload = () => {
    setMapInstance(null);
  };

  const handleDelete = (id: string) => {
    setSelectedZones(selectedZones.filter(z => z.id !== id));
  };

  const handleAdd = () => {
    if (selectedZones.findIndex(z => z.id == zoneId) !== -1) {
      setZoneId('');
      setZoneValue('');
      setZoneCoords({
        lat: 0,
        lng: 0,
      });
      return;
    }

    const newSelection = {
      id: zoneId,
      label: zoneValue,
      location: zoneCoords,
    };

    setSelectedZones([
      ...selectedZones,
      newSelection,
    ]);

    setZoneId('');
    setZoneValue('');
  };

  const handleNextClick = () => {
    onFinish(selectedZones);
  };

  useEffect(() => {
    if (!mapIsLoaded || !inputRef.current) {
      return;
    }

    const newAutocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      { types: ['(cities)'] },
    );

    newAutocomplete.setFields(['formatted_address', 'geometry', 'place_id']);

    newAutocomplete.addListener('place_changed', () => {
      if (!newAutocomplete || !mapInstance) {
        return;
      }

      const place = newAutocomplete.getPlace();

      if (!place.place_id || !place.formatted_address || !place.geometry?.location) {
        return;
      }

      setZoneId(place.place_id);
      setZoneCoords(place.geometry.location.toJSON());
      setZoneValue(place.formatted_address);
      const viewport = place.geometry?.viewport?.toJSON();
      if (viewport) {
        mapInstance.fitBounds(viewport);
      }
    });
  }, [mapIsLoaded, mapInstance, inputRef.current]);

  const memoizedMap = useMemo(() => {
    if (!mapIsLoaded) {
      return null;
    }

    return (
      <GoogleMap
        mapContainerStyle={{
          height: desktop ? '320px' : '60vh',
          width: desktop ? '1200px' : '100%',
          maxWidth: '100%',
          borderRadius: '10px',
          margin: '1.25rem 0',
        }}
        center={ zoneCoords ? zoneCoords : { lat: 0, lng: 0 } }
        zoom={ 1 }
        onLoad={ handleMapLoad }
        onUnmount={ handleMapUnload }
        options={{
          controlSize: null,
          disableDefaultUI: true,
          zoomControl: true,
          gestureHandling: 'greedy',
          styles: mapStyle as google.maps.MapTypeStyle[],
        }}
      >
        {
          zoneCoords
            ? <Marker
                icon={{
                  url: GoldMarker,
                  scaledSize: new window.google.maps.Size(40, 52),
                }}
                position={ zoneCoords }
              />
            : null
        }
      </GoogleMap>
    );
  }, [mapIsLoaded, desktop, zoneCoords]);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    setCompletedSelection(selectedZones.length >= max);
  }, [inputRef.current, selectedZones, max]);

  return (
    <Content>
      <Typography sx={{ fontSize: '1.625rem', fontWeight: 600, textAlign: 'center' }}>
        { t('Elegí tus lugares de interés') }
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Input
          type="search"
          inputRef={ inputRef }
          sx={{ flexGrow: 1 }}
          value={ zoneValue }
          onChange={ handleInputChange }
          disabled={ completedSelection }
          placeholder={ t('Ingresá ubicación, por ej: Buenos Aires') }
          startAdornment={
            <Icon
              icon="bytesize:search"
              height="2rem"
              style={{
                color: completedSelection ? theme.palette.text.disabled : '#6A4739',
                opacity: completedSelection ? 0.42 : 1,
                marginRight: '1rem',
              }} />
          }
        />
        <Button
          variant="outlined"
          sx={{ padding: '0 2rem' }}
          onClick={ handleAdd }
          disabled={ zoneId == '' }
        >
          { t('Agregar') }
        </Button>
      </Box>
      { memoizedMap }
      <Stack direction="row" spacing={ 2 } justifyContent="center">
        {
          selectedZones.map(zone => (
            <Chip
              key={ zone.id }
              label={ zone.label }
              onDelete={ () => handleDelete(zone.id) }
            />
          ))
        }
      </Stack>
      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          size="large"
          sx={{ width: '200px', mt: '2.5rem' }}
          onClick={ handleNextClick }
          disabled={ !completedSelection }
        >
          { t('Siguiente') }
        </Button>
      </Box>
    </Content>
  );
}
