import { Icon } from '@iconify/react/dist/iconify.js';
import { Send } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  List,
  styled,
  TextField,
  Typography } from '@mui/material';
import { useEffect, useState, KeyboardEvent, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchConversations,
  fetchMessages,
  getConversations,
  getMessages,
  sendMessage } from '../../features/comms/commsSlice';
import { store } from '../../store';
import Conversation from '../../components/Chats/Conversation';
import Message from '../../components/Chats/Message';
import { Conversation as ConversationType } from '../../features/comms/commsAPI';
import { useTranslation } from 'react-i18next';
import { fetchLotById, getCurrentLot } from '../../features/lots/lotsSlice';

const ConversationsList = styled(List)(() => ({
  width: '100%',
  backgroundColor: '#fafafa',
  paddingTop: 0,
  paddingBottom: 0,
  borderTopLeftRadius: '20px',
  borderBottomLeftRadius: '20px',
  overflow: 'hidden',
  height: '600px',
}));

const ChatContents = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  overflow: 'hidden',
  maxHeight: '600px',
  alignItems: 'center',
  '.MuiFilledInput-root': {
    borderRadius: 0,
  },
}));

const LotData = styled(Box)(() => ({
  width: '100%',
  borderTop: 'solid 2px #fafafa',
  borderRight: 'solid 2px #fafafa',
  borderBottom: 'solid 2px #fafafa',
  borderTopRightRadius: '20px',
  padding: '.25rem .5rem',
  paddingBottom: '.25rem',
}));

const MessageWrapper = styled(Box)(() => ({
  flexGrow: 1,
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  scrollbarWidth: 'auto',
  scrollbarColor: '#fafafa #dadada',
  '::-webkit-scrollbar': {
    width: '12px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: '#dadada',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#fafafa',
    borderRadius: '10px',
    border: '3px none #f0f0f0',
  },
}));

const MessageList = styled(List)(() => ({
  backgroundColor: '#dadada',
  width: '100%',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  borderRight: 'solid 2px #fafafa',
}));

export default function Messages() {
  const { i18n, t } = useTranslation();

  const conversations = useSelector(getConversations);
  const messages = useSelector(getMessages);
  const lot = useSelector(getCurrentLot);
  const price = lot ? lot.price : 0;

  const messageListWrapperRef = useRef<HTMLDivElement>(null);

  const [loadingChatData, setLoadingChatData] = useState(false);
  const [message, setMessage] = useState('');
  const [currentConversation, setCurrentConversation] = useState<ConversationType>();
  const [sending, setSending] = useState(false);
  const [parsedPrice, setParsedPrice] = useState('');

  useEffect(() => {
    setLoadingChatData(true);
    store.dispatch(fetchConversations())
      .unwrap()
      .then(data => {
        if (Array.isArray(data) && data.length > 0) {
          setCurrentConversation(data[0]);
          store.dispatch(fetchMessages(data[0].id));
        }
      });
  }, []);

  useEffect(() => {
    if (!currentConversation) {
      return;
    }

    if (!currentConversation.consultedPlot) {
      return;
    }

    store.dispatch(fetchLotById(parseInt(currentConversation.consultedPlot)))
      .unwrap()
      .then(() => setLoadingChatData(false));
  }, [currentConversation]);

  useEffect(() => {
    if (!messageListWrapperRef.current) {
      return;
    }

    messageListWrapperRef.current.scrollTop = messageListWrapperRef.current.scrollHeight;
  }, [messages]);

  const handleSendMessage = () => {
    if (!currentConversation) {
      return;
    }

    setSending(true);

    try {
      store.dispatch(sendMessage({
        conversationId: currentConversation.id,
        lotId: parseInt(currentConversation.consultedPlot),
        message,
      }))
        .unwrap()
        .then(() => {
          // Limpiamos el mensaje actual
          setMessage('');

          // Actualizamos los mensajes
          store.dispatch(fetchMessages(currentConversation.id));
        });
    } catch (err) {
      console.error(err);
    } finally {
      setSending(false);
    }
  };

  const handleMessageKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key == 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleChangeConversation = (id: number) => {
    const selected = conversations.find(c => c.id === id);
    if (!selected) {
      return;
    }

    // Actualizamos los mensajes
    store.dispatch(fetchMessages(selected.id));

    // Seteamos la nueva conversación
    setCurrentConversation(selected);

    // Limpiamos el mensaje actual
    setMessage('');
  };

  useEffect(() => {
    const parsed = new Intl.NumberFormat(i18n.language, {
      minimumFractionDigits: 0,
      useGrouping: true,
    }).format(price);

    setParsedPrice(parsed);
  }, [price, i18n.language]);

  return (
    <Grid container>
      <Grid item xs={ 12 }>
        <Typography variant="h6" sx={{ marginBottom: '1rem' }}>{ t('Mensajes') }</Typography>
      </Grid>
      <Grid item xs={ 12 } sm={ 6 }>
        <ConversationsList>
          {
            conversations.map(conversation => (
              <Conversation
                key={ `conversation_${ conversation.id }` }
                value={ conversation }
                onClick={ handleChangeConversation }
                selected={ currentConversation?.id == conversation.id }
              />
            ))
          }
        </ConversationsList>
      </Grid>
      <ChatContents item xs={ 12 } sm={ 6 }>
        {
          lot && !loadingChatData
            ? <LotData>
                <Typography variant="body2" fontSize="1.25rem">{ lot.title }</Typography>
                <Typography color="text.secondary" fontSize=".8rem">
                  <Icon
                    icon="ep:location"
                    style={{ width: '.875rem', marginRight: '.25rem', verticalAlign: 'middle' }}
                  />
                  { lot.address }
                </Typography>
                <Typography color="text.secondary" fontSize=".8rem">
                  <Icon
                    icon="bi:arrows-angle-expand"
                    style={{ width: '.6rem', marginRight: '.475rem', verticalAlign: 'middle' }}
                  />
                  { lot.area } { lot.unit.name }
                </Typography>
                <Typography color="text.secondary" fontSize=".8rem">
                  <Icon
                    icon="ep:price-tag"
                    style={{ width: '.875rem', marginRight: '.2rem', verticalAlign: 'middle' }}
                  />
                  { lot.currency.name } { parsedPrice }
                </Typography>
              </LotData>
            : null
        }
        <MessageWrapper ref={ messageListWrapperRef }>
          <MessageList>
            {
              messages.map(message => (
                <Message key={ `message_${ message.id }` } value={ message } />
              ))
            }
          </MessageList>
        </MessageWrapper>
        <TextField
          variant="standard"
          sx={{
            width: '100%',
            backgroundColor: '#fafafa',
            borderRight: 'solid 2px #fafafa',
            borderBottom: 'solid 2px #fafafa',
            borderBottomRightRadius: '20px',
            overflow: 'hidden',
          }}
          value={ message }
          disabled={ sending }
          onChange={ event => setMessage(event.target.value) }
          onKeyDown={ handleMessageKeyDown }
          InputProps={{
            sx: {
              backgroundColor: '#fff',
              paddingRight: '.45rem',
              borderBottom: 'none !important',
              ':before': {
                borderBottom: 'none !important',
              },
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={ handleSendMessage } disabled={ sending }>
                  <Send />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </ChatContents>
    </Grid>
  );
}
