import { SvgIcon } from '@mui/material';

interface LeftArrowProps {
  sx?: Record<string, unknown>;
  color?: string;
}

/* eslint-disable max-len */
export default function LeftArrowIcon(props: LeftArrowProps) {
  const { sx, color = '#333333' } = props;

  return (
    <SvgIcon viewBox="0 0 38 8" sx={{ verticalAlign: 'middle', fill: color, ...sx }}>
      <path d="M0.646446 3.64645C0.451183 3.84171 0.451183 4.15829 0.646446 4.35356L3.82843 7.53554C4.02369 7.7308 4.34027 7.7308 4.53553 7.53554C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17158C4.7308 0.976314 4.7308 0.659731 4.53553 0.464469C4.34027 0.269207 4.02369 0.269207 3.82843 0.464469L0.646446 3.64645ZM38 3.5L1 3.5L1 4.5L38 4.5L38 3.5Z" />
    </SvgIcon>
  );
}
