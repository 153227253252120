import config from '../../config';
import api from '../../services/api';

import { Options } from './optionsSlice';

type Option = Record<string, string>;

interface OptionsData {
  mostConsultedAreas: Record<string, Record<string, string>>;
  minPrice: string;
  maxPrice: string;
  landAptitude: Record<string, Option>;
  landType: Record<string, Option>;
  publicationStatus: Record<string, Option>;
  areaType: Record<string, Option>;
  moneyType: Record<string, Option>;
  userType: Record<string, Option>;
  actionToDo: Record<string, Option>;
  userProfile: Record<string, Option>;
  productType: Record<string, Option>;
  paymentGateways: Record<string, Option>;
  status: Record<string, Option>;
}

export async function fetchOptions() {
  const response = await api<OptionsData>('PUT', 'rules/plotGetPricesAndLandAptitude-1/execute');

  return parseOptions(response.data);
}

export async function changeLang(language: string) {
  const response = await api<{ changeOk: boolean }>('PUT', 'rules/plotChangeLanguagePreference-1/execute', {
    language,
  });

  return response.data;
}

function parseOptions(options: OptionsData): Options {
  const lang = localStorage.getItem('lang') ?? 'es';

  const areas = [];
  for (const id of Object.keys(options.mostConsultedAreas)) {
    for (const [name, image] of Object.entries(options.mostConsultedAreas[id])) {
      areas.push({
        id: parseInt(id),
        name,
        image: config.s3.url + image,
      });
    }
  }

  const types = Object.keys(options.landType).map(key => ({
    id: parseInt(key),
    name: options.landType[key][lang],
  }));

  const suitabilities = Object.keys(options.landAptitude).map(key => ({
    id: parseInt(key),
    name: options.landAptitude[key][lang],
  }));

  const currencies = Object.keys(options.moneyType).map(key => ({
    id: parseInt(key),
    name: options.moneyType[key][lang],
  }));

  const units = Object.keys(options.areaType).map(key => ({
    id: parseInt(key),
    name: options.areaType[key][lang],
  }));

  const userTypes = Object.keys(options.userType).map(key => ({
    id: parseInt(key),
    name: options.userType[key][lang],
  }));

  const paymentGateways = Object.keys(options.paymentGateways).map(key => ({
    id: parseInt(key),
    name: options.paymentGateways[key][lang],
  }));

  const status = Object.keys(options.status).map(key => ({
    id: parseInt(key),
    name: options.status[key][lang],
  }));

  const publicationStatus = Object.keys(options.publicationStatus).map(key => ({
    id: parseInt(key),
    name: options.publicationStatus[key][lang],
  })).filter(s => s.id != 1); // Quitamos Borrador de los estados posibles para los lotes

  return {
    mostConsultedAreas: areas,
    minPrice: parseFloat(options.minPrice),
    maxPrice: parseFloat(options.maxPrice),
    landSuitabilities: suitabilities,
    landTypes: types,
    currencies,
    units,
    userTypes,
    publicationStatus,
    paymentGateways,
    paymentStatus: status,
  };
}

const optionsAPI = {
  fetchOptions,
  changeLang,
};

export default optionsAPI;
