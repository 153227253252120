import { PropsWithChildren, ReactNode, useEffect, useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useLoadScript } from '@react-google-maps/api';

import { fetchOptions } from '../features/options/optionsSlice';
import { closeSidebar, setMapLoadedState } from '../features/ui/UISlice';
import { store } from '../store';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar/General';

import config from '../config';

const googleMapsOptions = {
  googleMapsApiKey: config.google.maps.key,
  libraries: ['places'],
  region: 'AR',
  language: 'es-419',
};

function RefreshOnRouteChange(props: PropsWithChildren<ReactNode>) {
  const { children } = props;

  const location = useLocation();

  useLayoutEffect(() => {
    store.dispatch(closeSidebar());
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname, location.search]);

  useEffect(() => {
    store.dispatch(fetchOptions());
  }, []);

  return <>{ children }</>;
}

export default function FullLayout() {
  // @ts-ignore
  const { isLoaded: isGoogleMapsLoaded } = useLoadScript(googleMapsOptions);

  useEffect(() => {
    store.dispatch(setMapLoadedState(isGoogleMapsLoaded));
  }, [isGoogleMapsLoaded]);

  return (
    <RefreshOnRouteChange>
      <Navbar />
      <Sidebar />
      <Outlet />
      <Footer />
    </RefreshOnRouteChange>
  );
}
